import $ from 'jquery';
import React, { useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import ReactSelect from 'react-select'
import { companysearchcandidate_service,getCondidatesView } from '../../ApiServices';
import {Comp_CandidateCard} from '../../web/component/Comp_CandidateCard';
import {Comp_Student_Profile_View} from '../../web/ash_dialogue/comp_student_profile_view';
import Candidate_Filters from '../../web/component/Candidate_Filters';

const CompanySearchCandidateNew = () => {


  const [canidates, setcanidates] = useState([]);

  const [viewCandiate,setViewCandidate]=useState(null);
  const [searchFilters,setSearchFilters]=useState([]);


  useEffect(() => {
    getcandidatecompanysearch();
    getSearchMasters();
  }, [])

  const getcandidatecompanysearch = (candiSearches={}) => {
    $("#resps_search_candi").html("");
    let map={job_status:"published"};
    map["filters"]=candiSearches;
    //alert(JSON.stringify(candiSearches));
    //return;
    getCondidatesView(map,"getCandidatesView").then((res)=>{
        //alert(res.data);
        setcanidates(JSON.parse(res.data));
        if(JSON.parse(res.data).length===0){
          $("#resps_search_candi").html("No candidates found");
        }
    }).catch((error)=>{

    })

    // companysearchcandidate_service().then((res)=>{
    //   alert(JSON.stringify(res.data));
    //   setcanidates(res.data.candidates);
    //   //console.log(res.data.candidates);
    // }).catch((error)=>{

    // })

  }


  const [fomdata, setfomdata] = useState(
    {
      jobcity: "",
      jobtype: "",
      verified: "",
      englishlevel: "",
      experience: "",
      minexperience: "",
      jobtitle: "",
      skills: ""
    }
  )

 const onViewCandidate=(candidateId)=>{
  setViewCandidate(candidateId)
 }

  const submitform = () => {

  }

  // search related functions
  
  const getSearchMasters=()=>{
    let map={};
    map["education_levels"]="1";
    map["job_types"]="1";
    map["job_shifts"]="1";
    map["skills"]="1";

    getCondidatesView(map,"getMasters").then((res)=>{
      //alert(res.data);
      let lsData=JSON.parse(res.data);
      //alert(searchFilters.job_types);
      //alert(JSON.stringify(lsData.education_levels));
      setSearchFilters(lsData);

    })
  }

  return (
    <section style={{ padding: '10px 10px' }}>
      <div className="container">
        <div className="row">

         {/* apply new filters */}
         <div class="mobile-filter-button pb-10">
    <a href="#0" class="btn1 btn-warning open_filters filter-button">
        <i class="ion-ios-color-filter-outline12 ti-filter12 fa fa-filter white my-float">Filter Candidates</i>
</a>
   </div>
         {typeof searchFilters?.job_types!=="undefined"?<Candidate_Filters runSearch={getcandidatecompanysearch} filters={searchFilters} />:''}
          <div className="col-lg-9 d-flex flex-column gap-2">
            <div id="resps_search_candi"></div>
            {
              canidates.map((v) => {
                // alert(v.city)
                  return (
                   <Comp_CandidateCard onView={onViewCandidate} candidate={v}/>
                  )
                
              })
            }

          </div>
        </div>
      </div>
      <Comp_Student_Profile_View candidateId={viewCandiate}/>
    </section>
  )
}

export default CompanySearchCandidateNew