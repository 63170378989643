import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { education_level_service, update_education_level } from '../../ApiServices';
import { toast } from 'react-toastify';

import {getCondidatesView} from '../../ApiServices';

export const Comp_Company_Profile_View = (props) => {

    const navigate = useNavigate();



    useEffect(() => {
      //alert(JSON.stringify(props.companyItem));
    }, [props.companyItem])

return(<div class="modal fade" id="divCompanyProfileView" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary white">
          <h1 class="modal-title fs-5  white" id="exampleModalLabel">Company Profile</h1>

          <button type="button" class="btn-close white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        {typeof props.companyItem.id!=="undefined"?<>
          <div class="row ">
            <div class="col-6 col-md-6 leftsection">
              
                <div class="logodiv">
                    <div class="card" style={{"width": "100%"}}>
                    {/* <img src="logo.png" class="card-img-top" alt="..." /> */}
                  
                  </div>
                </div>
              
                
            </div>
            <div class="col-6 col-md-6 rightsection">
                <div class="companynamediv text-center mt-2">
                <b>{props.companyItem["companyname"]}</b>
                </div>
              
            </div>
            
          </div>

          <table class="table">
            <tbody>
                <tr>
                    <th scope="row text-center p-2"></th>
                    <td>Number of Employees</td>
                    <td>{props.companyItem["no_of_employees"]}</td>
                  </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Contact Person</td>
                <td>{props.companyItem["firstname"]} {props.companyItem["lastname"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Contact Number</td>
                <td>{props.companyItem["mobile"]}</td> 
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Email</td>
                <td>{props.companyItem["email"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Gender</td>
                <td>{props.companyItem["gender"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Country</td>
                <td>{props.companyItem["country_name"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>State</td>
                <td>{props.companyItem["state_name"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>City</td>
                <td>{props.companyItem["city_name"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Created Date</td>
                <td>{props.companyItem["created_at"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Updated date</td>
                <td>{props.companyItem["updated_at"]}</td>
              </tr>
              <tr>
                <th scope="row text-center p-2"></th>
                <td>Profile Completed </td>
                <td><button type="button" class="btn btn-success">{props.companyItem["is_profile_completed"]}</button></td>
              </tr>
            </tbody>
          </table>
          </>:''}
         
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          
        </div>
       
      </div>
    </div>
  </div>)

}

export default Comp_Company_Profile_View