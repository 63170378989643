import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { alljobplan_service,getCondidatesView } from '../../ApiServices';
import Comp_Dashboard_Widgets from '../../web/component/comp_dashboard_widgets';
import Comp_Ejobee_Pricing from '../../web/component/comp_ejobee_pricing';
import { Helmet } from 'react-helmet';

const CompanyDashboard = () => {

    //alert("i m in all jobs");
    const navigate = useNavigate();
    const [jobplans, setjobplans] = useState([]);
    const [getDashboard,setDashboard]=useState([]);
    const [getCompanyName,setCompanyName]=useState([]);
    const [freepost, setfreepost] = useState('0');


    const postjobclick = () => {
        navigate('/company/post-job')
    }

    useEffect(() => {
        let map={};
        map["company_id"]=btoa(localStorage.getItem('company_id'));
        getCondidatesView(map,"getCompanyOutstanding").then((res)=>{
            //alert(JSON.stringify(res));
            setDashboard(JSON.parse(res.data));
            if(JSON.parse(res.data).companies.length>0){
               setCompanyName(JSON.parse(res.data).companies[0]["companyname"]);
               setfreepost(JSON.parse(res.data).companies[0]["freejobpost"]);
            }
            
        }).catch((error)=>{

        })
        getaljobplan();
      
    }, []);

    
  const getaljobplan = () => {
    alljobplan_service().then((res) => {
      setjobplans(res.data.plans)
    }).catch((err) => {

    })
  }



    const formatDate = (timestamp, locale = 'en-GB') => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(locale, options);
    };

    return (
        <>
            <Helmet>
                <title>Welcome to Ejobee</title>
                <meta name="description" content="All Jobs That Company Have Posted For Candidate." />
            </Helmet>

           
            <section style={{ padding: '10px 10px' }}>
                <div className="container">
                    <div className="section-tittle row justify-content-between">
                        <div style={{ width: 'fit-content' }}>
                            <h2>Welcome {getCompanyName} </h2>
                        </div>
                        <div style={{ width: 'fit-content' }}>
                            <div onClick={postjobclick} className="btn btn-primary">Post Job</div>
                        </div>
                    </div>
                    <div className="section-tittle row justify-content-between">
                    <div style={{ width: 'fit-content' }}>
                        
                   {freepost!=="0"?<>Greeting! you can post <b>{freepost}</b> job(s) for free on Ejobee</>:''}
                        </div>
                    </div>
                    
                    <Comp_Dashboard_Widgets company_dashboard={getDashboard}/>
                    <div className="row mt-5 mb-5">
                        
                          

                        
                        <div class="col-lg-12">
                        <p className='fs-4 text-black mb-2'>Job Post Plans</p>
                        </div>
                        <Comp_Ejobee_Pricing buyNow={false} lsBusinessPlans={jobplans}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompanyDashboard