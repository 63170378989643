import React, { useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {Comp_CandidateSavedJobCard} from '../../web/component/Comp_CandidateCard';
import {getCondidatesView} from '../../ApiServices';
import {Comp_Student_Profile_View} from '../../web/ash_dialogue/comp_student_profile_view';

const CompanySavedCandidate = () => {
  const navigate = useNavigate();

  const [savedcandidate, setsavedcandidate] = useState([]);
  const [viewCandiate,setViewCandidate]=useState(null);
  
  const fetchcandidate = () => {
    let map={ser_company_id:btoa(localStorage.getItem('company_id'))};
    
      getCondidatesView(map,"getCandidateSavedJobs").then((res) => {
        //alert(res.data);
        const newCandidates = JSON.parse(res.data).saved_jobs;
        setsavedcandidate(newCandidates);
       
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    
  }
  
  const onViewCandidate=(candidateId)=>{
    setViewCandidate(candidateId)
   }

  useEffect(()=>{
    fetchcandidate();
  },[])


  return (
    <section style={{ padding: '10px 10px' }}>
      <div className="container">
        <div className="row h-100" style={{ position: 'relative' }}>
          <div className="col-lg-6 d-flex flex-column gap-2">
            {/* <h2 style={
              {
                color: 'var(--primary-color)',
              }
            }>You Don't Have Saved Candidate Profile</h2> */}
            {
              savedcandidate.length != 0 ?
                savedcandidate.map((v) => {
                  return (
                    <Comp_CandidateSavedJobCard candidate_id={v.candidate_id} onView={onViewCandidate} candidate={v}/>
                  )
                })
                : <div style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{fontSize:"18px",fontWeight:'bold'}}>Saved Candidate List Empty</p>
                </div>
            }
          </div>
          <div className="col-lg-6 d-flex flex-column" style={{ position: 'relative' }}>
            <div style={{ position: "relative" }}>
            <img className='w-100' src="/assets/images/saved-profile.png" alt="Ejobee"  />
            </div>
            
          </div>
        </div>
      </div>
      <Comp_Student_Profile_View candidateId={viewCandiate}/>
    </section>
  )
}

export default CompanySavedCandidate