import React from 'react'
import { Helmet } from 'react-helmet'

const Terms = () => {
  return (
    <>
    <Helmet>
      <title>Terms and Conditions - Guidelines for Using ejobee</title>
      <meta name="description" content="Review ejobee's Terms and Conditions for a comprehensive understanding of platform usage. Navigate guidelines, responsibilities, and user agreements to ensure a secure and respectful experience for all users." />
    </Helmet>
      <section>
        <div className="container">
          <div className="section-title mb-3">
            <h4>Terms And Conditions</h4>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h4>Acceptance of Terms</h4>
              <p>By accessing or using our website and services, whether as a candidate or a company, you agree to comply with and be bound by the following terms and conditions. Please read these Terms carefully before using the platform. If you do not agree with these Terms, you must not use Ejobee.</p>

              <h4 class="mt-5">Eligibility </h4>
              <p>
              Ejobee is intended for use by individuals and companies who are 18 years of age or older. By using this platform, you represent and warrant that you meet this age requirement. If you do not meet this requirement, you must not use our services.
              </p>

              <h4 class="mt-5">User Accounts Registration</h4>
              <p class="mt-2">
               <b>Candidates -</b> To access job search features and apply for jobs, you must create an account. You agree to provide accurate, current, and complete information during registration and to update such information as necessary.
              </p>

              <p>
              <b>Companies -</b> To post jobs and search for candidates, you must create a company account. You agree to provide accurate, current, and complete information during registration and to update such information as necessary.
              </p>

              <p>
              <b>Account Security -</b> Both candidates and companies are responsible for maintaining the confidentiality of their account login credentials and for all activities that occur under their account. You agree to notify Ejobee immediately of any unauthorized use of your account or any other breach of security.
              </p>

              <h4 class="mt-10">Account Termination</h4>
              <p>
              Ejobee reserves the right to suspend or terminate any account at any time for any reason, including but not limited to violations of these Terms, fraudulent activities or misuse of the platform.
              </p>

              <h4 class="mt-2">Use of the Platform</h4>
              <p>You agree to use Ejobee solely for lawful purposes and in accordance with these Terms. You may not use the platform in any way that could damage, disable, overburden, or impair our services or interfere with any other party's use of the platform.</p>

              <h4 class="mt-10">Prohibited Conduct</h4>
              <p><b>Both candidates and companies agree not to engage in any of the following prohibited activities -</b></p>
              <p class="mt-2">Posting or sharing false, misleading, or fraudulent information.</p>
              <p>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity.</p>
              <p>Engaging in any form of harassment, abuse or discrimination against other users.</p>
              <p>Attempting to gain unauthorized access to other user accounts, the platform's systems or networks.</p>
              <p>Uploading or transmitting any viruses, malware, or other harmful code.</p>
              
              <h4 class="mt-10">Content Ownership</h4>
              <p class="mt-2">
              <b>Candidates -</b> All content you provide, including resumes, and intro videos, remains your property. By submitting content to Ejobee, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and display your content in connection with the operation of the platform.
              </p>
              <p>
              <b>Companies -</b> All job postings and company profiles remain the property of the company. By submitting content to Ejobee, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and display your content in connection with the operation of the platform.
              </p>

              <h4 class="mt-10">Job Postings and Applications Accuracy</h4>
              <p class="mt-2"><b>Companies:</b> You are responsible for ensuring the accuracy and legality of the job postings you create.</p>
              <p><b>Candidates:</b> You are responsible for ensuring the accuracy of the information provided in your applications.</p>
              
              <h4 class="mt-5">No Guarantee</h4>
              <p>Ejobee does not guarantee that job postings will result in a successful hire, nor do we guarantee that job applications will result in an interview or job offer. We are not responsible for the actions or decisions of employers or job seekers.</p>

              <h4  class="mt-5">Screening and Verification</h4>
              <p>Ejobee does not screen or verify the identity, qualifications, or credentials of users, employers, or job seekers. It is the responsibility of both parties to conduct their own due diligence.</p>

              <h4 class="mt-5">Payment of Services</h4>
              <p>Certain features of Ejobee may require payment, such as premium memberships, job posting fees, access to candidate profiles, Premium Resumes, etc by using these features; you agree to pay the applicable fees and charges as described on our website.</p>

              <h4 class="mt-5">Trademark</h4>
              <p>The Ejobee name and logo are legally registered trademarks. Any use of the Ejobee name or logo without prior written consent from Ejobee is strictly prohibited and may result in legal action. Unauthorized use includes, but is not limited to, reproduction, distribution, or any other means of utilization that could mislead or imply endorsement by Ejobee.</p>

              <h4 class="mt-5">Changes to These Terms</h4>
              <p>Ejobee reserves the right to modify these Terms at any time. Any changes will be posted on this page.</p>

              <h4 class="mt-5">Contact Us</h4>
              <p>If you have any questions or concerns about these Terms and Conditions, please contact us Ejobee Support Team Email yourejobee@gmail.com</p>

             
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Terms