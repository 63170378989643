import React, { useEffect, useState } from 'react'
import AdminAddCity from '../dialogue/AdminAddCity'
import AdminEditCity from '../dialogue/AdminEditCity'
import { getallcityservice, getcountryservice } from '../../ApiServices'

const AdminCity = () => {

  const [countries, setcountries] = useState([])
  const [cities, setcities] = useState([]);
  const [cityid, setcityid] = useState(null)


  useEffect(() => {
    getallcities();
    getcountries();
  }, []);


  const getcountries = () => {
    getcountryservice().then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setcountries(mappedoptions)
    }).catch((error) => {

    })
  }

  const getallcities = () => {
    getallcityservice().then((data) => {
      setcities(data.data);
    }).catch((error) => {

    })
  }


  return (
    <div className="container-fluid">
      {/* city */}
      <AdminAddCity countries={countries} getcities={getallcities} />
      <AdminEditCity countries={countries} getcities={getallcities} cityid={cityid} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">City</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addcity" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Coutnry</th>
                <th scope="col">state</th>
                <th scope="col">City</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                cities.map((v) => {
                  return (
                    <tr>
                      <td>
                        India
                      </td>
                      <td>
                        {v.state.name}
                      </td>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#editcity" class="btn btn-sm btn-neutral text-danger-hover" onClick={()=>setcityid(v.id)}>
                          Edit
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminCity