import React, { forwardRef, useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import { add_candidateeducation_service, alldegree_service, getalluniversityservice } from '../../ApiServices';
import { toast } from 'react-toastify';

const AddEducation = ({ getprofile }) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    university_name: "",
    degree_name: "",
    startDate: "",
    endDate: "",
  });
  const [errors, seterrors] = useState({})

  const [university, setuniversity] = useState([])
  const [degree, setdegree] = useState([]);

  useEffect(() => {
    //alert(JSON.stringify(getprofile));
    //getuniversity();
    //getdegree();
  }, [])

  const getdegree = () => {
    alldegree_service().then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setdegree(mappedoptions);
    }).catch((error) => { })
  }

  const getuniversity = () => {
    getalluniversityservice().then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setuniversity(mappedoptions)
    }).catch((error) => {

    })
  }

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    })
    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  }

  const HandleSelectChange = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    })
    seterrors((prevErrors) => ({
      ...prevErrors,
      [action.name]: '',
    }));
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const validateform = () => {
    const newerrors = {}

    if (!formdata.university_name) {
      newerrors.university_name = "University is Required"
    }

    if (!formdata.degree_name) {
      newerrors.degree_name = "please Select Jobrole"
    }

    if (!formdata.startDate || !formdata.endDate) {
      newerrors.date = "please Select Start And End Date"
    }

    if (!formdata.startDate) {
      newerrors.date = "please Select Start Date"
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const canceldialogue = () => {
    setformdata({
      degree_name: "",
      university_name: "",
      startDate: "",
      endDate: "",
    })
    seterrors({})
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      console.log(formdata);
      add_candidateeducation_service(formdata).then((res) => {
        toast('✅ Education Added Successfully');
        canceldialogue();
        getprofile();
      }).catch((err) => {

      })
    }
  }

  return (
    <div class="modal fade" id="addEducation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Education
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">


              <input className='form-control' placeholder="Degree" value={formdata.degree_name} onChange={HandleChange} name='degree_name' />
              {/* <ReactSelect placeholder="Select Degree" value={formdata.degree} onChange={HandleSelectChange} name='degree' options={degree} /> */}
              {errors.degree_name && <span className='text-danger'>{errors.degree_name}</span>}

              {/* <ReactSelect placeholder="Select University" value={formdata.university} onChange={HandleSelectChange} name='university' className='mt-2' options={university} /> */}
              <input className='form-control' placeholder="University" value={formdata.university_name} onChange={HandleChange} name='university_name' />
              {errors.university_name && <span className='text-danger'>{errors.university_name}</span>}

              <div className="row">
                <div className="col-lg-6 mt-3 d-flex flex-column">
                  <label className='me-2'>Start Date</label>
                  <ReactDatePicker selected={
                    !formdata.startDate ? new Date() : formdata.startDate
                  }
                    showMonthYearPicker
                    className='form-control' dateFormat="MMMM yyyy"
                    name='startDate'
                    placeholderText='Start Year' onChange={(date) => {
                      setformdata({
                        ...formdata,
                        startDate: date
                      })
                    }}
                    maxDate={new Date()} customInput={<ExampleCustomInput />} />
                </div>
                <div className="col-lg-6 mt-3 d-flex flex-column">
                  <label className='me-2'>end Date</label>
                  <ReactDatePicker selected={
                    !formdata.endDate ? new Date() : formdata.endDate
                  }
                    showMonthYearPicker
                    className='form-control' dateFormat="MMMM yyyy"
                    name='endDate'
                    placeholderText='Start Year' onChange={(date) => {
                      setformdata({
                        ...formdata,
                        endDate: date
                      })
                    }}
                    maxDate={new Date()} customInput={<ExampleCustomInput />} />
                </div>
                {
                  errors.date && <span className='text-danger' >{errors.date}</span>
                }
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddEducation