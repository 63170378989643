export const TestimonialsData=[
  {"g_title":"Testimonials","col17":"testimonials","col18":"12","id":"500","col1":"Aryan Patel,Marketing Coordinator","col2":"eJobee has been a game-changer for me. The Resume Builder is incredibly user-friendly, and the job platform provided me with tailored opportunities. I landed my dream job thanks to eJobee!.","col3":" ","col4":"def_user_thumb.png","col5":"","col6":"","col7":"","col8":"","col9":"","col10":"","col11":"","col12":"","col13":"","col14":"","col15":"","col016":"","col017":"","col018":"","col19":"","col20":"377","f_date":"12-Jan-2024","entry_date":"2024-01-12 07:54:12","e_id":"YxJ9UA-ntAdOicQKJLIQs38pyJCvv_qbGf5d7qsAFDIwFuFPwJffNng4AYUm9GT89wawCUM4GNp2ss70eHwx9Q"},
  {"g_title":"Testimonials","col17":"testimonials","col18":"12","id":"500","col1":"Priya Sharma,Data Analyst","col2":"I can\'t speak highly enough about eJobee. The Resume Builder helped me create a professional and eye-catching resume effortlessly. Thank you, eJobee, for making job hunting a positive experience!","col3":" ","col4":"def_user_thumb.png","col5":"","col6":"","col7":"","col8":"","col9":"","col10":"","col11":"","col12":"","col13":"","col14":"","col15":"","col016":"","col017":"","col018":"","col19":"","col20":"377","f_date":"12-Jan-2024","entry_date":"2024-01-12 07:54:12","e_id":"YxJ9UA-ntAdOicQKJLIQs38pyJCvv_qbGf5d7qsAFDIwFuFPwJffNng4AYUm9GT89wawCUM4GNp2ss70eHwx9Q"},
  {"g_title":"Testimonials","col17":"testimonials","col18":"12","id":"500","col1":"Rohan Kapoor,Social Medial Specialist","col2":"Using eJobee\'s Resume Builder streamlined the application process for me. The platform\'s job recommendations were spot-on, saving me time and effort. I highly recommend eJobee for anyone serious about their career.","col3":" ","col4":"def_user_thumb.png","col5":"","col6":"","col7":"","col8":"","col9":"","col10":"","col11":"","col12":"","col13":"","col14":"","col15":"","col016":"","col017":"","col018":"","col19":"","col20":"377","f_date":"12-Jan-2024","entry_date":"2024-01-12 07:54:12","e_id":"YxJ9UA-ntAdOicQKJLIQs38pyJCvv_qbGf5d7qsAFDIwFuFPwJffNng4AYUm9GT89wawCUM4GNp2ss70eHwx9Q"}

]
export const faqdata=[
  {
    question:"How do I build a resume with Ejobee?",
    answer:"By following these steps, EJobee’s Resume Builder helps you create a polished and professional resume. \n•Choose a Template \n• User-Friendly Interface \n• Personal Information Input \n• Add Work Experience \n• Include Education and Certifications \n• Customize Sections \n• Preview and Edit \n• Save and Download"
  },
  {
    question:"What is the benefit of having an intro video on Ejobee?",
    answer:"Companies can first view your intro video to assess your personality, skills, and communication abilities before deciding to call you for an interview. The intro video gives employers a quick insight into who you are, making it easier for them to identify strong candidates early in the hiring process."
  },
  {
    question:"Can Ejobee help us save time in our recruitment process?",
    answer:"Yes, Ejobee saves your company time by allowing you to pre-screen candidates through their intro videos and resumes. This reduces the need for initial interviews, allowing you to focus on the most promising candidates."
  },
  {
    question:"What makes Ejobee different from other job portals?",
    answer:"Ejobee stands out by offering intro video showcases, which give you a deeper understanding of candidates before the interview stage. This unique feature, combined with an easy-to-use platform and affordable job postings, makes it a superior choice for efficient hiring."
  },
  {
    question:"How can Ejobee help our company find the best candidates efficiently?",
    answer:"Ejobee helps your company find the best candidates efficiently by allowing you to review intro videos and resumes before scheduling interviews. This enables you to quickly assess a candidate's personality, skills, and communication abilities, ensuring you only invest time in interviewing those who are a good fit for your company."
  },
  {
    question:"Can I upload my existing resume to my Ejobee profile?",
    answer:"Yes, you can upload your existing resume to your profile section on Ejobee. Simply log in to your Ejobee account and navigate to the 'Upload your Resume' option. From there, you can upload your current resume."
  }

]
export const faqdata_old = [
    {
        question: "How does eJobee's Resume Builder work?",
        answer: "eJobee's Resume Builder is an intuitive tool that guides you through creating a professional resume. Simply input your details, and it formats them into a polished document, making the job application process smoother."
    },
    {
        question: "Is eJobee only for specific industries or job types?",
        answer: "No, eJobee is a versatile platform catering to various industries and job types. Whether you're in technology, healthcare, finance, or any other sector, you can find relevant job opportunities and use our Resume Builder to enhance your application."
    },
    {
        question: "Can I import my existing resume into eJobee's Resume Builder?",
        answer: "Absolutely! eJobee allows you to import your existing resume, making it easy to update and modify. You can leverage our tools to refine and optimize your resume for the best job matches."
    },
    {
        question: "How does eJobee match me with job opportunities?",
        answer: "eJobee uses advanced algorithms to analyze your resume, skills, and preferences. It then matches you with relevant job listings, ensuring that you receive personalized job recommendations tailored to your qualifications."
    },
    {
        question: "How does eJobee match me with job opportunities?",
        answer: "eJobee uses advanced algorithms to analyze your resume, skills, and preferences. It then matches you with relevant job listings, ensuring that you receive personalized job recommendations tailored to your qualifications."
    },
    {
        question: "Is my personal information safe on eJobee?",
        answer: "Yes, eJobee takes privacy seriously. Your personal information is encrypted and secured. We adhere to strict privacy policies to protect your data and ensure a safe job-seeking experience."
    },
    {
        question: "Can I track the status of my job applications on eJobee?",
        answer: "Absolutely! eJobee provides a dashboard where you can track the status of your job applications. Stay informed about which applications are under review, scheduled for interviews, or have received offers."
    },
    {
        question: "Is eJobee accessible on mobile devices?",
        answer: "Yes, eJobee is designed to be mobile-friendly. You can access the platform and utilize the Resume Builder on your smartphone or tablet, making it convenient for you to manage your job search on the go."
    }
];


export const testimonialcarddata = [
    {
        id: '1',
        img: 'https://randomuser.me/api/portraits/men/32.jpg',
        name: 'Aryan Patel',
        position: 'Marketing Coordinator',
        title: 'Amazing Experience',
        star: '5',
        description: 'eJobee has been a game-changer for me. The Resume Builder is incredibly user-friendly, and the job platform provided me with tailored opportunities. I landed my dream job thanks to eJobee!'
    },
    {
        id: '2',
        img: 'https://randomuser.me/api/portraits/women/33.jpg',
        name: 'Priya Sharma',
        position: 'Data Analyst',
        title: 'Efficient and Effective',
        star: '4',
        description: 'Using eJobee\'s Resume Builder streamlined the application process for me. The platform\'s job recommendations were spot-on, saving me time and effort. I highly recommend eJobee for anyone serious about their career.'
    },
    {
        id: '3',
        img: 'https://randomuser.me/api/portraits/men/22.jpg',
        name: 'Rohan Kapoor',
        position: 'Social Media Specialist',
        title: 'Exceptional Service',
        star: '5',
        description: 'I can\'t speak highly enough about eJobee. The Resume Builder helped me create a professional and eye-catching resume effortlessly. The job platform\'s intuitive interface and accurate job matches make it a standout choice. Thank you, eJobee, for making job hunting a positive experience!'
    }
]

// jobs data

export const jobsdata = [
    {
        "id": "123456",
        "companyName": "Tech Innovators Inc.",
        "jobTitle": "Senior Software Engineer",
        "slug": "senior-software-engineer-at-tech-innovators",
        "location": "San Francisco, CA",
        "employmentType": "Full-Time",
        "description": "<p>We are looking for an experienced Senior Software Engineer to join our dynamic team...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Computer Science or related field</li><li>5+ years of software development experience</li><li>Strong proficiency in JavaScript, React, and Node.js</li><li>Excellent problem-solving and communication skills</li></ul><h5>Responsibilities:</h5><ul><li>Design and implement scalable software solutions</li><li>Collaborate with cross-functional teams to define, design, and ship new features</li><li>Conduct code reviews and provide constructive feedback</li><li>Participate in architectural discussions and contribute to decision-making processes</li></ul>",
        "skillsRequired": ["JavaScript", "React", "Node.js", "Software Architecture"],
        "education": "Bachelor's degree in Computer Science or related field",
        "englishLevel": "Advanced",
        "experience": "5+ years",
        "gender": "Any",
        "address": "123 Tech Street, San Francisco, CA",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?company,logo", // Random company profile picture URL
        "minSalary": 90000,
        "maxSalary": 120000,
        "applicationDeadline": "2024-03-15"
    },
    {
        "id": "654321",
        "companyName": "InnovateTech Solutions",
        "jobTitle": "Frontend Developer",
        "location": "Remote",
        "employmentType": "Full-Time",
        "description": "<p>We are seeking a skilled Frontend Developer to join our remote team...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Computer Science or related field</li><li>3+ years of frontend development experience</li><li>Proficiency in HTML, CSS, and JavaScript</li><li>Experience with modern frontend frameworks such as React or Vue</li></ul><h5>Responsibilities:</h5><ul><li>Develop and maintain responsive user interfaces</li><li>Collaborate with backend developers to integrate frontend components</li><li>Optimize web applications for maximum speed and scalability</li></ul>",
        "skillsRequired": ["HTML", "CSS", "JavaScript", "React"],
        "education": "Bachelor's degree in Computer Science or related field",
        "englishLevel": "Advanced",
        "experience": "3+ years",
        "gender": "Any",
        "address": "Remote",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?tech,office",
        "minSalary": 80000,
        "maxSalary": 110000,
        "applicationDeadline": "2024-03-20",
        "slug": "frontend-developer-at-innovatetech-solutions"
      },
      {
        "id": "987654",
        "companyName": "Digital Dynamics Inc.",
        "jobTitle": "Data Scientist",
        "location": "New York, NY",
        "employmentType": "Part-Time",
        "description": "<p>We are looking for a Data Scientist to join our analytics team in New York...</p><h5>Requirements:</h5><ul><li>Master's degree in Statistics, Computer Science, or a related field</li><li>2+ years of experience in data analysis and machine learning</li><li>Proficiency in Python and data visualization tools</li><li>Excellent analytical and problem-solving skills</li></ul><h5>Responsibilities:</h5><ul><li>Analyze and interpret complex datasets</li><li>Develop and implement machine learning models</li><li>Create data visualizations and reports</li></ul>",
        "skillsRequired": ["Python", "Machine Learning", "Data Analysis", "Statistics"],
        "education": "Master's degree in Statistics, Computer Science, or a related field",
        "englishLevel": "Advanced",
        "experience": "2+ years",
        "gender": "Any",
        "address": "New York, NY",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?data,office",
        "minSalary": 90000,
        "maxSalary": 120000,
        "applicationDeadline": "2024-03-25",
        "slug": "data-scientist-at-digital-dynamics"
      },
      {
        "id": "111222",
        "companyName": "E-commerce Innovators",
        "jobTitle": "Marketing Specialist",
        "location": "London, UK",
        "employmentType": "Contract",
        "description": "<p>We are hiring a Marketing Specialist to drive our e-commerce marketing strategies...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Marketing or a related field</li><li>3+ years of experience in digital marketing</li><li>Strong knowledge of SEO, SEM, and social media marketing</li><li>Excellent written and verbal communication skills</li></ul><h5>Responsibilities:</h5><ul><li>Develop and implement digital marketing campaigns</li><li>Optimize website content for search engines</li><li>Create and manage social media content</li></ul>",
        "skillsRequired": ["Digital Marketing", "SEO", "SEM", "Social Media Marketing"],
        "education": "Bachelor's degree in Marketing or a related field",
        "englishLevel": "Advanced",
        "experience": "3+ years",
        "gender": "Any",
        "address": "London, UK",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?marketing,office",
        "minSalary": 75000,
        "maxSalary": 100000,
        "applicationDeadline": "2024-03-18",
        "slug": "marketing-specialist-at-ecommerce-innovators"
      },
      {
        "id": "333444",
        "companyName": "HealthTech Solutions",
        "jobTitle": "Senior DevOps Engineer",
        "location": "Austin, TX",
        "employmentType": "Full-Time",
        "description": "<p>We are seeking a Senior DevOps Engineer to enhance our healthcare technology platform...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Computer Science or related field</li><li>5+ years of experience in DevOps and cloud technologies</li><li>Expertise in tools like Docker, Kubernetes, and Jenkins</li><li>Strong scripting skills (Python, Bash, etc.)</li></ul><h5>Responsibilities:</h5><ul><li>Implement and manage CI/CD pipelines</li><li>Automate deployment and infrastructure processes</li><li>Collaborate with development and operations teams</li></ul>",
        "skillsRequired": ["DevOps", "Docker", "Kubernetes", "Jenkins"],
        "education": "Bachelor's degree in Computer Science or related field",
        "englishLevel": "Advanced",
        "experience": "5+ years",
        "gender": "Any",
        "address": "Austin, TX",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?healthtech,office",
        "minSalary": 95000,
        "maxSalary": 130000,
        "applicationDeadline": "2024-03-22",
        "slug": "senior-devops-engineer-at-healthtech-solutions"
      },
      {
        "id": "555666",
        "companyName": "Creative Design Studios",
        "jobTitle": "Graphic Designer",
        "location": "Los Angeles, CA",
        "employmentType": "Freelance",
        "description": "<p>We are looking for a talented Graphic Designer to join our creative team on a freelance basis...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Graphic Design or a related field</li><li>2+ years of experience in graphic design</li><li>Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign)</li><li>Strong portfolio showcasing creative designs</li></ul><h5>Responsibilities:</h5><ul><li>Create visual concepts and graphics for various projects</li><li>Collaborate with the creative team on design projects</li><li>Ensure brand consistency in all design elements</li></ul>",
        "skillsRequired": ["Graphic Design", "Adobe Creative Suite", "Visual Concepts"],
        "education": "Bachelor's degree in Graphic Design or a related field",
        "englishLevel": "Advanced",
        "experience": "2+ years",
        "gender": "Any",
        "address": "Los Angeles, CA",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?design,office",
        "minSalary": 60000,
        "maxSalary": 80000,
        "applicationDeadline": "2024-03-17",
        "slug": "graphic-designer-at-creative-design-studios"
      },
      {
        "id": "777888",
        "companyName": "GreenTech Innovations",
        "jobTitle": "Environmental Scientist",
        "location": "Sydney, Australia",
        "employmentType": "Remote",
        "description": "<p>We are hiring an Environmental Scientist to contribute to our sustainable initiatives...</p><h5>Requirements:</h5><ul><li>Master's degree in Environmental Science or related field</li><li>3+ years of experience in environmental research</li><li>Expertise in GIS and data analysis tools</li><li>Passionate about sustainability and environmental conservation</li></ul><h5>Responsibilities:</h5><ul><li>Conduct environmental impact assessments</li><li>Analyze and interpret environmental data</li><li>Collaborate with cross-functional teams on green initiatives</li></ul>",
        "skillsRequired": ["Environmental Science", "GIS", "Data Analysis", "Sustainability"],
        "education": "Master's degree in Environmental Science or related field",
        "englishLevel": "Advanced",
        "experience": "3+ years",
        "gender": "Any",
        "address": "Sydney, Australia",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?environment,office",
        "minSalary": 85000,
        "maxSalary": 110000,
        "applicationDeadline": "2024-03-28",
        "slug": "environmental-scientist-at-greentech-innovations"
      },
      {
        "id": "999000",
        "companyName": "Finance Dynamics Ltd.",
        "jobTitle": "Financial Analyst",
        "location": "Toronto, Canada",
        "employmentType": "Full-Time",
        "description": "<p>We are seeking a Financial Analyst to join our finance team and drive data-driven decisions...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Finance, Accounting, or a related field</li><li>2+ years of experience in financial analysis</li><li>Advanced Excel skills and proficiency in financial modeling</li><li>Strong analytical and problem-solving abilities</li></ul><h5>Responsibilities:</h5><ul><li>Analyze financial data and trends</li><li>Prepare financial reports and forecasts</li><li>Provide insights to support strategic financial decisions</li></ul>",
        "skillsRequired": ["Financial Analysis", "Excel", "Financial Modeling", "Analytical Skills"],
        "education": "Bachelor's degree in Finance, Accounting, or a related field",
        "englishLevel": "Advanced",
        "experience": "2+ years",
        "gender": "Any",
        "address": "Toronto, Canada",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?finance,office",
        "minSalary": 80000,
        "maxSalary": 100000,
        "applicationDeadline": "2024-03-30",
        "slug": "financial-analyst-at-finance-dynamics"
      },
      {
        "id": "111222",
        "companyName": "Global Travel Adventures",
        "jobTitle": "Travel Consultant",
        "location": "Paris, France",
        "employmentType": "Hybrid",
        "description": "<p>We are looking for a Travel Consultant to assist our clients in planning memorable journeys...</p><h5>Requirements:</h5><ul><li>Degree in Travel and Tourism or a related field</li><li>Experience in travel consulting or customer service</li><li>Knowledge of popular travel destinations and itineraries</li><li>Excellent communication and interpersonal skills</li></ul><h5>Responsibilities:</h5><ul><li>Assist clients in planning and booking travel arrangements</li><li>Provide recommendations on destinations, accommodations, and activities</li><li>Ensure a seamless travel experience for clients</li></ul>",
        "skillsRequired": ["Travel Planning", "Customer Service", "Communication Skills", "Destination Knowledge"],
        "education": "Degree in Travel and Tourism or a related field",
        "englishLevel": "Advanced",
        "experience": "2+ years",
        "gender": "Any",
        "address": "Paris, France",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?travel,office",
        "minSalary": 70000,
        "maxSalary": 90000,
        "applicationDeadline": "2024-04-02",
        "slug": "travel-consultant-at-global-travel-adventures"
      },
      {
        "id": "333444",
        "companyName": "TechSupport Solutions",
        "jobTitle": "IT Support Specialist",
        "location": "Berlin, Germany",
        "employmentType": "Contract",
        "description": "<p>We are hiring an IT Support Specialist to provide technical assistance to our clients...</p><h5>Requirements:</h5><ul><li>Degree in Information Technology or a related field</li><li>Experience in providing IT support and troubleshooting</li><li>Knowledge of hardware, software, and network systems</li><li>Strong problem-solving and communication skills</li></ul><h5>Responsibilities:</h5><ul><li>Provide technical support to clients via phone, email, or in person</li><li>Troubleshoot hardware and software issues</li><li>Install and configure computer systems and applications</li></ul>",
        "skillsRequired": ["IT Support", "Troubleshooting", "Hardware", "Communication Skills"],
        "education": "Degree in Information Technology or a related field",
        "englishLevel": "Advanced",
        "experience": "2+ years",
        "gender": "Any",
        "address": "Berlin, Germany",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?tech,support,office",
        "minSalary": 75000,
        "maxSalary": 95000,
        "applicationDeadline": "2024-04-05",
        "slug": "it-support-specialist-at-techsupport-solutions"
      },
      {
        "id": "555666",
        "companyName": "FashionHub International",
        "jobTitle": "Fashion Designer",
        "location": "Milan, Italy",
        "employmentType": "Full-Time",
        "description": "<p>We are seeking a creative Fashion Designer to join our international fashion team...</p><h5>Requirements:</h5><ul><li>Degree in Fashion Design or a related field</li><li>3+ years of experience in fashion design</li><li>Proficiency in design software (e.g., Adobe Illustrator, Photoshop)</li><li>Strong portfolio showcasing fashion design projects</li></ul><h5>Responsibilities:</h5><ul><li>Create original fashion designs for seasonal collections</li><li>Collaborate with the design team on creative projects</li><li>Stay updated on fashion trends and industry developments</li></ul>",
        "skillsRequired": ["Fashion Design", "Adobe Illustrator", "Adobe Photoshop", "Creativity"],
        "education": "Degree in Fashion Design or a related field",
        "englishLevel": "Advanced",
        "experience": "3+ years",
        "gender": "Any",
        "address": "Milan, Italy",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?fashion,office",
        "minSalary": 85000,
        "maxSalary": 110000,
        "applicationDeadline": "2024-04-08",
        "slug": "fashion-designer-at-fashionhub-international"
      },
      {
        "id": "121314",
        "companyName": "Wellness Innovations",
        "jobTitle": "Wellness Coach",
        "location": "Barcelona, Spain",
        "employmentType": "Part-Time",
        "description": "<p>We are hiring a Wellness Coach to guide individuals towards a healthier lifestyle...</p><h5>Requirements:</h5><ul><li>Certification in Wellness Coaching or a related field</li><li>Experience in conducting wellness assessments</li><li>Knowledge of nutrition and fitness principles</li><li>Excellent interpersonal and motivational skills</li></ul><h5>Responsibilities:</h5><ul><li>Provide personalized wellness coaching sessions</li><li>Assist individuals in setting and achieving health goals</li><li>Educate clients on nutrition and lifestyle choices</li></ul>",
        "skillsRequired": ["Wellness Coaching", "Nutrition", "Fitness", "Interpersonal Skills"],
        "education": "Certification in Wellness Coaching or a related field",
        "englishLevel": "Intermediate",
        "experience": "2+ years",
        "gender": "Any",
        "address": "Barcelona, Spain",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?wellness,office",
        "minSalary": 60000,
        "maxSalary": 80000,
        "applicationDeadline": "2024-04-12",
        "slug": "wellness-coach-at-wellness-innovations"
      },
      {
        "id": "151617",
        "companyName": "Virtual Education Hub",
        "jobTitle": "Online Tutor",
        "location": "Remote",
        "employmentType": "Freelance",
        "description": "<p>We are looking for experienced Online Tutors to provide virtual education support...</p><h5>Requirements:</h5><ul><li>Bachelor's degree in Education or a related field</li><li>Experience in online tutoring and virtual classrooms</li><li>Expertise in a specific subject or academic area</li><li>Strong communication and teaching skills</li></ul><h5>Responsibilities:</h5><ul><li>Conduct virtual tutoring sessions for students</li><li>Provide guidance and assistance with academic subjects</li><li>Adapt teaching methods to individual student needs</li></ul>",
        "skillsRequired": ["Online Tutoring", "Subject Expertise", "Communication Skills", "Teaching"],
        "education": "Bachelor's degree in Education or a related field",
        "englishLevel": "Advanced",
        "experience": "3+ years",
        "gender": "Any",
        "address": "Remote",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?education,office",
        "minSalary": 50000,
        "maxSalary": 70000,
        "applicationDeadline": "2024-04-15",
        "slug": "online-tutor-at-virtual-education-hub"
      },
      {
        "id": "181920",
        "companyName": "GreenEnergy Innovations",
        "jobTitle": "Renewable Energy Engineer",
        "location": "Oslo, Norway",
        "employmentType": "Full-Time",
        "description": "<p>We are seeking a Renewable Energy Engineer to contribute to sustainable energy projects...</p><h5>Requirements:</h5><ul><li>Master's degree in Renewable Energy Engineering or related field</li><li>Experience in designing and implementing renewable energy systems</li><li>Knowledge of solar, wind, or other renewable technologies</li><li>Strong analytical and problem-solving skills</li></ul><h5>Responsibilities:</h5><ul><li>Design and optimize renewable energy systems</li><li>Conduct feasibility studies and energy assessments</li><li>Collaborate with project teams on green energy initiatives</li></ul>",
        "skillsRequired": ["Renewable Energy Engineering", "Solar", "Wind", "Analytical Skills"],
        "education": "Master's degree in Renewable Energy Engineering or related field",
        "englishLevel": "Advanced",
        "experience": "4+ years",
        "gender": "Any",
        "address": "Oslo, Norway",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?renewable-energy,office",
        "minSalary": 90000,
        "maxSalary": 120000,
        "applicationDeadline": "2024-04-18",
        "slug": "renewable-energy-engineer-at-greenenergy-innovations"
      },
      {
        "id": "212223",
        "companyName": "SmartHome Technologies",
        "jobTitle": "IoT Solutions Architect",
        "location": "Tokyo, Japan",
        "employmentType": "Full-Time",
        "description": "<p>We are looking for an IoT Solutions Architect to lead the design of smart home solutions...</p><h5>Requirements:</h5><ul><li>Bachelor's or Master's degree in Computer Science or a related field</li><li>Experience in designing and implementing IoT solutions</li><li>Knowledge of smart home technologies and protocols</li><li>Strong architectural and coding skills</li></ul><h5>Responsibilities:</h5><ul><li>Design end-to-end IoT solutions for smart homes</li><li>Collaborate with development teams on implementation</li><li>Stay updated on emerging IoT trends and technologies</li></ul>",
        "skillsRequired": ["IoT Solutions", "Smart Home Technologies", "Architecture", "Coding"],
        "education": "Bachelor's or Master's degree in Computer Science or a related field",
        "englishLevel": "Advanced",
        "experience": "5+ years",
        "gender": "Any",
        "address": "Tokyo, Japan",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?iot,office",
        "minSalary": 100000,
        "maxSalary": 130000,
        "applicationDeadline": "2024-04-22",
        "slug": "iot-solutions-architect-at-smarthome-technologies"
      },
      {
        "id": "242526",
        "companyName": "Foodie Delights Co.",
        "jobTitle": "Culinary Specialist",
        "location": "New Orleans, LA",
        "employmentType": "Hybrid",
        "description": "<p>We are hiring a Culinary Specialist to bring culinary creativity to our diverse food offerings...</p><h5>Requirements:</h5><ul><li>Degree in Culinary Arts or a related field</li><li>Experience in culinary creation and menu development</li><li>Passion for exploring and experimenting with diverse cuisines</li><li>Strong teamwork and communication skills</li></ul><h5>Responsibilities:</h5><ul><li>Create innovative and delicious culinary offerings</li><li>Collaborate with the culinary team on menu development</li><li>Stay updated on food trends and industry innovations</li></ul>",
        "skillsRequired": ["Culinary Arts", "Menu Development", "Creativity", "Teamwork"],
        "education": "Degree in Culinary Arts or a related field",
        "englishLevel": "Intermediate",
        "experience": "3+ years",
        "gender": "Any",
        "address": "New Orleans, LA",
        "isSaved": false,
        "isApplied": false,
        "companyProfilePic": "https://source.unsplash.com/200x200/?culinary,office",
        "minSalary": 70000,
        "maxSalary": 90000,
        "applicationDeadline": "2024-04-25",
        "slug": "culinary-specialist-at-foodie-delights"
      }                                                   
]