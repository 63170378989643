import React, { useState } from 'react'
import { admin_changepassword } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminChangePassword = () => {

  const [passwords, setPasswords] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const newErrors = {};

    if (!passwords.current_password) {
      newErrors.current_password = 'current Password is required';
    }

    if (!passwords.new_password) {
      newErrors.new_password = 'New Password is required';
    }else{
      if (passwords.new_password.length<6) {
        newErrors.new_password = 'password min. Length 6 is required';
      }
    }

    if (!passwords.confirm_password) {
      newErrors.confirm_password = 'Confirm Password is required';
    } else if (passwords.new_password !== passwords.confirm_password) {
      newErrors.confirm_password = 'Confirm Password is Not Match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updatepassword = () => {
    if (validateFields()) {
      admin_changepassword(passwords).then((data)=>{
        toast('✅ Password change Successfully', { autoClose: 3000 })
      }).catch((error)=>{
        toast('❌ ' + error.message, { autoClose: 3000 })
      })
    }
  }

  const onchangehandler = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setPasswords({
      ...passwords,
      [name]: value,
    });
  }

  return (
    <div className="container-fluid">
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0">Contact Details</h5>
        </div>
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <input value={passwords.current_password} onChange={onchangehandler} type="text" name='current_password' className="form-control" placeholder='Current Password' />
                  <span className="text-danger">{errors.current_password}</span>
                </div>
              </div>
              <div className="col-lg-6 mt-2">
                <div className="form-group">
                  <input type="text" name='new_password' value={passwords.new_password} onChange={onchangehandler} className="form-control" placeholder='New Password' />
                  <span className="text-danger">{errors.new_password}</span>
                </div>
              </div>
              <div className="col-lg-6 mt-2 ">
                <div className="form-group">
                  <input type="text" name='confirm_password' value={passwords.confirm_password} onChange={onchangehandler} className="form-control" placeholder='Confirm New Password' />
                  <span className="text-danger">{errors.confirm_password}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer border-0 py-5">
          <button onClick={updatepassword} className='btn btn-primary'>Update</button>
        </div>
      </div>
    </div>
  )
}

export default AdminChangePassword