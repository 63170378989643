import React from 'react'
import '../Admin.css'
import AdminNavbar from '../admin/component/AdminNavbar'
import AdminSidebar from '../admin/component/AdminSidebar'
import { Outlet } from 'react-router-dom'
import AdminCandidateDetail from '../admin/dialogue/AdminCandidateDetail'
import AdminCompaniesDetail from '../admin/dialogue/AdminCompaniesDetail'
import AdminJobsDetail from '../admin/dialogue/AdminJobsDetail'

const AdminLayout = () => {
  return (
    <>
      {/* dialogues */}
      {/* candidate */}
      <AdminCandidateDetail />

      {/* companies */}
      <AdminCompaniesDetail/>

      {/* job dialogue */}
      <AdminJobsDetail/>


      {/* <!-- Dashboard --> */}
      <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <AdminSidebar />
        {/* <!-- Main content --> */}
        <div class="h-screen flex-grow-1 overflow-y-lg-auto">
          <AdminNavbar />
          <main class="py-6 bg-surface-secondary">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  )
}

export default AdminLayout