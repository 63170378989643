import React, { useEffect, useState } from 'react'
import AdminAddjobrole from '../dialogue/AdminAddjobrole'
import { allIndustries_service, alljobrole_service } from '../../ApiServices'

const AdminJobRole = () => {

  const [industries, setindustries] = useState([]);
  const [jobroles, setjobroles] = useState([]);

  useEffect(() => {
    getindustries();
    getjobroles();
  }, [])


  const getjobroles = () =>{
    alljobrole_service().then((data)=>{
      setjobroles(data.data.data)
    }).catch((error)=>{

    })
  }
  

  const getindustries = () =>{
    allIndustries_service().then((data)=>{
      const mappedoptions = data.data.data.map((v)=>({
        value: v.id,
        label: v.name
      }))
      setindustries(mappedoptions);
    }).catch((error)=>{

    })
  }

  return (
    <div className="container-fluid">
      <AdminAddjobrole industries={industries} getjobroles={getjobroles} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Job Role</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addJobRole" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Industry</th>
                <th scope="col">Job Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                jobroles.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.industry.name}
                      </td>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#addstate" class="btn btn-sm btn-neutral text-danger-hover">
                          Edit
                        </button>
                        {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button> */}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {/* <div class="card-footer border-0 py-5">
          <span class="text-muted text-sm">Showing 10 items out of 250 results found</span>
        </div> */}
      </div>
    </div>
  )
}

export default AdminJobRole