import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import Select from 'react-select'
import { getcityservice, getcountryservice, getstateservice, update_location } from '../../ApiServices';
import { toast } from 'react-toastify';

const Locations = ({ country, state, city_name,getprofile}) => {

  const closedialogueRef = useRef();
//alert(city_name);
  const [formData, setformData] = useState({
    country: { value: country.id, label: country.name },
    state: { value: state.id, label: state.name },
    city_name:city_name
    //city: { value: city.id, label: city.name }
  });
  const [errors, seterrors] = useState({})


  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([])
  const [cities, setcities] = useState([])


  const validateform = () => {

    const newErrors = {};

    if (!formData.country) {
      newErrors.country = 'country is Required';
    }

    if (!formData.state) {
      newErrors.state = 'State is Required';
    }

    if (!formData.city_name) {
      newErrors.city_name = 'City is Required';
    }

    seterrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  useEffect(() => {
    getcountry();
    getstate(country.id)
    //getcities(state.id);
  }, [])



  const canceldialogue = () => {
    seterrors({})

    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  
  const HandleChange = (e) =>{
    //alert(e.target);
    const {name,value} = e.target;
    setformData({
      ...formData,
      [name]:value
    })
    seterrors({})
  }

  const handleSelectChange = (data, action) => {
    seterrors((prevErrors) => ({
      ...prevErrors,
      [action.name]: '',
    }));
    setformData({
      ...formData,
      [action.name]: data
    })
    if (action.name == "country") {
      getstate(data.value);
    }

    if (action.name == "state") {
      //getcities(data.value);
    }
  }


  const getcountry = () => {
    getcountryservice().then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setcountries(mappedoptions)
    }).catch((error) => {
      getcountry()
    })
  }

  const getstate = (countryid) => {
    getstateservice(countryid).then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setstates(mappedoptions)
    }).catch((error) => {
      getstate();
    })
  }

  const getcities = (stateid) => {
    // getcityservice(stateid).then((data) => {
    //   const mappedoptions = data.data.map((v) => ({
    //     value: v.id,
    //     label: v.name
    //   }));
    //   setcities(mappedoptions)
    // }).catch((error) => {
    //   getcities();
    // })
  }

  const submit = () => {
    if (validateform()) {
      update_location(formData).then((res) => {
        toast('✅ Location Updated Successfully');
        canceldialogue();
        getprofile()
      }).catch((err) => {

      })
    }
  }

  return (
    <div class="modal fade" id="addLocations" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit Locations            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="country"
                placeholder={'Select country'}
                value={formData.country}
                onChange={handleSelectChange}
                options={countries}
              />
              {errors.country && <span className="text-danger">{errors.country}</span>}

              <Select
                className="basic-single mt-2"
                classNamePrefix="select"
                isSearchable={true}
                name="state"
                placeholder="Select State"
                value={formData.state}
                onChange={handleSelectChange}
                options={states}
              />
              {errors.state && <span className="text-danger">{errors.state}</span>}

              <input className='form-control' placeholder="Current City" value={formData.city_name}  onChange={HandleChange} name='city_name' />
              {errors.city_name && <span className="text-danger">{errors.city_name}</span>}
              {/* <Select
                className="basic-single mt-2"
                classNamePrefix="select"
                placeholder={'Select City'}
                isSearchable={true}
                name="city"
                value={formData.city}
                onChange={handleSelectChange}
                options={cities}
              /> */}
              

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Locations