import React from 'react'

const CompanyNotification = () => {
  return (
    <section style={{ padding: '10px 10px' }}>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6">
            <img className='w-100' src="/assets/images/empty-notification.png" alt="Ejobee" />
          </div>
          <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
            <h2 style={
              {
                color: 'var(--primary-color)',
              }
            }>You Don't Have Any Notifcation Yet</h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyNotification