
import { Link, useNavigate } from 'react-router-dom';
import './comp_ejobee_section_resume.css';
import resumeImg from '../../c_assets/home/home_resume.jpg';
//assets/images/356.png

const Comp_Ejobee_Section_resume=(props)=>{
    
    return(
        <div class="container-fluid bg-gray-trans1 pt-100 pb-100 bgimage_main position-relative half-fluid resumediv">
            <div class="container">
            <h2 class="vc_custom_heading ico_header">Quick and Quality Resume Creation Tool</h2>
            <hr/>
              <div  data-aos111="fade-up" class="row ">
          
             
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <Link to={'/resume_sample'} ><div class="image"> <img src={resumeImg} alt=""/></div></Link>
                </div>
          
                
                <div class="col-lg-6 col-md-6 col-sm-12 mt-auto">
                  <p><strong>Personalized Templates: </strong>
                    Select from a wide range of professionally designed resume templates tailored to various industries and career levels. Whether you’re a fresh graduate or a seasoned professional, we have the perfect design for you.</p>
                  <p><strong>Instant Preview: </strong>
                    See how your resume will look with instant previews as you design. Make changes in real-time and ensure your resume looks perfect before you download it.</p>
                    <p><strong>User-Friendly Interface:</strong>
                        Our intuitive design tools make it easy to create a standout resume without any design skills.</p>
                    <p><strong>Brand Your Professional Image:</strong>
                        Use our templates to craft a resume that reflects your personal brand.</p>
                        <Link to={'/resume_sample'} className='btn btn-secondary bg-white mt-5'>Create Now</Link>
                </div>
              </div>
            </div>
          </div>
    );
}
export default Comp_Ejobee_Section_resume;