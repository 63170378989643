import React, { useRef, useState } from 'react'
import { postcurrencyservice } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddCurrency = ({getcurrency}) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    name: '',
    symbol: ''
  })

  const [errors, seterrors] = useState({});

  const validateform = () => {
    const newerrors = {}

    if (!formdata.name) {
      newerrors.name = "Name is Require";
    }

    if (!formdata.symbol) {
      newerrors.symbol = "Symbol is Required";
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0
  }

  const HandleChange = (e) => {
    const { name, value } = e.target
    setformdata({
      ...formdata,
      [name]: value
    })
    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const canceldialogue = () => {
    setformdata({
      name: '',
      symbol: '',
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      postcurrencyservice(formdata).then((data)=>{  
        toast('✅ Currency Added Successfully');
          canceldialogue();
          getcurrency();
      }).catch((error)=>{

      })
    }
  }

  return (
    <div class="modal fade" id="addcurrency" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Currency
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <input type="text" className="form-control mt-2" placeholder='Currency Symbol' onChange={HandleChange} value={formdata.symbol} name='symbol' />
              {errors.symbol && <span className='text-danger'>{errors.symbol}</span>}

              <input type="text" className="form-control mt-2" placeholder='Currency Name' onChange={HandleChange} value={formdata.name} name='name' />
              {errors.name && <span className='text-danger' >{errors.name}</span>}

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAddCurrency