import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { company_profile_service } from '../../ApiServices';

const Navbar = () => {

  const navigate = useNavigate();

  const [name, setname] = useState('');
  const [freepost, setfreepost] = useState('');
  const checkTest=localStorage.getItem("admin-token");
  useEffect(() => {
    getprofile();
    
  }, [])

  const removeTest=()=>{
    localStorage.removeItem("company_id");
    localStorage.removeItem("company_is_profile_completed");
  }

  const getprofile = () => {
    const id = localStorage.getItem('company_id');
    if (id) {
      company_profile_service(id).then((data) => {
        //alert(JSON.stringify(data.data));
        setname(data.data.company.companyname);
        setfreepost(data.data.company.freejobpost);
      }).catch((error) => {
        console.log(error);
      })
    } else {
      navigate('/login')
    }
  }

  return (
    <nav>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={''} className="logo" style={{ width: '150px' }}>
            <img className='w-100' src="/assets/logo/logo.png" alt="EJOBEE ICON" />
          </Link>
          <div className="d-flex gap-5">
            {/* <div className='btn btn-sm border' style={{ color: 'var(--primary-color)' }}>Free Post : {freepost}</div> */}
            <ul className='d-flex gap-3 m-0 align-items-center'>
              <li>
                <div className="dropdown">
                  <div className='fw-bold dropdown-toggle mb-0' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" style={{ color: 'var(--primary-color)' }}>
                  <i class="far fa-user-circle green-color  fa-2x ml-2 mr-2"></i>,Hello
                  {/* hello,<i class="fas fa-bars"></i> */}
                  
                    {/* {name} */}
                    </div>
                  <ul className='dropdown-menu mt-2' aria-labelledby="dropdownMenuButton1">
                    {checkTest?<li><Link class="dropdown-item" onClick={removeTest} to={'/admin/companies'}>Back to Admin</Link></li>:''}
                    <li><Link class="dropdown-item" to={'/company'}>Dashboard</Link></li>
                    <li><Link class="dropdown-item" to={'/company/profile'}>Profile</Link></li>
                    {/* <li><Link class="dropdown-item" to={'/company/notification'}>Notifications</Link></li> */}
                    <li>
                      <a href={"javascript:void(0)"} class="dropdown-item text-danger" data-bs-toggle="modal" data-bs-target="#logoutdialogue">Logout</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar