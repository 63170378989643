import React, { useEffect, useState } from 'react'
import AdminAddCurrency from '../dialogue/AdminAddCurrency'
import AdminEditCurrency from '../dialogue/AdminEditCurrency'
import { getallcityservice, getallcurrencyservice } from '../../ApiServices';

const AdminCurrency = () => {

  const [currencies, setcurrencies] = useState([]);
  const [currencyid, setcurrencyid] = useState(null);

  useEffect(() => {
    getallcurrencies();
  }, [])

  const getallcurrencies = () => {
    getallcurrencyservice().then((data) => {
      setcurrencies(data.data);
    })
  }


  return (
    <div className="container-fluid">
      {/* currency */}
      <AdminAddCurrency getcurrency={getallcurrencies} />
      <AdminEditCurrency getcurrency={getallcurrencies} curid={currencyid} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">Currency</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addcurrency" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Symbol</th>
                <th scope="col">Currency</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                currencies.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.symbol}
                      </td>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button onClick={()=>setcurrencyid(v.id)} type="button" data-bs-toggle="modal" data-bs-target="#editcurrency" class="btn btn-sm btn-neutral text-danger-hover">
                          Edit
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminCurrency