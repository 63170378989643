import React, { useEffect, useState } from 'react'
import { companyjobsbyid_service, postjobpreview_service } from '../../ApiServices'
import { toast } from 'react-toastify'
import { json } from 'react-router-dom'
//import { useNavigate, useParams } from 'react-router-dom'

export const JobPreview=(props)=>{
  let bkbtnclick=props.bkbtnclick;
  let nxbtnclick=props.nxbtnclick;
  let jobid=props.jobid;
  let setformstep=props.setformstep;
  
 
  const continuebtnclick = () => {
    postjobpreview_service(jobid).then((res) => {
      nxbtnclick()
    }).catch((error) => {
      toast('❌' + error.message);
    })
  }

  const [jobsdata, setjobsdata] = useState({});

  useEffect(() => {
    //alert(props.jobid);
    jobid=props.jobid;
    if (jobid !== null) {
      //alert("i am in data calss");
      companyjobsbyid_service(jobid).then((res) => {
       // alert(JSON.stringify(res.data.jobs[0]));
        setjobsdata(res.data.jobs[0]);
      }).catch((error) => {
        toast('❌' + error.message);
      })
    }
  }, [props.jobid,props.fromStep])


  const backbtnclick = () => {
    bkbtnclick()
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between border-bottom">
              <div>Job Detail</div>
              <div className='d-flex gap-2'>
                <div onClick={() => setformstep(1)}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="#EBF3FE"></rect><g clip-path="url(#clip0_502_26996)"><path d="M10 19.6397V21.6664C10 21.853 10.1467 21.9997 10.3333 21.9997H12.36C12.4467 21.9997 12.5333 21.9664 12.5933 21.8997L19.8733 14.6264L17.3733 12.1264L10.1 19.3997C10.0333 19.4664 10 19.5464 10 19.6397ZM21.8067 12.693C22.0667 12.433 22.0667 12.013 21.8067 11.753L20.2467 10.193C19.9867 9.93305 19.5667 9.93305 19.3067 10.193L18.0867 11.413L20.5867 13.913L21.8067 12.693Z" fill="#0074E8"></path></g><defs><clipPath id="clip0_502_26996"><rect width="16" height="16" fill="white" transform="translate(8 8)"></rect></clipPath></defs></svg>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1985_82249)"><path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill="#5E6C84"></path></g><defs><clipPath id="clip0_1985_82249"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Company Name
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.company?.companyname}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Job Tite
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.jobrole_name}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Industry
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.industry_name}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Job Type
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.job_type?.name} 
                </div>
              </div>
              {jobsdata?.is_night===1 || jobsdata?.isnight==="1"?<>
                <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Urgent Hiring
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  Yes
                </div>
              </div>
              </>:''}

              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Work Type
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.work_type?.name}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Job Location
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.office_address}, {jobsdata?.jobcity?.name}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Monthly Salary
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  ₹ {jobsdata?.min_salary} - ₹
                  {jobsdata?.max_salary} per month
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Additional perks
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.perks?.length != 0 ?
                    jobsdata?.perks?.map((v,index) => {
                      return (
                        <>{v.label} {index<jobsdata.perks.length-1?',':''}</>
                      )
                    })
                    : 'none'}
                </div>
              </div>
              {/* <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Joining Fees
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata.is_fees==="false"?'No' : ' Yes'}
                </div>
              </div> */}
              {
                jobsdata.is_fees==="true" ?''
                  // <div className='d-flex gap-3 mt-4'>
                  //   <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  //     Fee Amount
                  //   </div>
                  //   <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  //     {jobsdata?.fee_amount}/-
                  //   </div>
                  // </div>
                  : ''
              }

            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className='card mt-2'>
            <div className="card-header d-flex align-items-center justify-content-between border-bottom">
              <div>Qualifying Requirement</div>
              <div className='d-flex gap-2'>
                <div onClick={() => { setformstep(2) }}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="#EBF3FE"></rect><g clip-path="url(#clip0_502_26996)"><path d="M10 19.6397V21.6664C10 21.853 10.1467 21.9997 10.3333 21.9997H12.36C12.4467 21.9997 12.5333 21.9664 12.5933 21.8997L19.8733 14.6264L17.3733 12.1264L10.1 19.3997C10.0333 19.4664 10 19.5464 10 19.6397ZM21.8067 12.693C22.0667 12.433 22.0667 12.013 21.8067 11.753L20.2467 10.193C19.9867 9.93305 19.5667 9.93305 19.3067 10.193L18.0867 11.413L20.5867 13.913L21.8067 12.693Z" fill="#0074E8"></path></g><defs><clipPath id="clip0_502_26996"><rect width="16" height="16" fill="white" transform="translate(8 8)"></rect></clipPath></defs></svg>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1985_82249)"><path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill="#5E6C84"></path></g><defs><clipPath id="clip0_1985_82249"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Minimum Education
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.education_level?.name}
                </div>
              </div>

              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Degree/Exam-Board
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.degree_name}
                </div>
              </div>

              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Experience Required
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {/* {jobsdata?.experience == "experienced" ? 'Yes' : "No"} */}
                  {jobsdata?.experience}
                </div>
              </div>
              {jobsdata?.experience==="experienced"?<div className='d-flex gap-3 mt-4'>
                  <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Minimum Experience
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.exp_year} Years
                </div>
                </div>:''}
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  English
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.english_level}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Age
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.minage} - {jobsdata?.maxage} yrs
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Gender
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.gender}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4 pb-4 border-bottom'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Skills
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {
                    jobsdata?.skills ?
                      JSON.parse(jobsdata?.skills).map((v,index) => {
                        return (
                          <>{v.label}{index<JSON.parse(jobsdata.skills).length-1?',':''}</>
                        )
                      }) : "None"
                  }
                </div>
              </div>
              <div className='d-flex gap-3 mt-4 pb-4 border-bottom'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Job Description
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  <div dangerouslySetInnerHTML={{ __html: jobsdata?.job_description }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className='card mt-2'>
            <div className="card-header d-flex align-items-center justify-content-between border-bottom">
              <div>Interviewer Information</div>
              <div className='d-flex gap-2'>
                <div onClick={() => setformstep(3)}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="#EBF3FE"></rect><g clip-path="url(#clip0_502_26996)"><path d="M10 19.6397V21.6664C10 21.853 10.1467 21.9997 10.3333 21.9997H12.36C12.4467 21.9997 12.5333 21.9664 12.5933 21.8997L19.8733 14.6264L17.3733 12.1264L10.1 19.3997C10.0333 19.4664 10 19.5464 10 19.6397ZM21.8067 12.693C22.0667 12.433 22.0667 12.013 21.8067 11.753L20.2467 10.193C19.9867 9.93305 19.5667 9.93305 19.3067 10.193L18.0867 11.413L20.5867 13.913L21.8067 12.693Z" fill="#0074E8"></path></g><defs><clipPath id="clip0_502_26996"><rect width="16" height="16" fill="white" transform="translate(8 8)"></rect></clipPath></defs></svg>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1985_82249)"><path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill="#5E6C84"></path></g><defs><clipPath id="clip0_1985_82249"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  HR Details
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.recruiter_name}, {jobsdata?.recruiter_whatsapp}
                </div>
              </div>
              <div className='d-flex gap-3 mt-4'>
                <div className='col-lg-6' style={{ fontSize: '14px' }}>
                  Type of Interview
                </div>
                <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                  {jobsdata?.interview_type == 1 ? 'in person Interview' : "Online/ telephonic Interview"}
                </div>
              </div>
              {
                jobsdata?.interview_type == 1 ?
                  <div className='d-flex gap-3 mt-4'>
                    <div className='col-lg-6' style={{ fontSize: '14px' }}>
                      Interview Address
                    </div>
                    <div className='col-lg-6' style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
                     {jobsdata.interview_address}
                    </div>
                  </div>
                  : ""
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-lg-12 text-center">
          <div onClick={backbtnclick} className="btn btn-primary me-2">Back</div>
          <div onClick={continuebtnclick} className="btn btn-primary">Next</div>
        </div>
      </div>

    </div>
  )
}

export default JobPreview