import React, { useState } from 'react'
import { Link } from 'react-router-dom'


const AdminSidebar = () => {

  const [navdropdown, setnavdropdown] = useState(false)

  const navmenuclick = () =>{
    setnavdropdown(!navdropdown);
  }

  return (
    <nav class={`navbar collapse navbar-collapse navbar-vertical navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg admin-navbar ${navdropdown?'':'collapse'} `} id="navbarVertical" style={{overflow:'hidden'}}>
      <div class="container-fluid" style={{height:'100%'}}>
        {/* <!-- Toggler --> */}
        {/* <button onClick={navmenuclick} class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> */}
        {/* <!-- Brand --> */}
        <Link class="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" to={''}>
          <img className='w-100' src="/assets/logo/logo.png" alt="..." />
        </Link>
        {/* <!-- User menu (mobile) --> */}
        <div class="navbar-user d-lg-none">
          {/* <!-- Dropdown --> */}
          <div class="dropdown">
            {/* <!-- Toggle --> */}
            <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="avatar-parent-child">
                <img className='w-100' alt="Image Placeholder" src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" class="avatar avatar- rounded-circle" />
                <span class="avatar-child avatar-badge bg-success"></span>
              </div>
            </a>
            {/* <!-- Menu --> */}
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
              {/* <a href="#" class="dropdown-item">Profile</a>
              <a href="#" class="dropdown-item">Settings</a>
              <a href="#" class="dropdown-item">Billing</a> */}
              <hr class="dropdown-divider" />
              <a href="#" class="dropdown-item">Logout</a>
            </div>
          </div>
        </div>
        {/* <!-- Collapse --> */}
        <div class="collapse1 navbar-collapse1" id="sidebarCollapse" style={{height:'100%'}}>
          {/* <!-- Navigation --> */}
          <ul class="navbar-nav" style={{overflowY:'scroll',height:'100%',paddingBottom:'100px'}}>
            <li class="nav-item">
              <Link class="nav-link" to={'dashboard'}>
                <i class="bi bi-house"></i> Dashboard
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={'candidate'}>
                <i class="bi bi-people"></i> Candidates
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={'companies'}>
                <i class="bi bi-building"></i> Companies
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={'jobs'}>
                <i class="bi bi-bar-chart"></i> Jobs
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={'job-plan'}>
                <i class="bi bi-bar-chart"></i> Job Posting Plans
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link class="nav-link" to={'coupons'}>
                <i class="bi bi-bar-chart"></i> Coupons
              </Link>
            </li> */}
            <li class="nav-item">
              <Link class="nav-link" to={'contact-forms'}>
                <i class="bi bi-chat"></i> Contact forms
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link class="nav-link" to={'resumes'}>
                <i class="bi bi-chat"></i> Resumes
              </Link>
            </li> */}
            <li class="nav-item">
              <Link class="nav-link" to={'country'}>
                <i class="bi bi-people"></i> Commons
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={'education-level'}>
                <i class="bi bi-people"></i> Options
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link class="nav-link" to={'about'}>
                <i class="bi bi-option"></i> Content
              </Link>
            </li> */}
            {/* <li class="nav-item">
              <Link class="nav-link" to={'blogs'}>
                <i class="bi bi-option"></i> Blogs Category
              </Link>
            </li> */}
            <li class="nav-item">
              <Link class="nav-link" to={'blogs'}>
                <i class="bi bi-option"></i> Blogs Post
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={'change-password'}>
                <i class="bi bi-people"></i> Change Password
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default AdminSidebar