import React from 'react';
import { Link } from 'react-router-dom';
export const CandidateOption=(props)=>{
    //alert(props.pathname);
    if(localStorage.getItem('candidate_id')){
        return( <div className="row tab-bar scroll-hide" style={{ flexWrap: 'nowrap', overflowX: 'scroll', padding: '30px 0px' }}>

            <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/dashboard'} className={props.pathname==="/dashboard"?"card p-tab active":" card p-tab"}>
                            Dashboard
                        </Link>
                    </div>
            
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/profile'} className={props.pathname==="/profile"?"card p-tab active":" card p-tab"}>
                            profile
                        </Link>
                    </div>
                    {/* <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/notification'} className={props.pathname==="/notification"?"card p-tab active":" card p-tab"}>
                            Notification
                        </Link>
                    </div> */}
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/saved-jobs'} className={props.pathname==="/saved-jobs"?"card p-tab active":" card p-tab"}>
                            Saved Jobs
                        </Link>
                    </div>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/applied-jobs'} className={props.pathname==="/applied-jobs"?"card p-tab active":" card p-tab"}>
                            Applied Jobs
                        </Link>
                    </div>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/resume_sample'} className={props.pathname==="/resume_sample" || props.pathname.indexOf("/resume_sample/")!==-1 ?"card p-tab active":" card p-tab"}>
                            Make a Resume
                        </Link>
                    </div>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/candidate-payments'} className={props.pathname==="/candidate-payments"?"card p-tab active":" card p-tab"}>
                            Payment History
                        </Link>
                    </div>
                </div>)
    }else{
        return(<></>)
    }
   

}
export default CandidateOption;