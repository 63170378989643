import React, { useEffect, useState } from 'react'
import AdminAddJobPlan from '../dialogue/AdminAddJobPlan'
import { alljobplan_service, jobplanbyid_service } from '../../ApiServices';
import AdminEditJobPlan from '../dialogue/AdminEditJobPlan';

const AdminJobPlan = () => {

    const [jobplans, setjobplans] = useState([]);
    const [jobplanid, setjobplanid] = useState(null);

    useEffect(() => {
        getaljobplan();
    }, [])

    const getaljobplan = () => {
        alljobplan_service().then((res) => {
            setjobplans(res.data.plans)
        }).catch((err) => {

        })
    }


    return (
        <div className="container-fluid">

            <AdminAddJobPlan getobplan={getaljobplan} />
            <AdminEditJobPlan getobplan={getaljobplan} jobplanid={jobplanid}/>

            <div class="card shadow border-0 mb-7">
                <div class="card-header d-flex justify-content-between">
                    <h5 class="mb-0">Job Posting Plans</h5>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#addjobplan" class="btn btn-sm btn-neutral text-danger-hover">
                        <i class="bi bi-plus"></i>
                        Add
                    </button>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">description</th>
                                <th scope="col">price</th>
                                <th scope="col">validity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                jobplans.map((v) => {
                                    return (
                                        <tr>
                                            <td>
                                                {v.title}
                                            </td>
                                            <td>
                                                {v.description.substring(0,30)}
                                            </td>
                                            <td>
                                                {v.price}
                                            </td>
                                            <td>
                                                {v.validity}
                                            </td>
                                            <td class="text-end">
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#editjobplan" class="btn btn-sm btn-neutral text-danger-hover" onClick={() => setjobplanid(v.id)}>
                                                    Edit
                                                </button>
                                                {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="bi bi-trash"></i>
                                                </button> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AdminJobPlan