import React, { useEffect, useRef, useState } from 'react'
import AdminBlogDetails from '../dialogue/AdminBlogDetails'
import AdminAddBlog from '../dialogue/AdminAddBlog'
import { blog_post_service } from '../../ApiServices';
import { public_url } from '../../ApiUrl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AdminEditBlog from '../dialogue/AdminEditBlog';

const AdminBlogs = () => {

  const navigate = useNavigate();

  const [blogpost, setblogpost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [blogid, setblogid] = useState(null)


  const getallblogpost = () => {
    if (hasMore) {
      blog_post_service(page).then((res) => {
        const newblogpost = res.data.data;
        setblogpost(prevblogpost => [...prevblogpost, ...newblogpost]);
        setHasMore(res.data.current_page < res.data.last_page);
        setPage(prevPage => prevPage + 1);
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }




  useEffect(() => {
    getallblogpost();
  }, [])





  return (
    <>
      {/* blog dialogue */}
      <AdminBlogDetails blogid={blogid} />
      <AdminAddBlog getblogpost={getallblogpost} />
      <AdminEditBlog getblogpost={getallblogpost} blogid={blogid} />
      <div className="container-fluid">
        <div class="card shadow border-0 mb-7">
          <div class="card-header d-flex justify-content-between">
            <h5 class="mb-0">Blogs</h5>
            <button type="button" data-bs-toggle="modal" data-bs-target="#addblog" class="btn btn-sm btn-neutral text-danger-hover">
              <i class="bi bi-plus"></i>
              Add
            </button>
          </div>
          <div class="table-responsive">
            <InfiniteScroll
              dataLength={blogpost.length}
              next={getallblogpost}
              hasMore={hasMore}
              loader={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0px' }}>
                <div style={{ width: '20px', height: '20px' }} class="loading-loader"></div>
              </div>}
            // endMessage={<p>No more candidates to show.</p>}
            >
              <table class="table table-hover table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Image</th>
                    <th scope="col">Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    blogpost.map((v) => {
                      return (
                        <tr>
                          <td>
                            {v.title.substring(0,30)}
                          </td>
                          <td>
                            <img width={'100px'} src={public_url + v.image} />
                          </td>
                          <td>
                            {v.content.substring(0, 30)}
                          </td>
                          <td class="text-end">
                            <button onClick={() => setblogid(v.id)} data-bs-toggle="modal" data-bs-target="#blogdetail" class="btn btn-sm btn-neutral">View</button>
                            <button data-bs-toggle="modal" onClick={() => setblogid(v.id)} data-bs-target="#editblog" class="btn btn-sm btn-neutral">Edit</button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminBlogs