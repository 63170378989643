import React from 'react'
import { useNavigate } from 'react-router-dom'

const LogoutModel = () => {

    const navigate = useNavigate();

    const logoutbtnclick = () =>{
        localStorage.clear();
        navigate('/');
    }

    return (
        // {/* <!-- logout Modal --> */}
        <div class="modal fade" id="logoutdialogue" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Are You Sure You Want to Logout ?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">cancel</button>
                        <button onClick={logoutbtnclick} data-bs-dismiss="modal" type="button" class="btn btn-danger" style={{ borderRadius: '0' }}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutModel