import React, { useEffect, useState } from 'react'
import { allcontact_form } from '../../ApiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminContactForm = () => {

  const [contactForms, setcontactForms] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    allcontact_form().then((data) => {
      setcontactForms(data.data.contactsform)
    }).catch((error) => {
      toast('❌' + error.message);
      if (error.message === 'Unauthorized: Please log in.') {
        localStorage.clear();
        navigate('/')
        window.location.reload();
      }
    })
  }, [])


  return (
    <div className="container-fluid">
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0">Contact Form</h5>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                contactForms.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.name}
                      </td>
                      <td>
                        {v.email}
                      </td>
                      <td>
                        {v.mobile}
                      </td>
                      <td>
                        {v.subject}
                      </td>
                      <td>
                        {v.message}
                      </td>
                      <td class="text-end">
                        <button data-bs-toggle="modal" data-bs-target="#blogdetail" class="btn btn-sm btn-neutral">View</button>
                        <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div class="card-footer border-0 py-5">

        </div>
      </div>
    </div>
  )
}

export default AdminContactForm