import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { alljobshift_service, alljobtype_service, allworktype_service, getallcityservice, update_job_prefence } from '../../ApiServices';
import { toast } from 'react-toastify';

const JobPreferences = ({ prefcity_name, prefjobtype, prefworktype, prefjobshift, getprofile }) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    city_name: prefcity_name,
    //pref_city: { value: prefcity.id, label: prefcity.name },
    pref_jobtype: { value: prefjobtype.id, label: prefjobtype.name },
    pref_worktype: { value: prefworktype.id, label: prefworktype.name },
    pref_jobshift: { value: prefjobshift.id, label: prefjobshift.name }
  })

  const [errors, seterrors] = useState({})
  const [allcities, setallcities] = useState([]);
  const [alljobtype, setalljobtype] = useState([]);
  const [allworktype, setallworktype] = useState([]);
  const [alljobshift, setalljobshift] = useState([]);

  useEffect(() => {

    // city
    // getallcityservice().then((data) => {
    //   const mappedoptions = data.data.map((v) => ({
    //     value: v.id,
    //     label: v.name
    //   }));
    //   setallcities(mappedoptions)
    // }).catch((error) => { })

    // jobtype
    alljobtype_service().then((data) => {
      const mappedoptions = data.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setalljobtype(mappedoptions)
    }).catch((error) => {

    })

    // worktype
    allworktype_service().then((data) => {
      const mappedoptions = data.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setallworktype(mappedoptions)
    })

    // jobshift
    alljobshift_service().then((data) => {
      const mappedoptions = data.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setalljobshift(mappedoptions)
    })

  }, [])


  const canceldialogue = () => {
    seterrors({})

    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  
  const HandleChange = (e) =>{
    const {name,value} = e.target;
    setformdata({
      ...formdata,
      [name]:value
    })
    seterrors({})
  }

  const HandleSelectChange = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    })
  }

  const validateform = () => {
    const newErrors = {};

    // if (!formdata.pref_city) {
    //   newErrors.pref_city = "Preferred city is Required"
    // }
    if(!formdata.city_name){
      newErrors.city_name="Preferred city is Required";
    }


    if (!formdata.pref_jobshift) {
      newErrors.pref_jobshift = "Preferred Jobshift is Required";
    }

    if (!formdata.pref_jobtype) {
      newErrors.pref_jobtype = "Preferred JobType is Required";
    }

    if (!formdata.pref_worktype) {
      newErrors.pref_worktype = "Preferred WorkType is Required"
    }

    seterrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const submit = () => {
    if (validateform()) {
      update_job_prefence(formdata).then((res)=>{
        toast('✅ Job Prefences Updated Successfully');
        canceldialogue()
        getprofile()
      }).catch((err)=>{

      })
    }
  }

  return (
    <div class="modal fade" id="addjobpreference" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit Preferences
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div className="form-group">
            <input className='form-control' placeholder="Current City" value={formdata.city_name} onChange={HandleChange} name='city_name' />
              {/* <ReactSelect options={allcities} placeholder="Select Preferred City" value={formdata.pref_city} onChange={HandleSelectChange} name='pref_city' /> */}
              {errors.city_name && <span className='text-danger'>{errors.city_name}</span>}
            </div>

            <div className="form-group mt-2">
              <ReactSelect options={alljobtype} placeholder="Select Preferred Job Type" value={formdata.pref_jobtype} onChange={HandleSelectChange} name='pref_jobtype' />
              {errors.pref_jobtype && <span className='text-danger'>{errors.pref_jobtype}</span>}
            </div>

            <div className="form-group mt-2">
              <ReactSelect options={allworktype} placeholder="Select Preferred Work Type" value={formdata.pref_worktype} onChange={HandleSelectChange} name='pref_worktype' />
              {errors.pref_worktype && <span className='text-danger'>{errors.pref_worktype}</span>}
            </div>

            <div className="form-group mt-2">
              <ReactSelect options={alljobshift} placeholder="Select Preferred Job Shift" value={formdata.pref_jobshift} onChange={HandleSelectChange} name='pref_jobshift' />
              {errors.pref_jobshift && <span className='text-danger'>{errors.pref_jobshift}</span>}
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobPreferences