import React, { useState } from 'react'
import { admin_login } from '../../ApiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const AdminLogin = () => {

  const navigate = useNavigate();

  const [formdata, setformdata] = useState({
    email: '',
    password: '',
  });

  const [errors, seterrors] = useState({});

  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };


  const handlechange = (e) => {
    const { name, value } = e.target;

    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setformdata({
      ...formdata,
      [name]: value,
    });
  }

  const validateform = () => {
    const newErrors = {};
    if (!formdata.email.trim()) {
      newErrors.email = 'Username is required';
    }

    if (!formdata.password) {
      newErrors.password = 'Password is required';
    }

    seterrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }


  const adminloginformsubmit = async (e) => {
    e.preventDefault();
    if (validateform()) {
      admin_login(formdata).then((data) => {
        localStorage.setItem('admin-token', data.data.token)
        toast('✅ Login Successfully', { autoClose: 3000 })
        navigate('/admin');
        window.location.reload();
      }).catch((error) => {
        toast('❌ ' + error.message, { autoClose: 3000 })
      });
    }
  }

  return (
    <section className='main-login h-screen'>
      <div className="container page-body-wrapper full-page-wrapper h-screen">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-6">
            <div className="row align-items-center justify-content-center h-screen">
              <div className="col-lg-6">
                <img src="/assets/images/login-admin.png" alt="EJOBEE" />
              </div>
              <div className="col-lg-6 login-modal-main admin-login-main">
                <div class="auth-form-light admin-login text-left">
                  <div class="brand-logo" style={{
                    marginBottom: "16px",
                    width: '150px'
                  }}>
                    <img className='w-100' src="/assets/logo/logo.png" />
                  </div>
                  <h4>Welcome Admin</h4>
                  <h6 class="fw-light">Sign in to Explore Ejobee Dashboards</h6>
                  <form onSubmit={adminloginformsubmit} class="pt-3 gap-3 d-flex flex-column">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="username"
                        id="email"
                        name="email"
                        onChange={handlechange}
                        value={formdata.email}
                      />
                      {errors.email && <span className="text-danger">{errors.email}</span>}
                    </div>
                    <div class="form-group" style={{ position: "relative" }}>
                      <input
                        type={passwordVisible2 ? "text" : "password"}
                        class="form-control"
                        id="password"
                        style={{ position: 'relative' }}
                        placeholder="Enter password"
                        name="password"
                        value={formdata.password}
                        onChange={handlechange}
                      />
                      {errors.password && <span className="text-danger">{errors.password}</span>}
                      <span
                        className="material-symbols-outlined"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility2}
                      >
                        {passwordVisible2 ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>}
                      </span>
                    </div>
                    <div class="mt-3">
                      <button
                        class="btn btn-block btn-primary btn-sm font-weight-medium auth-form-btn pe-5 ps-5 pt-2 pb-2"
                        type="submit"
                      >
                        SIGN IN
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </section>
  )
}

export default AdminLogin