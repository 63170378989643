export const base_url ='https://www.ejobee.com/v1/public/api/'
export const public_url = 'https://www.ejobee.com/v1/public/storage/'

// export const base_url ='https://ejobee.com/v1/public/api/';
// export const public_url = 'https://ejobee.com/v1/public/storage/';

// export const ash_bublic_url="https://newb.ejobee.com/";
// export const ash_base_url="https://newb.ejobee.com/ash_ejob/controller/call_auth.php?pm=";

export const ash_bublic_url="https://www.ejobee.com/";
export const ash_base_url="https://www.ejobee.com/ash_ejob/controller/call_auth.php?pm=";
//export const ash_base_url="https://newbase.ejobee.com/controller/call_auth.php?pm=";

// admin login
export const admin_login_url = "admin/login";
export const admin_changepassword_url = "admin/changepassword";

// candidate
export const candidate_send_otp = "candidate/send-otp";
export const candidate_resend_otp_url = "candidate/resend-otp";
export const candidate_verify_otp_url = "candidate/verify-otp";
export const candidate_profile_url = "candidate/profile"

export const candidate_personal_details_url = "candidate/complete-personal-detail";
export const candidate_education_details_url = "candidate/complete-education-detail";
export const candidate_experience_url = "candidate/complete-experience-detail";
export const candidate_preferences_url = "candidate/complete-preference-detail";
export const edit_total_experience_url = "candidate/edit-total-experience";
export const edit_current_salary_url = "candidate/edit-current-salary";

export const add_work_experience_url = "candidate/add-work-experience";
export const edit_work_experience_url = "candidate/edit-work-experience";
export const workexperiencebyid_url = "candidate/work-experience/";
export const deleteexperiecnce_url = "candidate/delete-work-experience";

export const addeducationdetail_url = 'candidate/add-education-detail';
export const educationdetailbyid_url = "candidate/education-detail/";
export const editeducationdetailbyid_url = "candidate/edit-education-detail"
export const deleteeducationdetail_url = "candidate/delete-education-detail"

export const updateenglishlevel_url = "candidate/update-english-level";

export const updateeducationlevel_url = "candidate/update-education-level";

export const updatelocation_url = "candidate/update-location";

export const updateprefences_url = "candidate/update-preference-detail";

export const updatebasicdetail_url = "candidate/update-personal-detail";

export const upload_resume_url = "candidate/upload-resume";
export const upload_profilpic_url = "candidate/update-profilepic";
export const upload_introvideo_url="candidate/upload-intro-video";

export const updateskill_url = "candidate/update-skills";

export const updatelanguage_url = "candidate/update-language";

// company
export const company_sendotp_url = "company/send-otp";

export const company_resendotp_url = "company/resend-otp";

export const company_verifyotp_url = "company/verify-otp";

export const company_completeprofileurl = "company/complete-profile";
export const company_profileurl = "company/profile";
export const company_updateprofileurl = "company/update-profile";


// post job
export const postjobdetail_url = "company/post-job/job-detail";
export const postcandidaterequirement_url = "company/post-job/candidate-requirement/";
export const postinterviewerdetail_url = "company/post-job/interview-information/";
export const postjobpreview_url = "company/post-job/preview/";
export const postupdatejobdetail_url = "company/post-job/update-job-detail/"
export const freejobpostpublish_url = "company/post-job/free-job-publish/";


// latest published jobs
export const latestpublishjob_url = "candidate/latest-published-jobs";

// job detail by slug
export const jobdetailbyslug_url = "candidate/job-detail-by-slug/"


// all jobs (published)
export const alljobs_url = "candidate/all-jobs"


// apply job
export const applyjob_url = "job/apply";

// save job
export const savejob_url = "job/save";

// unsave job
export const unsavejpb_url = "job/save/"

// search candiddate
export const searchcandidate_url = "company/search-candidate";

// save job list
export const savejoblist_url = "candidate/saved-jobs/";

// application list
export const applicationlist_url ="candidate/applied-jobs/";


// blog post
export const blog_post_url = 'blogs/posts'

// country
export const country_url = "countries";

// state
export const state_url = "states"
export const statebycountry_url = "states/country/";

// city
export const city_url = "cities";
export const citybystate_url = "cities/state";

// language
export const language_url = "languages";

// currency
export const currency_url = "currencies";

// education level
export const education_level_url = "education-levels";

// university
export const university_url = "universities";

// degree
export const degree_url = "degrees";

// Industry
export const industry_url = "industries";

// Job ROle
export const job_role_url = "job-roles"


// university
export const skills_url = "skills";

// university
export const perks_url = "perks";

// work types
export const worktype_url = "work-types";

// job types
export const jobtype_url = "job-types";

// job shift
export const jobshift_url = "job-shifts";


// job-plans
export const jobplan_url = "job-plans";


// contact form
export const contact_form_url = "contactform";

// testimonial
export const testimonial_url = "";

// contact Detail
export const contact_detail_url = "contact-detail";
export const update_contact_detail_url = "contact-detail";

// premium
export const buy_premium_url = "candidate/buy-premium"


// dashboard data
export const dashboarddata_url = "admin/dashboard";

// admin canidate
export const admincandidate_url = "admin/allcandidate"

// admin company
export const admincompany_url = "admin/allcompanies";

// admin all jobs
export const adminalljobs_url = "admin/alljobs";