import React, { useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { addperk_service } from '../../ApiServices';

const AdminAddPerks = ({getperks}) => {


    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        name:''
    })

    const [errors, seterrors] = useState({})


    const HandleChange = (e) =>{
        const {name,value} = e.target;
        setformdata({
            ...formdata,
            [name]:value
        })
        seterrors((preverror)=>({
            ...preverror,
            [name]:''
        }))
    }


    const canceldialogue = () =>{
        setformdata({
            jobrole:'',
            name:''
        })
        seterrors({})
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const validateform = () =>{
        const newerror = {};

        if (!formdata.name) {
            newerror.name = "Perk Name is Required";
        }

        seterrors(newerror);
        return Object.keys(newerror).length === 0;
    }

    const submit = () =>{
        if (validateform()) {
            addperk_service(formdata).then((data)=>{
                console.log(data);
                toast('✅ Perks Added Successfully');
                canceldialogue()
                getperks();
            }).catch((error)=>{

            })
        }
    }
    
  return (
    <div class="modal fade" id="addPerks" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Add Perks
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <input type="text" className="form-control mt-2" placeholder='Perks Name' onChange={HandleChange} value={formdata.name} name='name' />
                            {errors.name && <span className='text-danger'>{errors.name}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AdminAddPerks