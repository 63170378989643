import React, { useRef, useState } from 'react'
import { adduniversityservice } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddUniversity = ({getuniversity}) => {

    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        name: ''
    })

    const [errors, seterrors] = useState({});

    const HandleChange = (e) => {
        const { name, value } = e.target;

        setformdata({
            ...formdata,
            [name]: value
        })

        seterrors((preverror) => ({
            ...preverror,
            [name]: ''
        }))
    }

    const validateform = () => {
        const newerror = {};

        if (!formdata.name) {
            newerror.name = "Universiy/college Name is Required";
        }

        seterrors(newerror);
        return Object.keys(newerror).length === 0;
    }

    const canceldialogue = () => {
        setformdata({
            name: '',
        })
        seterrors({});
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const submit = () => {
        if (validateform()) {
            adduniversityservice(formdata).then((data) => {
                console.log(data);
                toast('✅ University/college Added Successfully');
                canceldialogue();
                getuniversity();
            }).catch((error) => {

            })
        }
    }

    return (
        <div class="modal fade" id="adduniversity" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Add University
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <input type="text" className="form-control mt-2" placeholder='University/college Name' onChange={HandleChange} value={formdata.name} name='name' />
                            {errors.name && <span className='text-danger'>{errors.name}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAddUniversity