import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import axios from "axios"
import { education_level_service, update_education_level } from '../../ApiServices';
import { toast } from 'react-toastify';

import {getCondidatesView} from '../../ApiServices';
import './comp_payment_option.css';

export const Comp_Payment_Option = (props) => {

    const navigate = useNavigate();

const [getCandidateProfile,setCandidateProfile]=useState({});

const [response, setResponse] = useState(null);

  const makePayment = async () => {
    
    try {
     
      const res = await axios.post('https://newb.ejobee.com/ash_ejob/controller/call_auth.php?pm=getPaymentPhone',[]);
      //alert(JSON.stringify(res.data));
      setResponse(res.data);
    } catch (error) {
      console.error(error);
    }




  
  };
   
//alert(props.candidateId);
const fetchProfileRecords = () => {
    
    let map={cand_id:btoa(props.candidateId)};

      getCondidatesView(map,"getCandidateProfView").then((res) => {
        
        const newCandidates = JSON.parse(res.data);
        
       setCandidateProfile(newCandidates);
       
       
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    
  }

    useEffect(() => {
        if(props.candidateId!==null){
            //fetchProfileRecords()
        }
    }, [props.candidateId])



    return (
        <div class="modal fade" id="divCompPaymentOption" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Choose Payment Option
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="container">
        <div class="row">
            <div class="col-lg-4 mb-lg-0 mb-3">
                <div class="card p-3">
                    <div class="img-box">
                        <img src="https://www.freepnglogos.com/uploads/visa-logo-download-png-21.png" alt="" />
                    </div>
                    <div class="number">
                        <label class="fw-bold" for="">**** **** **** 1060</label>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <small><span class="fw-bold">Expiry date:</span><span>10/16</span></small>
                        <small><span class="fw-bold">Name:</span><span>Kumar</span></small>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-lg-0 mb-3">
                <div class="card p-3">
                    <div class="img-box">
                        <img src="https://www.freepnglogos.com/uploads/mastercard-png/file-mastercard-logo-svg-wikimedia-commons-4.png"
                            alt="" />
                    </div>
                    <div class="number">
                        <label class="fw-bold">**** **** **** 1060</label>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <small><span class="fw-bold">Expiry date:</span><span>10/16</span></small>
                        <small><span class="fw-bold">Name:</span><span>Kumar</span></small>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-lg-0 mb-3">
                <div class="card p-3">
                    <div class="img-box">
                        <img src="https://www.freepnglogos.com/uploads/discover-png-logo/credit-cards-discover-png-logo-4.png"
                            alt="" />
                    </div>
                    <div class="number">
                        <label class="fw-bold">**** **** **** 1060</label>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <small><span class="fw-bold">Expiry date:</span><span>10/16</span></small>
                        <small><span class="fw-bold">Name:</span><span>Kumar</span></small>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="card p-3">
                    <p class="mb-0 fw-bold h4">Payment Methods</p>
                </div>
            </div>
            <div class="col-12">
                <div class="card p-3">
                    <div class="card-body border p-0">
                       <div class="row">
                        <div class="col-md-3">
                            <input type="radio" name="payOptions" id="optPhonePay"/>
                            <label for="optPhonePay" class="ml-2"> <b>Phone Pay</b></label>
                        </div>

                        <div class="col-md-6">
                            <input type="radio" name="payOptions" id="optCCA"/>
                            <label for="optCCA" class="ml-2"> <b>Debit Card/Credit Card/Net Banking</b></label>
                        </div>

                       </div>
                       
                    </div>
                  
                </div>
            </div>
            <div class="col-12">
            <div id="resPrespPay">{response!==null?JSON.stringify(response):''}</div>
                <button type="button" onClick={props.makeCc} class="btn btn-primary payment">
                    Make Payment
                </button>
            </div>
        </div>
    </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Comp_Payment_Option