import React, { useEffect, useState } from 'react'
import { Link,useLocation } from 'react-router-dom'
import JobCard from '../component/JobCard'
import Comp_JobCard from '../component/Comp_JobCard';
import { savejoblist_service } from '../../ApiServices'
import { toast } from 'react-toastify';
import {getCondidatesView} from '../../ApiServices';
import CandidateOption from './CandidateOption';

const SavedJobs = () => {
    const location = useLocation();

    const [savejobdata, setsavejobdata] = useState([]);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        fetchcandidate()
    }, [])

   
  const fetchcandidate = () => {
    let map={ser_candidate_id:btoa(localStorage.getItem('candidate_id'))};
    
      getCondidatesView(map,"getCandidateSavedJobs").then((res) => {
        const newCandidates = JSON.parse(res.data).saved_jobs;
        //alert(newCandidates);
        setsavejobdata(newCandidates);
        setloading(false);
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          //navigate('/')
          window.location.reload();
        }
      })
    
  }


    return (
        <>
            <div className="container">
            <CandidateOption pathname={location.pathname} />
            </div>

            {
                loading ? <section>
                    <div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div class="loading-loader"></div>
                    </div>
                </section> : <section className='main-can-profile' style={{ padding:'10px 0px 50px 0px' }}>
                    <div className="container">
                        {
                            savejobdata.length != 0 ? <div className='row' style={{ rowGap: '10px' }}>
                                {
                                    savejobdata.map((v) => {
                                        return (
                                            <Comp_JobCard savedJobs={v}  />
                                        )
                                    })
                                }
                            </div> : <div className='row'>
                                <div className="col-lg-6 d-flex justify-content-center">
                                    <img style={{ width: '200px' }} src="/assets/images/savejob_img.png" />
                                </div>
                                <div className="col-lg-6 d-flex align-items-center text-center justify-content-center flex-column gap-3">
                                    <h3 style={{ color: 'var(--primary-color)' }}>You haven't Save Any Job.</h3>
                                </div>
                            </div>
                        }
                    </div>
                </section >
            }

        </>
    )
}

export default SavedJobs