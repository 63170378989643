import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { blog_post_service, blogpostbyid_service } from '../../ApiServices';
import { public_url } from '../../ApiUrl';
import { toast } from 'react-toastify';

const BlogDetail = () => {

    const { id, slug } = useParams();
    const [blog, setblog] = useState(null)
    const [blogpost, setblogpost] = useState([])

    useEffect(() => {
        getsingleblog();
    }, [slug])


    useEffect(() => {
        getallblogpost();
    }, [])


    const getsingleblog = () => {
        blogpostbyid_service(id).then((res) => {
            setblog(res.data[0])
        }).catch((error) => {
            toast('❌' + error.message);
        })
    }


    const getallblogpost = () => {
        blog_post_service(1).then((res) => {
            const newblogpost = res.data.data;
            setblogpost(newblogpost);
        }).catch((error) => {

        })
    }

    if (blog) {
        return (
            <>
                <Helmet>
                    <title> {blog.title} | EJOBEE Blog</title>
                    <meta name="description" content={`Read on ${blog.title} the eJobee blog. Gain valuable insights, tips, and career advice to enhance your professional journey`} />
                </Helmet>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div class="blog">
                                    <div class="blog-img">
                                        <img src={public_url + blog.image} style={{ width: '100%' }} alt="" />
                                    </div>
                                    <div class="blog-content">
                                        <h2 class="blog-title">{blog.title}</h2>
                                        <div style={{ width: '100%', overflowX: 'hidden' }} dangerouslySetInnerHTML={{ __html: blog.content }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <h4 className='mb-4'>Related Blogs</h4>
                                <div className="row" style={{ rowGap: '30px' }}>
                                    {
                                        blogpost.slice(0, 3).map((b) => {
                                            return (
                                                <Link to={`/blogs/${b.id}/${b.slug}`} className="col-lg-12">
                                                    <div class="blog">
                                                        <div class="blog-img">
                                                            <img style={{ width: '100%', height: '250px', objectFit: 'contain' }} src={public_url + b.image} alt="" />
                                                        </div>
                                                        <div class="blog-content">
                                                            <h2 class="blog-title">{b.title}</h2>
                                                            <p class="blog-desc" dangerouslySetInnerHTML={{ __html: b.content.substring(0, 150) }} ></p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    } else {
        return (
            <section>
                <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div class="loading-loader"></div>
                </div>
            </section>
        )
    }

}

export default BlogDetail