import React, { useEffect, useState } from 'react'
import { admindashboarddata_service } from '../../ApiServices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminDashboard = () => {

  const [candidatecount, setcandidatecount] = useState('');
  const [companycount, setcompanycount] = useState('');
  const [jobscount, setjobscount] = useState('');
  const [latestcandidate, setlatestcandidate] = useState([]);
  const [latestcompany, setlatestcompany] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    admindashboarddata_service().then((res) => {
      setcandidatecount(res.data.candidate_count);
      setcompanycount(res.data.company_count);
      setjobscount(res.data.job_count)
      setlatestcandidate(res.data.latest_candidates);
      setlatestcompany(res.data.latest_companies);
    }).catch((error) => {
      toast('❌' + error.message);
      if (error.message === 'Unauthorized: Please log in.') {
        localStorage.clear();
        navigate('/')
        window.location.reload();
      }
    })
  }, [])


  return (
    <div class="container-fluid">
      {/* <!-- Card stats --> */}
      <div class="row g-6 mb-6">
        <div class="col-xl-4 col-sm-6 col-12">
          <div class="card shadow border-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <span class="h6 font-semibold text-muted text-sm d-block mb-2">Candidates</span>
                  <span class="h3 font-bold mb-0">{candidatecount}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                    <i class="bi bi-people"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 col-12">
          <div class="card shadow border-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <span class="h6 font-semibold text-muted text-sm d-block mb-2">Companies</span>
                  <span class="h3 font-bold mb-0">{companycount}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-primary text-white text-lg rounded-circle">
                    <i class="bi bi-people"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 col-12">
          <div class="card shadow border-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <span class="h6 font-semibold text-muted text-sm d-block mb-2">Jobs</span>
                  <span class="h3 font-bold mb-0">{jobscount}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-info text-white text-lg rounded-circle">
                    <i class="bi bi-clock-history"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* latest candidate */}
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0">Latest Candidate</h5>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">mobile</th>
                <th scope="col">Email</th>
                <th scope="col">City</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                latestcandidate.map((v) => {
                  return (
                    <tr>
                      <td>
                        {
                          v.profilepic ? <img alt="..." src="https://images.unsplash.com/photo-1612422656768-d5e4ec31fac0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" class="avatar avatar-sm rounded-circle me-2" /> : <img alt="..." src="/assets/images/user_icon.png" class="avatar avatar-sm rounded-circle me-2" />
                        }
                        {
                          v.firstname==null?
                          '': v.firstname + " " + v.lastname
                        }
                      </td>
                      <td>
                        {v.mobile}
                      </td>
                      <td>
                        {v.email}
                      </td>
                      <td>
                        {v.city?.name}
                      </td>
                      <td class="text-end">
                        <button data-bs-toggle="modal" data-bs-target="#candidatedetail" class="btn btn-sm btn-neutral">View</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>

      {/* latest companies */}
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0">Latest Companies</h5>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">mobile</th>
                <th scope="col">Email</th>
                <th scope="col">City</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                latestcompany.map((v) => {
                  return (
                    <tr>
                      <td>
                        {/* <img alt="..." src="https://images.unsplash.com/photo-1612422656768-d5e4ec31fac0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" class="avatar avatar-sm rounded-circle me-2" /> */}
                        {v.companyname}
                      </td>
                      <td>
                        {v.mobile}
                      </td>
                      <td>
                        {v.email}
                      </td>
                      <td>
                        {v.city?.name}
                      </td>
                      <td class="text-end">
                        <button data-bs-toggle="modal" data-bs-target="#companydetail" class="btn btn-sm btn-neutral">View</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard