import './comp_image_slider.css';
export const Comp_Image_Slider=(props)=>{
    let clsName="blog-slider";

    return (<div class="main-blog-area bg-area1 pb-10 ">
    <div class="container">
            <div class="row">
                <div style={{"text-align":"center"}} class="col-md-12">
                    <div style={{"display":"inline-block"}} class="flag bottom-boder heading section-heading  page__title-bar">
                        {/* // "<h5 class=\"title text-white ml-1 title\">"+title+"</h5>"+ */}
                    </div>
                </div>
            </div>
        
    <div class={clsName +" swiper-container slider-nav-style-1"} data-slides-per-view="3">
    <div class="new-product-wrapper swiper-wrapper">
    {/* content part */}
    {props.lsData.map((item,index)=>{
        return (<div class="single-blog swiper-slide">
    <a href={"#"}>
    <img style={{"width":"100%"}} src={item["col4"]}  alt={"feature_expo"}/>
    <div class="blog-image">
    {/* // "<h6 class=\"central_blog_txt title1\"></h6>"+ */}
    </div>
</a></div>)
    })}
    {/* end of content part */}
    </div>
<div class="swiper-buttons">
<div class="swiper-button-next"></div>
<div class="swiper-button-prev"></div>
</div>
</div></div></div>
 
            )

}