import React from 'react'
import { Helmet } from 'react-helmet'

const Privacy = () => {
  return (
    <>
    <Helmet>
      <title>
      Privacy Policy - Your Trust Matters at ejobee
      </title>
    </Helmet>
      <section>
        <div className="container">
          <div className="section-title mb-3">
            <h4>Privacy Policy</h4>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <h4>Introduction -</h4>
               At Ejobee, Your privacy is important to us. We are committed to safeguarding the privacy and security of our users, including both job seekers and employers. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our job portal. Whether you're a candidate looking for your next opportunity or a company searching for the right talent, we take your privacy seriously and strive to be transparent about our data practices. Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-lg-12">
              <h4>Information We Collect From Candidates -</h4>
              <p class="mt-2">
              <b>Personal Information -</b> Includes your name, email address, phone number, postal address, date of birth, gender, and other details provided during registration or profile creation.
              </p>

              <p>
              <b>Profile Data -</b> Includes resumes, skills, job preferences, work experience, education details, and intro videos.
              </p>

              <p>
              <b>Transaction Data -</b> Details of any transactions you carry out, such as payments for premium services.
              </p>

              <p>
              <b>Communication Data -</b> Any communication you have with Ejobee, including emails, chats, or feedback.
              </p>

            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <h4>Information We Collect From Job seekers -</h4>
              <p class="mt-2">
              <b>Company Information -</b> Includes the company name, industry, size, address, and contact details provided during registration.
              </p>

              <p>
              <b>Job Posting Data -</b> Details of the job postings you create, including job descriptions, requirements, salary ranges, and other relevant information.
              </p>

              <p>
              <b>Transaction Data -</b> Details of any payments made for job postings or premium services.
              </p>

              <p>
              <b>Communication Data -</b> Any communication your company has with Ejobee, including emails, chats, or support requests.
              </p>

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-lg-12">
              <h4>How We Use Candidates Information -</h4>
              <p class="mt-2">
              <b>To Provide Services -</b> To create and manage your account, enable you to apply for jobs, and allow employers to view your profile and intro video.
              </p>

              <p>
              <b>To Improve Our Services -</b> To analyze usage patterns and feedback to enhance the functionality and content of our platform.
              </p>

              <p>
              <b>To Process Transactions -</b> To handle payments for premium services.
              </p>

              <p>
              <b>To Communicate with You -</b> To send you job alerts, updates, and respond to your inquiries.
              </p>

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-lg-12">
              <h4>How We Use Job seekers Information -</h4>
              <p class="mt-2">
              <b>To Provide Services -</b> To create and manage your company profile, enable job postings, and allow you to search and view candidate profiles and intro videos.
              </p>

              <p>
              <b>To Improve Our Services -</b> To analyze usage data and feedback to improve the platform’s functionality and user experience.
              </p>

              <p>
              <b>To Process Transactions -</b> To handle payments for job postings and premium services.
              </p>

              <p>
              <b>To Communicate with You -</b> To send notifications, updates, and respond to your inquiries.
              </p>

            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <h4>Information Sharing and Disclosure -</h4>
              <p><b>Ejobee may share your information in the following circumstances -</b></p>
              <p class="mt-2">
                <b>Candidate’s Information -</b> <br/>
                <b>With Employers -</b> Your profile information, including resumes and intro videos, will be shared with companies using our platform to find candidates.
              </p>

              <p>
              <b>With Service Providers -</b> We may share your information with third-party service providers who assist us in operating our platform, processing payments, or providing customer support.
              </p>

              <p>
              <b>In Business Transfers -</b> If Ejobee is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
              </p>

              <p>
              <b>With Legal Authorities -</b> We may disclose your information if required to do so by law or in response to valid requests from public authorities.
              </p>

            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <p><b>Job seekers Information -</b></p>
              <p class="mt-2">
               <b>With Candidates -</b> Job postings and company information will be visible to candidates using our platform to search for jobs.
              </p>

              <p>
              <b>With Service Providers -</b> We may share your information with third-party service providers who assist in operating our platform, processing payments, or providing customer support.
              </p>

              <p>
              <b>In Business Transfers -</b> If Ejobee is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
              </p>

              <p>
              <b>With Legal Authorities -</b> We may disclose your information if required to do so by law or in response to valid requests from public authorities.
              </p>

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-lg-12">
              <p><b>Data Security-</b></p>
              <p class="mt-2">
              We prioritize the security of your data and implement appropriate technical and organizational measures to protect it from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
              </p>

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-lg-12">
              <p><b>Your Rights-</b></p>
              <p>You have the following rights regarding your personal data -</p>
              <p class="mt-2">
              <b>Access Correction -</b> You can control the information you provide through your account settings. You may choose not to share certain information, but this may limit your access to some features.
              </p>

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-lg-12">
              <p><b>Cookies and Similar Technologies-</b></p>
              <p>We use cookies and similar technologies to enhance your experience and collect data for analytics.</p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <p><b>Changes to This Privacy Policy-</b></p>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12">
              <p><b>Contact Us-</b></p>
              <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at Ejobee Support Team Email: yourejobee@gmail.com</p>
            </div>
          </div>




        </div>
      </section>
    </>
  )
}

export default Privacy