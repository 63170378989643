import React, { useEffect, useRef, useState } from 'react'
import { languagebyid, updatelanguagebyid_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminEditLanguages = ({ langid, getlanguage }) => {

  const closedialogueRef = useRef();

  const navigate = useNavigate();

  const [formdata, setformdata] = useState({
    name: '',
    code: ''
  })
  const [errors, seterrors] = useState({});

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    })

    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const validateform = () => {
    const newerror = {}

    if (!formdata.name) {
      newerror.name = "Language Name is Required";
    }

    if (!formdata.code) {
      newerror.code = "Language Code is Required";
    }

    seterrors(newerror);
    return Object.keys(newerror).length === 0;
  }

  const canceldialogue = () => {
    setformdata({
      name: '',
      code: '',
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  useEffect(() => {
    if (langid != null) {
      languagebyid(langid).then((res) => {
        console.log(res.data);
        setformdata({
          name: res.data[0].name,
          code: res.data[0].code
        })
      }).catch((err) => {

      })
    }
  }, [langid])


  const submit = () => {
    console.log(formdata);
    if (validateform()) {
      updatelanguagebyid_service(langid, formdata).then((res) => {
        toast('✅ Language updated Successfully');
        canceldialogue();
        getlanguage();
      }).catch((error) => {
        toast('❌' + error.message);
      if (error.message === 'Unauthorized: Please log in.') {
        localStorage.clear();
        navigate('/')
        window.location.reload();
      }
      })
    }
  }

  return (
    <div class="modal fade" id="editlanguages" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit Language
            <button type="button" ref={closedialogueRef} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">

              <input type="text" className="form-control mt-2" placeholder='Language Name' value={formdata.name} onChange={HandleChange} name='name' />
              {errors.name && <span className='text-danger'>{errors.name}</span>}

              <input type="text" className="form-control mt-2" placeholder='Language code' value={formdata.code} onChange={HandleChange} name='code' />
              {errors.code && <span className='text-danger'>{errors.code}</span>}

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEditLanguages