import React, { useEffect, useState } from 'react'
import { MultiStepForm, Step } from 'react-multi-form'
import CandidateRequirement from './CandidateRequirement'
import InterviewInformation from './InterviewInformation'
import JobPreview from './JobPreview'
import SelectPlan from './SelectPlan'
import CompanyJobDetail from './CompanyJobDetail'
import { useNavigate, useParams } from 'react-router-dom'
import { companyjobsbyid_service,buy_premium_service } from '../../ApiServices'

const PostJob = () => {

  const [formstep, setformstep] = useState(1)
  const [job_id, setjob_id] = useState(null);

  const navigate = useNavigate();

  const { jobid } = useParams();

  useEffect(() => {
    if (jobid != null) {
      //alert("i min post job main"+jobid);
      setjob_id(jobid)
      //alert("parent post"+job_id);
      companyjobsbyid_service(jobid).then((res) => {
        //alert(JSON.stringify(res.data));

        
        if (res.data.jobs[0].job_detail) {
          setformstep(2);
        }

        if (res.data.jobs[0].candidate_requirement) {
          setformstep(3);
        }

        if (res.data.jobs[0].interview_question) {
          setformstep(4)
        }

        if (res.data.jobs[0].job_preview) {
          setformstep(5)
        }

        if (res.data.jobs[0].select_plan) {
          //navigate('/company');
        }
      }).catch((error) => {

      })
    }
  }, [jobid])


  // form1
  const cjdnextbtn = () => {
    setformstep(2)
  }

  // form2
  const crnextbtn = () => {
    setformstep(3)
  }

  const crbackbtn = () => {
    setformstep(1)
  }

  // step3
  const ivbackbtnclick = () => {
    setformstep(2)
  }

  const ivnextbtnclick = () => {
    setformstep(4)
  }

  // step4
  const jpbackbtnclick = () => {
    setformstep(3);
  }

  const jpnextbtnclick = () => {
    setformstep(5);
  }

  // form5

  const spbackbtnclick = () => {
    setformstep(4);
  }

  const spnextbtnclick = (jobid,formdata) => {
    //console.log('payment');
    //alert("i m in click");
    //here is payment page to place
    if (localStorage.getItem('company_id')) {
      let map={"company_id":localStorage.getItem('company_id'),
        "user_type":2,
        "job_id":job_id,
        "plan_id":formdata.planid,
        "planAmount":formdata.planAmount
      }
      // alert(formdata.planid);
      // return;

      buy_premium_service(map).then((response) => {
        //alert(JSON.stringify(response));
        //return;
          if (response.data.payment_url) {
              //return;
              // console.log(response.data.payment_url);
              window.location.href = response.data.payment_url;
          }
      }).catch((error) => {
          console.log(error);
      })
  } else {
      navigate('/login')
  }
  }


  return (
    <div className="container mt-5">
      <MultiStepForm activeStep={formstep}  accentColor={'var(--primary-color)'}>
        <Step className="mob-h"  label="Job Details">
          <CompanyJobDetail fromStep={formstep} nbclick={cjdnextbtn} jobid={job_id} setjobid={setjob_id} />
        </Step>
        <Step  label="Qualifying Requirement">
          <CandidateRequirement fromStep={formstep} prevclick={crbackbtn} nxbtnclick={crnextbtn} setjobid={setjob_id} jobid={job_id} />
        </Step>
        <Step  label="Interviewer Information">
          <InterviewInformation bkbtnclick={ivbackbtnclick} nxbtnclick={ivnextbtnclick} jobid={job_id} />
        </Step>
        <Step  label="Preview">
          <JobPreview fromStep={formstep} bkbtnclick={jpbackbtnclick} nxbtnclick={jpnextbtnclick} setjobid={setjob_id} jobid={job_id} setformstep={setformstep}/>
        </Step>
        <Step  label="Post Job">
          <SelectPlan nxtbtnclick={spnextbtnclick} backbtnclick={spbackbtnclick} jobid={job_id}/>
        </Step>
      </MultiStepForm>
    </div>
  )
}

export default PostJob