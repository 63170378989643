import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import { allIndustries_service, alljobrole_service, alljobtype_service, allperk_service, allworktype_service, company_profile_service, companyjobsbyid_service, getallcityservice, postjbdetail_service, postupdatejobdetail_service } from '../../ApiServices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CompanyJobDetail = (props) => {
  //fromStep,nbclick, setjobid, jobid
  let formStep=props.formStep;
  let nbclick=props.nbclick;
  let setjobid=props.setjobid;
  let jobid=props.jobid;


  const navigate = useNavigate();


  const [jobtitleoption, setjobtitleoption] = useState([]);
  const [industryoption, setindustryoption] = useState([]);
  const [jobtypeoption, setjobtypeoption] = useState([]);
  const [worktypeoption, setworktypeoption] = useState([]);
  const [jobcityoption, setjobcityoption] = useState([]);
  const [perksoption, setperksoption] = useState([]);
  const [companyname, setcompanyname] = useState('');

  const [formdata, setformdata] = useState({
    companyid: '',
    companyname: companyname,
    jobrole_name: "",
    industry_name: "",
    jobtype: "",
    isnightshift: false,
    worktype: "",
    officeaddress: "",
    city_name: "",
    minsalary: "",
    maxsalary: "",
    perks: "",
    isfee: false,
    feeamount: "",
    city_name:"",
  })

  const [errors, seterrors] = useState({});


  useEffect(() => {
    jobid=props.jobid;
    if (jobid != null) {
      companyjobsbyid_service(jobid).then((res) => {
        if (res.data.jobs[0].job_detail === 1) {
          setformdata({
            jobrole_name: res.data.jobs[0].jobrole_name,
            industry_name: res.data.jobs[0].industry_name,
            jobtype: { value: res.data.jobs[0].job_type.id, label: res.data.jobs[0].job_type.name },
            isnightshift: res.data.jobs[0].is_night,
            worktype: { value: res.data.jobs[0].work_type.id, label: res.data.jobs[0].work_type.name },
            city_name: res.data.jobs[0].city_name,
            officeaddress: res.data.jobs[0].office_address,
            minsalary: res.data.jobs[0].min_salary,
            maxsalary: res.data.jobs[0].max_salary,
            perks: res.data.jobs[0].perks,
            isfee: res.data.jobs[0].is_fees,
            feeamount: res.data.jobs[0].fee_amount,
            city_name:res.data.jobs[0].city_name,
          })
        }
      }).catch((error) => {

      })
    }
  }, [props.jobid,props.fromStep])


  useEffect(() => {
    // jobrole
    alljobrole_service().then((data) => {
      const mappedoptions = data.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setjobtitleoption(mappedoptions)
    }).catch((error) => { })


    // industry
    allIndustries_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setindustryoption(mappedoptions)
    }).catch((err) => { })

    // jobtype
    alljobtype_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setjobtypeoption(mappedoptions)
    }).catch((err) => { })

    // worktype
    allworktype_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setworktypeoption(mappedoptions);
    }).catch((err) => { })

    // jobcity
    getallcityservice().then((res) => {
      const mappedoptions = res.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setjobcityoption(mappedoptions);
    }).catch((err) => { })


    // perks
    allperk_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setperksoption(mappedoptions)
    }).catch((err) => { })


    getprofile()
  }, [])


  const getprofile = () => {
    const id = localStorage.getItem('company_id');
    if (id) {
      company_profile_service(id).then((data) => {
        setcompanyname(data.data.company.companyname)
        setformdata({
          ...formdata,
          companyid: data.data.company.id,
          companyname: data.data.company.companyname
        })
      }).catch((error) => {
        console.log(error);
      })
    } else {
      navigate('/login')
    }
  }


  const changeHandle = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    });

    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }))
  }


  const nightshifthandle = () => {
    setformdata({
      ...formdata,
      isnightshift: !formdata.isnightshift
    })
  }


  const changeSelecthandle = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    })

    seterrors((prevErrors) => ({
      ...prevErrors,
      [action.name]: ''
    }))

  }


  const validateform = () => {
    const newerrors = {}

    if(!formdata.jobrole_name){
      newerrors.jobrole_name="Job Title Required";
    }

    // if (!formdata.jobtitle) {
    //   newerrors.jobtitle = "Job Title is Required";
    // }

    // if (!formdata.industry) {
    //   newerrors.industry = "Industry is Required";
    // }
    if(!formdata.industry_name){
      newerrors.industry_name = "Industry Name Required";
    }

    if (!formdata.officeaddress) {
      newerrors.officeaddress = "Office Address Required";
    }

    // if (!formdata.jobcity) {
    //   newerrors.jobcity = "JobCity is Required";
    // }
    if(!formdata.city_name){
      newerrors.city_name="City Name Required";
    }

    if (!formdata.worktype) {
      newerrors.worktype = "Please Choose Location Type"
    }

    if (!formdata.minsalary) {
      newerrors.minsalary = "Please Enter Minimum Salary"
    }

    if (!formdata.maxsalary) {
      newerrors.maxsalary = "Please Enter Maximum Salary"
    }

    if (!formdata.isfee) {
      //alert("i m checking fee "+formdata.isfee);
      //newerrors.isfee = "Please Choose There Any Fee Required";
    }

    if (formdata.isfee == "true") {
      //alert("no in feee ");
      if (!formdata.feeamount) {
        newerrors.feeamount = "Please Enter Fees Amount"
      }
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const nextbtnclick = () => {
    
    if (validateform()) {
      //alert("i am in form validation "+props.jobid);
      if (jobid) {
        //alert("i m in 1");
        postupdatejobdetail_service(formdata, jobid).then((res) => {
          //alert(res.data.job_id);
          if (res.data.job_id) {
            //alert(res.data.job_id);
            nbclick();
          }
        }).catch((error) => {
          toast('❌' + error.message);
        })
      } else {
        //alert("i m in 2");
        postjbdetail_service(formdata).then((data) => {
          if (data.data.job_id) {
            navigate('/company/post-job/'+data.data.job_id)
          }
        }).catch((error) => {
          toast('❌' + error.message);
        })
      }
    }
  }





  return (
    <section style={{ padding: '10px 0px' }}>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="card">
            <h5 className='mt-3 mb-0'>Job Details</h5>
            <p>Fill Detail For Show Your Requirements</p>
            <div className="row mt-2">
              <div className="col-lg-12">
                <div class="form-group">
                  <label htmlFor="" className='label'>Company Name <span className='text-danger'>*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    value={companyname}
                    placeholder="company Name"
                  />
                  {errors.companyname && <span className='text-danger'>{errors.companyname}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-lg-6">
                <div class="form-group">
                  <label htmlFor="" className='label'>Job Title <span className='text-danger'>*</span> </label>
                  <input
                    type="text"
                    name='jobrole_name'
                    value={formdata.jobrole_name}
                    class="form-control"
                    placeholder="Marketing Manager / Software Developer / Sales Manager"
                    onChange={changeHandle}
                  />
                  {/* <ReactSelect value={formdata.jobtitle} name='jobtitle' options={jobtitleoption} placeholder="Job Title" onChange={changeSelecthandle} /> */}
                  {errors.jobrole_name && <span className='text-danger'>{errors.jobrole_name}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-lg-6">
                <div class="form-group">
                  <label htmlFor="" className='label'>Industry <span className='text-danger'>*</span></label>
                  <input
                    type="text"
                    name='industry_name'
                    value={formdata.industry_name}
                    class="form-control"
                    placeholder="Construction/Information Technology/Transportation"
                    onChange={changeHandle}
                  />
                  {/* <ReactSelect value={formdata.industry} name='industry' options={industryoption} placeholder="Industry" onChange={changeSelecthandle} /> */}

                  {errors.industry_name && <span className='text-danger'>{errors.industry_name}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-2">
              <div className="col-lg-6">
                <div class="form-group">
                  <label class="label">
                    Job Type
                  </label> <span className='text-danger'>*</span>
                  <ReactSelect value={formdata.jobtype} options={jobtypeoption} name='jobtype' onChange={changeSelecthandle} />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-6">
                <input type="checkbox" name='isnightshift' checked={formdata.isnightshift == true} value={true} onChange={nightshifthandle} /> Urgent Hiring
              </div>
            </div>
          </div>
          <div className="card mt-2">
            <h5 className='mt-3 mb-0'>Location</h5>
            {/* <p>FIll Location For Better Results</p> */}
            <div className="row mt-2 mb-2">
              <div className="col-lg-6">
                <div class="form-group">
                  <label for="first_name" class="label">
                   Work Location Type
                  </label> <span className='text-danger'>*</span>
                  <ReactSelect value={formdata.worktype} name='worktype' onChange={changeSelecthandle} options={worktypeoption} />
                  {errors.worktype && <span className='text-danger'>{errors.worktype}</span>}
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-lg-6">
                <div class="form-group">
                  <label for="first_name" class="label">
                    Job City
                  </label> <span className='text-danger'>*</span>
                  <input
                    type="text"
                    name='city_name'
                    value={formdata.city_name}
                    class="form-control"
                    placeholder="Indore/Mumbai/Delhi"
                    onChange={changeHandle}
                  />
                  {/* <ReactSelect value={formdata.jobcity} name='jobcity' onChange={changeSelecthandle} options={jobcityoption} placeholder="Job City" /> */}
                  {errors.city_name && <span className='text-danger'>{errors.city_name}</span>}
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-lg-12">
                <div class="form-group">
                  <label for="first_name" class="label">
                  Office address
                  </label> <span className='text-danger'>*</span>
                  <input
                    type="text"
                    name='officeaddress'
                    value={formdata.officeaddress}
                    class="form-control"
                    placeholder="Office Address"
                    onChange={changeHandle}
                  />
                  {errors.officeaddress && <span className='text-danger'>{errors.officeaddress}</span>}
                </div>
              </div>
            </div>


          </div>
          <div className="card mt-2">
            <h5 className='mt-3 mb-0'>Salary</h5>
            {/* <p>Fill details about job salary and incentives</p> */}

            <div className="row mt-2">
              <div className="col-lg-6">
                <div class="form-group">
                  <label for="first_name" class="label">
                    Minimum Salary <span className='text-danger'>*</span>
                  </label>
                  <input
                    type="text"
                    name='minsalary'
                    value={formdata.minsalary}
                    class="form-control"
                    onChange={changeHandle}
                    placeholder="Minimum Salary"
                  />
                  {errors.minsalary && <span className='text-danger'>{errors.minsalary}</span>}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-6">
                <div class="form-group">
                  <label for="first_name" class="label">
                    Maximum Salary <span className='text-danger'>*</span>
                  </label>
                  <input
                    type="text"
                    value={formdata.maxsalary}
                    class="form-control"
                    name='maxsalary'
                    onChange={changeHandle}
                    placeholder="Maximum Salary"
                  />
                  {errors.minsalary && <span className='text-danger'>{errors.minsalary}</span>}
                </div>
              </div>
            </div>


            <div className="row mt-2 mb-2">
              <div className="col-lg-12">
                <div class="form-group">
                  <label for="first_name" class="label">
                    Additional Benefits
                  </label>
                  <ReactSelect value={formdata.perks} isMulti options={perksoption} name='perks' onChange={changeSelecthandle} />
                  <div className='d-flex gap-2' style={{ flexWrap: 'wrap' }}>
                  </div>
                </div>
              </div>
            </div>
            <input
                        type="hidden"
                        value="false"
                        name="isfee"
                      />
            {/* <div className="row mt-2 mb-2">
              <div className="col-lg-6">
                <div class="form-group">
                  <label for="first_name" class="label">
                    Is there any joining fee or deposit required from the candidate
                  </label>
                  <div className='d-flex gap-3'>
                    <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                      <input
                        type="radio"
                        value="true"
                        name="isfee"
                        checked={formdata.isfee === 'true'}
                        onChange={changeHandle}
                      />
                      yes
                    </label>
                    <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                      <input
                        type="radio"
                        value="false"
                        name="isfee"
                        checked={formdata.isfee === 'false'}
                        onChange={changeHandle}
                      />
                      no
                    </label>
                  </div>
                  {errors.isfee && <span className='text-danger'>{errors.isfee}</span>}
                </div>
              </div>
            </div> */}

            {
              formdata.isfee == "true" ?
                <>
                  <div className="row mt-2 mb-4">
                    <div className="col-lg-6">
                      <div class="form-group">
                        <label for="first_name" class="label">
                          Fee Amount <span className='text-danger'>*</span>
                        </label>
                        <input
                          type="text"
                          name='feeamount'
                          value={formdata.feeamount}
                          class="form-control"
                          placeholder="Amount"
                          onChange={changeHandle}
                        />
                        {errors.feeamount && <span className='text-danger'>{errors.feeamount}</span>}
                      </div>
                    </div>
                  </div>
                </>
                :
                ''
            }

          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mt-3 text-center">
            <div onClick={nextbtnclick} className="btn btn-primary">Next</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyJobDetail