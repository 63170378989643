import React, { useEffect, useState } from 'react'
import AdminAddDegree from '../dialogue/AdminAddDegree';
import { alldegree_service, education_level_service } from '../../ApiServices';

const AdminDegree = () => {

  const [degree, setdegree] = useState([]);
  const [educationlevels, seteducationlevels] = useState({});

  useEffect(() => {
    getalldegrees();
    geteducationlevel();
  },[])

  const getalldegrees = () =>{
    alldegree_service().then((data)=>{
      console.log(data);
      setdegree(data.data)
    }).catch((error)=>{
      
    })
  }

  const geteducationlevel = () =>{
    education_level_service().then((data)=>{
      console.log(data);
      const mappedoptions = data.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      seteducationlevels(mappedoptions)
    }).catch((error)=>{

    })
  }

  return (
    <div className="container-fluid">
      <AdminAddDegree educationlevels={educationlevels} getdegrees={getalldegrees} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">Degree</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#adddegree" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Education Level</th>
                <th scope="col">Degree</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                degree.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.education_level.name}
                      </td>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#editdegree" class="btn btn-sm btn-neutral text-danger-hover">
                          Edit
                        </button>
                        {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button> */}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {/* <div class="card-footer border-0 py-5">
          <span class="text-muted text-sm">Showing 10 items out of 250 results found</span>
        </div> */}
      </div>
    </div>
  )
}

export default AdminDegree