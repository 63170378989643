import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';

//import './comp_resm_7.css' // only fo ref

export const Comp_Resm_7=(props)=>{
  const [resumeData,setResumeData]=useState({});
  const [defaultImg,setDefaultImg]=useState("");
  useEffect(()=>{
      if(typeof props.setProfile.candidate_master!=="undefined"){
          setResumeData(setResumeTemplateData(props.setProfile));
    
        }
    },[props.setProfile])

    useEffect(()=>{
        
      if(typeof resumeData.basic!=="undefined"){
          bindEdit();
          if(resumeData.basic.gender==="male"){
              setDefaultImg("/assets/resm_thumb/male_rems.jpg");
            }else{
              setDefaultImg("/assets/resm_thumb/female_rems.jpg");
            }
      }

},[resumeData])
    return(<>
    {typeof resumeData.basic!=="undefined"?
        <div class="resume container newejobeeresume02">
        <div class="header row align-items-center mb-2">
          <div class="col-md-3 my-2">
          {typeof resumeData.basic.profile_pic!=="undefined"?
          <img src={public_url+resumeData.basic.profile_pic} alt="Profile Picture" class="img-fluid resm_pic"/>:
          <img src={defaultImg} alt="Profile Picture" class="img-fluid resm_pic"/>}
            
          </div>
          <div class="col-md-9 information_div">
            <h1 class="h3 white">{resumeData.basic.first_name} {resumeData.basic.last_name}</h1>
            <h4 class="editable white" e_type="text" e_key="f" e_id="ft">Web Developer</h4>
            <p class="mb-0"> 
              <span><i class="fas fa-phone"></i>{'\u00A0'} {resumeData.contact.mobile} {'\u00A0'}|</span> 
              <span> <i class="far fa-envelope"></i>{'\u00A0'}  {resumeData.contact.email} {'\u00A0'}|</span> <br/> 
              <span><i class="fas fa-map-marker"></i>{'\u00A0'} {resumeData.contact.address}</span></p>
          </div>
        </div>
    
        <div class="section mb-2">
          <h2 class="section-title h4 editable" e_type="text" e_key="f" e_id="ft">Summary</h2>
          <p class="editable" e_type="textarea" e_key="f" e_id="ft">Experienced customer service representative with over 4 years in fast-paced environments. Skilled at building relationships, solving problems, and increasing sales. Passionate about customer satisfaction and team success.</p>
        </div>
       <div class="section">
            <h2 class="section-title h4">Experience</h2>
            {resumeData.experience.map((item,index)=>{
              return  <div class="work-item mb-3">
              <h5>{item["jobrole_name"]}</h5>
              <p><strong>{item["company_name"]}</strong> | {item["duration"]}</p>
            </div>
            })}
           
            
          </div>
    
        <div class="row mb-2">
          <div class="col-md-6">
            <h2 class="section-title h4 ">Education</h2>
            <ul class="list-unstyled">
            {resumeData.education.map((item,index)=>{
              return <li><strong>{item["degree"]}</strong> - {item["university"]}, {item["duration"]}</li> 
            })}
            </ul>
          </div>
          <div class="col-md-6">
            <h2 class="section-title h4 skills">Skills</h2>
            <p class="editable" e_type="text" e_key="f" e_id="ft"> {resumeData.skills.map((item,index)=>{
                return <>{index!==0?", "+item:item}</>
                })
               }</p>
          </div>
          
        </div>
    
       
    
        <div class="row mb-2">
          <div class="col-md-6 prefrence">
            <h2 class="section-title h4">Preference</h2>
            {resumeData.preference.map((item,index)=>{
                return <><p>Work Type: {item["work"]}</p>
                <p>Timing: {item["job_time"]}</p>
                <p>Job Shift: {item["job_shift"]}</p>
                <p>Pref. City: {item["city_name"]}</p></>
            })}
           
          </div>
          <div class="col-md-6">
            <h2 class="section-title h4 lang">Language Known</h2>
            <p e_type="textarea" e_key="f" e_id="ft" class="editable">{resumeData.language.map((item,index)=>{
                return <>{index!==0?", "+item:item}</>
             })}</p>
        </div>
        </div>
    
        <div class="row mt-4 signature">
            <div class="col-12">
              <h2 class="section-title h4">Signature</h2>
                <p>.......................................</p>
            </div>
        </div>
      </div>:''}
      </>
    );
}
export default Comp_Resm_7;