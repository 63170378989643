import React, { useRef } from 'react'
import { useState } from 'react';
import { update_basic_detail } from '../../ApiServices';
import { toast } from 'react-toastify';

const BasicDetails = ({firstname,lastname,email,gender,dob,getprofile}) => {
  
  const closedialogueRef = useRef();

  const canceldialogue = () => {
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const [formData, setformData] = useState({
    firstname:firstname,
    lastname:lastname,
    email:email,
    gender:gender,
    dob:dob
  })

  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const {name,value} = e.target
    setformData({
      ...formData,
      [name]:value
    })

    seterrors((preverror)=>({
      ...preverror,
      [name]:''
    }))
  }

  const validateform = () => {
    const newerrors = {};

    if (!formData.firstname) {
      newerrors.firstname = "First Name is Required"
    }

    if (!formData.lastname) {
      newerrors.lastname = "Last Name is Required"
    }

    if (!formData.email) {
      newerrors.email="Email Address is Required"
    }

    if (!formData.dob) {
      newerrors.dob = "Date of Birth is Required"
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length == 0;
  }

  const submit = () => {
    if (validateform()) {
      update_basic_detail(formData).then((res)=>{
        toast('✅ Basic Details Updated Successfully');
        canceldialogue();
        getprofile()
      }).catch((error)=>{

      })
    }
  }

  return (
    <div class="modal fade" id="editbasicdetail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit Basic Details
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">

              <input readonly={"true"} className='form-control mt-2' type="text" placeholder='FirstName' name='firstname' value={formData.firstname} onChange={handleChange} />
              {errors.firstname && <span className="text-danger">{errors.firstname}</span>}

              <input readonly={"true"} className='form-control mt-2' type="text" placeholder='lastName' name='lastname' value={formData.lastname} onChange={handleChange} />
              {errors.lastname && <span className="text-danger">{errors.lastname}</span>}

              <input readonly={"true"} type="text" placeholder='Email' className="form-control mt-2" name='email' value={formData.email} onChange={handleChange} />
              {errors.email && <span className="text-danger">{errors.email}</span>}


              <div className="col-lg-12 d-flex gap-4 align-items-center mt-2">
                <label htmlFor="">Gender</label>
                <div className='d-flex gap-3 align-items-center justify-content-center'>
                  <label className='d-flex gap-1 align-items-center justify-content-center'>
                    <input
                      type="radio"
                      value="male"
                      name="gender"
                      checked={formData.gender === 'male'}
                      onChange={handleChange}
                    />
                    Male
                  </label>
                  <label className='d-flex gap-1 align-items-center justify-content-center'>
                    <input
                      type="radio"
                      value="female"
                      name="gender"
                      checked={formData.gender === 'female'}
                      onChange={handleChange}
                    />
                    Female
                  </label>
                </div>
              </div>

              <input type="date" placeholder='Date Of Birth' className="form-control mt-3" name='dob' value={formData.dob} onChange={handleChange} />
              {errors.dob && <span className="text-danger">{errors.dob}</span>}


            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicDetails