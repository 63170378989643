import './comp_ejobee_footer.css';

export const Comp_Ejobee_Footer=(props)=>{
  return(
  <div class="custom-footer">
  <div class="container">
    {/* <div class="row">
      <div class="col text-center">
        <a href="#">Home</a>
        <a href="#">About Us</a>
        <a href="#">Jobs</a>
        <a href="#">FAQs</a>
        <a href="#">Privacy Policy</a>
        <a href="#">Terms & Conditions</a>
        <a href="#">Contact Us</a>
      </div>
    </div> */}
    <div class="row">
      <div class="col text-center">
        <p> 
                            &copy; {new Date().getFullYear()} Ejobee. All rights reserved.
                      </p>
      </div>
    </div>
  </div>
</div>
);
}
export default Comp_Ejobee_Footer;