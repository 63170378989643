import React from 'react'
import Navbar from '../company/component/Navbar'
import { Outlet } from 'react-router-dom'
import Sidebar from '../company/component/Sidebar'
import Footer from '../web/component/Footer';
import Comp_Ejobee_Footer from '../web/component/comp_ejobee_footer';
const CompanyLayout = () => {
  return (
    <div>
      <Navbar />
      <div>
        <Sidebar />
        <Outlet />
      </div>
      <Footer />
      <Comp_Ejobee_Footer/>
    </div>
  )
}

export default CompanyLayout