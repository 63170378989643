import React from 'react'
import { Link } from 'react-router-dom';
import './comp_candidate_payments.css';


export const Comp_Company_Payments_New=(props)=>{
    return <div class="row ps-4 border-box">
    <div class="col-md-12 invoiceim pb-2">
           <div class="img-container float-left d-flex">
            <span><i class="fa fa-shopping-cart fa-2x pr-2" style={{"padding-top":"50%"}} aria-hidden="true"></i></span>
                <span class="text-success-2 pt-2">Paid for :{props.listTransaction.jobrole_name} <br></br>
                Date-time {props.listTransaction.updated_at}
                </span>
            </div>
            {/* <a  href="javascript:void(0)" class="float-right btn-sm btn-lg btn-primary_two btn-hover-dark mt-5">Repeat Order</a> */}
    </div>

    <div class="col-md-12 ps-4">
        <div class="row">
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Payment of</h6>
           <span className="pt-2">
            {"Job Listing"}
           </span>
        </div>
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Order Number</h6>
           <span className="pt-2">{props.listTransaction.order_id}</span>
        </div>
        
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Amount</h6>
            <span className="pt-2">₹ {props.listTransaction.amount}</span>
        </div>
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Payment Status</h6>
           <span className="pt-2">{props.listTransaction.status}</span>
        </div>
        <div className="col-md-4 d-flex-mob">
        {/* <a href={"../company/payment_receipt?rf="+btoa(props.listTransaction.order_id)} class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">Track</a> */}
    {/* <a  data-toggle="modal" data-target="#divTrackOrder" href="javascript:void(0)" class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">Track</a> */}
    <a data-bs-toggle="modal" href={"javascript:void(0)"} onClick={()=>props.funViewReceipt(props.listTransaction)} data-bs-target="#popViewPaymentReceipt" class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">View Receipt</a>
    </div> 
    </div>
   </div>
</div>
}


export const Comp_Candidate_Payments_New=(props)=>{
    return <div class="row ps-4 border-box">
    <div class="col-md-12 invoiceim pb-2">
           <div class="img-container float-left d-flex">
            <span><i class="fa fa-shopping-cart fa-2x pr-2" style={{"padding-top":"50%"}} aria-hidden="true"></i></span>
                <span class="text-success pt-2">Paid for :{"Membership"} <br></br>
                Date-time {props.listTransaction.updated_at}
                </span>
            </div>
            {/* <a  href="javascript:void(0)" class="float-right btn-sm btn-lg btn-primary_two btn-hover-dark mt-5">Repeat Order</a> */}
    </div>

    <div class="col-md-12 ps-4">
        <div class="row">
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Payment of</h6>
           <span className="pt-2">
            {"Premimum Member"}
           </span>
        </div>
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Order Number</h6>
           <span className="pt-2">{props.listTransaction.order_id}</span>
        </div>
        
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">MRP</h6>
            <span className="pt-2">₹ {props.listTransaction.amount}</span>
        </div>
        <div class="col-md-2 d-flex-mob">
            <h6 className="pt-2">Payment Status</h6>
           <span className="pt-2">{props.listTransaction.status}</span>
        </div>
        <div className="col-md-4 d-flex-mob">
    {/* <a  data-toggle="modal" data-target="#divTrackOrder" href="javascript:void(0)" class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">Track</a> */}
    <a data-bs-toggle="modal" href={"javascript:void(0)"} onClick={()=>props.funViewReceipt(props.listTransaction)} data-bs-target="#popViewPaymentReceipt" class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">View Receipt</a>
    </div> 
    </div>
   </div>
</div>
}


export const Comp_Company_Payments=(props)=>{
    return (
        <Link to={"javascript:void(0)"} className="col-lg-12 col-sm-12">
            <div className="card p-3 d-flex flex-column gap-1">
                <div className="info d-flex align-items-center justify-content-between">
                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                        <div className="com-profile">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        </div>
                        <div>
                            <p className='fw-bold mb-0'>{props.listTransaction.updated_at}</p>
                            <p className='mb-0'>{props.listTransaction.companyname}</p>
                        </div>
                    </div>
                    <div className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000"><path d="M8.355 6.316a.985.985 0 0 0 0 1.515l4.714 4.17-4.714 4.17a.985.985 0 0 0 0 1.515c.474.419 1.24.419 1.713 0l5.577-4.933a.985.985 0 0 0 0-1.515l-5.577-4.933c-.461-.408-1.239-.408-1.713.01Z"></path></svg>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-1'>
                <p className='mb-0 col1'><i class="fa fa-suitcase" aria-hidden="true"></i> Job Posted: {props.listTransaction.jobrole_name}</p>
                </div>
                <div className='d-flex align-items-center gap-1'>
                <p className='mb-0 col1'>Paid Amount : <i class="fa fa-inr" aria-hidden="true"></i> {props.listTransaction.amount}</p>
                </div>
                {
                    props.listTransaction.status ? <div style={{ fontSize: '13px', padding: '0px 5px',fontWeight:'bold',margin:'10px 0px'}}>
                        Payment Status : {props.listTransaction.status}
                    </div> : ''
                }
                {/* <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p className='mb-0'>{"address"}</p>
                </div> */}
               
              
               
                <div className="d-flex gap-2">
                    {/* {
                        props.savedJobs.work_types ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {props.savedJobs.work_types}
                        </div> : ''
                    }
                    {
                        props.savedJobs.jobtype_name ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {props.savedJobs.jobtype_name}
                        </div> : ''
                    } */}
                </div>
            </div>

        </Link>
    )
}



export const Comp_Candidate_Payments = (props) => {
    //alert(JSON.stringify(props.savedJobs))
    return (
        <Link to={"javascript:void(0)"} className="col-lg-12 col-sm-12">
            <div className="card p-3 d-flex flex-column gap-1">
                <div className="info d-flex align-items-center justify-content-between">
                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                        <div className="com-profile">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        </div>
                        <div>
                            <p className='fw-bold mb-0'>{props.listTransaction.updated_at}</p>
                            <p className='mb-0'>{props.listTransaction.firstname} {props.listTransaction.lastname}</p>
                        </div>
                    </div>
                    <div className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000"><path d="M8.355 6.316a.985.985 0 0 0 0 1.515l4.714 4.17-4.714 4.17a.985.985 0 0 0 0 1.515c.474.419 1.24.419 1.713 0l5.577-4.933a.985.985 0 0 0 0-1.515l-5.577-4.933c-.461-.408-1.239-.408-1.713.01Z"></path></svg>
                    </div>
                </div>
                {/* <div className='d-flex align-items-center gap-1'>
                <p className='mb-0 col1'><i class="fa fa-book" aria-hidden="true"></i> {"edule"}</p>
                <p className='mb-0 col1'>| <i class="fa fa-suitcase" aria-hidden="true"></i> {"datedd"}</p>
                </div> */}
                <div className='d-flex align-items-center gap-1'>
                <p className='mb-0 col1'>Paid Amount : <i class="fa fa-inr" aria-hidden="true"></i> {props.listTransaction.amount}</p>
                </div>
                {
                    props.listTransaction.status ? <div style={{ fontSize: '13px', padding: '0px 5px',fontWeight:'bold',margin:'10px 0px'}}>
                        Payment Status : {props.listTransaction.status}
                    </div> : ''
                }
                {/* <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p className='mb-0'>{"address"}</p>
                </div> */}
               
              
               
                <div className="d-flex gap-2">
                    {/* {
                        props.savedJobs.work_types ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {props.savedJobs.work_types}
                        </div> : ''
                    }
                    {
                        props.savedJobs.jobtype_name ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {props.savedJobs.jobtype_name}
                        </div> : ''
                    } */}
                </div>
            </div>

        </Link>
    )
}

export default Comp_Candidate_Payments