import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import ReactSelect from 'react-select';
import { education_level_service, update_education_level } from '../../ApiServices';
import { compnay_complete_profile_service, getcityservice, getcountryservice, getstateservice } from '../../ApiServices';
import { toast } from 'react-toastify';

import {getCondidatesView} from '../../ApiServices';

export const Comp_Create_Company_Admin = (props) => {

    const navigate = useNavigate();

    const [errors, seterrors] = useState({});

    const [countries, setcountries] = useState([]);
    const [states, setstates] = useState([]);
    const [cities, setcities] = useState([]);

    const [formData, setformData] = useState({
        companyname: "",
        numberemployee: "",
        firstname: "",
        lastname: "",
        email: "",
        gender: "male",
        country: "",
        state: "",
        city_name: "",
        freejobpost:"" 
    });


    const employeework = [
        { value: "0-10", label: "0-10" },
        { value: "10-20", label: "10-20" },
        { value: "20-50", label: "20-50" },
        { value: "50-100", label: "50-100" },
        { value: "100-150", label: "100-150" },
        { value: "150-200", label: "150-200" },
        { value: "200-250", label: "200-250" },
        { value: "250+", label: "250+" },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target;
        seterrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setformData({
            ...formData,
            [name]: value
        })
    }

    const handleSelectChange = (data, action) => {
        seterrors((prevErrors) => ({
            ...prevErrors,
            [action.name]: '',
        }));
        setformData({
            ...formData,
            [action.name]: data
        })
        if (action.name == "country") {
            getstate(data.value);
        }

        if (action.name == "state") {
            //getcities(data.value);
        }
    }
    const validateform = () => {
        const newErrors = {};

        if (!formData.companyname) {
            newErrors.companyname = "Company Name Required";
        }

        if (!formData.numberemployee) {
            newErrors.numberemployee = "Number of Employee Required";
        }

        if (!formData.firstname) {
            newErrors.firstname = "Firstname Required";
        }

        if (!formData.lastname) {
            newErrors.lastname = "Lastname Required";
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is Not Valid';
        }

        if (!formData.country) {
            newErrors.country = 'Country Required';
        }

        if (!formData.state) {
            newErrors.state = 'State Required';
        }

        if (!formData.city_name) {
            newErrors.city_name = 'City Required';
        }
        if(!formData.freejobpost){
            newErrors.freejobpost='No of free post required';
        }

        seterrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    }

    const getcountry = () => {
        getcountryservice().then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setcountries(mappedoptions)
        }).catch((error) => {
            getcountry()
        })
    }

    const getstate = (countryid) => {
        getstateservice(countryid).then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setstates(mappedoptions)
        }).catch((error) => {
            getstate();
        })
    }

    useEffect(() => {
        getcountry();
      //alert(JSON.stringify(props.companyItem));
    }, [])


    
    const continuebtnclick = () => {
        if (validateform()) {
            getCondidatesView(formData,"addCompanyFrmAdmin").then((data) => {
                //alert(JSON.stringify(data));
                if (data.data==="001") {
                    alert("Company created successfully");
                    window.location.reload();
                }else{
                    alert(data.msg);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }


    return (
        <div class="modal fade" id="divCompanyCreateAdmin"  data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl11 modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-primary white">
                        Create New Company
                        <button type="button" class="btn-close white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div className="login-box d-flex flex-column justify-content-center h-100">
                            <h2>Complete Your Profile</h2>
                            <p>Provide Personal and Company Details to Enhance Your Profile on Our Platform</p>
                            <div className="login-form mt-2">
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <input className='form-control' placeholder="Company Name" name="companyname" onChange={handleChange} />
                                        {errors.companyname && <span className="text-danger">{errors.companyname}</span>}
                                    </div>
                                </div>
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <input className='form-control' placeholder="Contact Number" name="mobile" onChange={handleChange} />
                                        {errors.companyname && <span className="text-danger">{errors.companyname}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="No. Of Employe in Company"
                                            isSearchable={false}
                                            name="numberemployee"
                                            onChange={handleSelectChange}
                                            options={employeework}
                                        />
                                        {errors.numberemployee && <span className="text-danger">{errors.numberemployee}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-6">
                                        <input className='form-control' type="text" placeholder='FirstName' name='firstname' onChange={handleChange} value={formData.firstname} />
                                        {errors.firstname && <span className="text-danger">{errors.firstname}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <input className='form-control' type="text" placeholder='lastName' name='lastname' onChange={handleChange} value={formData.lastname} />
                                        {errors.lastname && <span className="text-danger">{errors.lastname}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <input type="text" placeholder='Email' className="form-control" name='email' onChange={handleChange} value={formData.email} />
                                        {errors.email && <span className="text-danger">{errors.email}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2 mb-2">
                                    <div className="col-lg-12 d-flex gap-4 align-items-center">
                                        <label htmlFor="">Gender</label>
                                        <div className='d-flex gap-3 align-items-center justify-content-center'>
                                            <label className='d-flex gap-1 align-items-center justify-content-center'>
                                                <input
                                                    type="radio"
                                                    value="male"
                                                    name="gender"
                                                    checked={formData.gender === 'male'}
                                                    onChange={handleChange}
                                                />
                                                Male
                                            </label>
                                            <label className='d-flex gap-1 align-items-center justify-content-center'>
                                                <input
                                                    type="radio"
                                                    value="female"
                                                    name="gender"
                                                    checked={formData.gender === 'female'}
                                                    onChange={handleChange}
                                                />
                                                Female
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select Country"
                                            isSearchable={true}
                                            name="country"
                                            onChange={handleSelectChange}
                                            options={countries}
                                        />
                                        {errors.country && <span className="text-danger">{errors.country}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select State"
                                            isSearchable={true}
                                            name="state"
                                            onChange={handleSelectChange}
                                            options={states}
                                        />
                                        {errors.state && <span className="text-danger">{errors.state}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        {/* <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select City"
                                            isSearchable={true}
                                            name="city"
                                            onChange={handleSelectChange}
                                            options={cities}
                                        /> */}
                                        <input className='form-control' placeholder="City" name="city_name" onChange={handleChange} />
                                        {errors.city_name && <span className="text-danger">{errors.city_name}</span>}
                                    </div>
                                </div>

                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        {/* <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select City"
                                            isSearchable={true}
                                            name="city"
                                            onChange={handleSelectChange}
                                            options={cities}
                                        /> */}
                                        <input type={"number"} className='form-control' placeholder="No of free job post" name="freejobpost" onChange={handleChange} />
                                        {errors.freejobpost && <span className="text-danger">{errors.freejobpost}</span>}
                                    </div>
                                </div>


                                <div onClick={continuebtnclick} className='btn btn-primary mt-3 w-100'>Continue</div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Comp_Create_Company_Admin