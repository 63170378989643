import React, { useEffect, useState } from 'react'
import { Link,useNavigate,useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getCondidatesView } from '../../ApiServices'
import InfiniteScroll from 'react-infinite-scroll-component'
import { public_url } from '../../ApiUrl'
import {Comp_List_Resume,Comp_List_Resume_ShowCase} from '../component/Comp_List_Resume';
import {CandidateOption} from './CandidateOption';


const Resume_Maker = () => {
    const location = useLocation();
   
    useEffect(() => {
        //fetchProfileRecords();
    }, [])

   

    return (
        <>
            <Helmet>
                <title>Resume - Make a resume | EJOBEE</title>
                <meta name="description" content="Explore the eJobee blog for valuable insights, tips, and career advice. Stay updated with the latest trends in the job market and enhance your career growth." />
            </Helmet>
            <section>
            <div className="container">
               <CandidateOption pathname={location.pathname} />
            </div>
                <div className="container resume_showcase">
                    <div className="section-title mb-5">
                        <h4>Make Resumes</h4>
                    </div>
                   
                        <div className="row" style={{ rowGap: '50px' }}>
                            {
                                <Comp_List_Resume_ShowCase lsSample={[
                                    {item_index:"1",item_img:"01-resume.jpg",item_desc:"Free User"},
                                    {item_index:"2",item_img:"02-resume.jpeg",item_desc:"Free User"},
                                    {item_index:"3",item_img:"03-resume.jpg",item_desc:"Free User"},
                                    {item_index:"4",item_img:"06_resume_new.png",item_desc:"Premium User"},
                                    {item_index:"5",item_img:"05-resume.jpg",item_desc:"Premium User"},
                                    {item_index:"6",item_img:"06-resume.jpg",item_desc:"Premium User"},
                                    {item_index:"7",item_img:"07-resmue.png",item_desc:"Premium User"},
                                   // {item_index:"8",item_img:"07-resmue.png",item_desc:"Premium User"},
                                ]}/>
                            //    <Comp_List_Resume lsSample={["1","2","3","4","5","6"]}/>
                            }
                        </div>
                    
                </div>
            </section>
        </>
    )
}

export default Resume_Maker