import React, { useRef, useState } from 'react'
import { addcountryservice } from '../../ApiServices'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

const AdminAddCountry = ({ getcountry }) => {

  const closedialogueRef = useRef();

  const navigate = useNavigate();

  const [formData, setformData] = useState({
    code: '',
    name: ''
  })

  const [errors, seterrors] = useState({})

  const validateform = () => {
    const newerrors = {}

    if (!formData.code) {
      newerrors.code = "Code is Required";
    }

    if (!formData.name) {
      newerrors.name = "Name is Required";
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;

  }

  const HandleChange = (e) => {
    const { name, value } = e.target
    setformData({
      ...formData,
      [name]: value
    })

    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const canceldialogue = () => {
    setformData({
      code: '',
      name: ''
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      addcountryservice(formData).then((data) => {
        toast('✅ Country Added Successfully');
        canceldialogue()
        getcountry();
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }

  return (
    <div class="modal fade" id="addcountry" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Country
            <button ref={closedialogueRef} onClick={canceldialogue} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <input type="text" className="form-control" name='code' placeholder='country Code' onChange={HandleChange} />
              {errors.code && <span className="text-danger">{errors.code}</span>}
              <input type="text" className="form-control mt-2" placeholder='country Name' name='name' onChange={HandleChange} />
              {errors.name && <span className="text-danger">{errors.name}</span>}
            </div>
          </div>
          <div class="modal-footer">
            <button onClick={submit} type="button" class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAddCountry