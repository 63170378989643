import './comp_receipt_ejobee.css';

export const Comp_Receipt_Ejobee=(props)=>{
  return(
    <div class="modal fade" id="popViewPaymentReceipt" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header recieptheader">
                  <img src="/assets/logo/logo.png" alt="Company Logo"/>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
               <div class="modal-body">
                  <div class="invoice">
                      <div class="invoice-header row ">
                          <div class="col-12 success-icon">
                            {props.getReceipt.status==="Success"?<i class="fa fa-check-circle fa-2x"></i>:<i class="fa fa-times fa-2x" aria-hidden="true"></i>}
                              <h4 class="text-success pt-3">Payment Status :{props.getReceipt.status}</h4>
                          </div>
                      </div>
                      </div>
                      {props.getReceipt.user_type==="1"?
                      <div class="content-div-item row">
                        <div class="left  col-6"><strong>Order No</strong>.</div>
                        <div class="right col-6">{props.getReceipt.order_id}</div>
                        <div class="left  col-6"><strong>Payment Date</strong></div>
                        <div class="right col-6">{props.getReceipt.created_at}</div>

                          <div class="left  col-6"><strong>Candidate Name</strong></div>
                          <div class="right col-6">{props.getReceipt.firstname} {props.getReceipt.lastname}</div>
                          <div class="left  col-6"><strong>Payment Method</strong></div>
                          <div class="right col-6">-</div>
                          {/* <div class="left  col-6"><strong>JOB POST TITLE</strong></div>
                          <div class="right col-6">NURSE</div> */}
                          
                          <div class="left  col-6"><strong>Amount</strong></div>
                          <div class="right col-6">{props.getReceipt.amount}</div>
                      </div>:''}

                      {props.getReceipt.user_type==="2"?
                        <div class="content-div-item row">
                        <div class="left  col-6"><strong>Order No</strong>.</div>
                        <div class="right col-6">{props.getReceipt.order_id}</div>
                        <div class="left  col-6"><strong>Payment Date</strong></div>
                        <div class="right col-6">{props.getReceipt.created_at}</div>

                          <div class="left  col-6"><strong>Company Name</strong></div>
                          <div class="right col-6">{props.getReceipt.companyname} </div>
                          <div class="left  col-6"><strong>Payment Method</strong></div>
                          <div class="right col-6">-</div>
                          <div class="left  col-6"><strong>Job Post Title</strong></div>
                          <div class="right col-6">{props.getReceipt.jobrole_name}</div>
                          
                          <div class="left  col-6"><strong>Amount</strong></div>
                          <div class="right col-6">{props.getReceipt.amount}</div>
                      </div>:''
                      }

                      <div class="row footnote">
                          <div class="col-12 pt-5">
                          <p>
                                  {/* <strong>Address</strong> C-21 Ress com, Nanakheda bus stand Ujjain <br/> */}
                                   <strong>Billing</strong> billing@ejobee.com
                                  </p>
                                  <p></p>
                          </div>
                       </div>
                      <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      {/* <button type="button" class="btn btn-primary">Download PDF</button> */}
                      </div>
                      </div>
                  </div>
           </div>
  
          </div>
          )
}

export const Comp_Receipt_Ejobee_old=(props)=>{
    return(

        <>
        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Reciept</button> */}
  
  <div class="modal fade" id="popViewPaymentReceipt11" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Payment Status:  {props.getReceipt.status}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
{props.getReceipt.user_type==="2"?
          <div class="row">
            <div class="col-12 checkmark"><i class="fa-solid fa-circle-check fa-2xl" ></i></div>
            <div class="col-12 amountdiv">Receipt Amount {props.getReceipt.amount}</div>
            <div class="col-6 left">Refrence No.</div>
            <div class="col-6 right">{props.getReceipt.order_id}</div>
            <div class="col-6 left">Payment Time</div>
            <div class="col-6 right">{props.getReceipt.created_at}</div>
            <div class="col-6 left">Payment Method</div>
            <div class="col-6 right">-</div>
            <div class="col-6 left">Company Name</div>
            <div class="col-6 right">{props.getReceipt.companyname}</div>
            <div class="col-6 left">Job Post Title</div>
            <div class="col-6 right">{props.getReceipt.jobrole_name}</div>
            <div class="col-6 left">AMOUNT</div>
            <div class="col-6 right">{props.getReceipt.amount}</div>
          </div>:
          ''
          }

          {/* for candidate view */}
          {props.getReceipt.user_type==="1"?
          <div class="row">
            <div class="col-12 checkmark"><i class="fa-solid fa-circle-check fa-2xl" ></i></div>
            <div class="col-12 amountdiv">Receipt Amount {props.getReceipt.amount}</div>
            <div class="col-6 left">Refrence No.</div>
            <div class="col-6 right">{props.getReceipt.order_id}</div>
            <div class="col-6 left">Payment Time</div>
            <div class="col-6 right">{props.getReceipt.created_at}</div>
            <div class="col-6 left">Payment Method</div>
            <div class="col-6 right">-</div>
            <div class="col-6 left">Candidate Name</div>
            <div class="col-6 right">{props.getReceipt.firstname} {props.getReceipt.lastname}</div>
            {/* <div class="col-6 left">Job Post Title</div>
            <div class="col-6 right">{props.getReceipt.jobrole_name}</div> */}
            <div class="col-6 left">AMOUNT</div>
            <div class="col-6 right">{props.getReceipt.amount}</div>
          </div>:
          ''
          }

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          {/* <button type="button" class="btn btn-primary">Download Pdf</button> */}
        </div>
      </div>
    </div>
  </div>
  </>
    );
}
export default Comp_Receipt_Ejobee;