import React, { useRef, useState } from 'react'
import ReactSelect from 'react-select'
import { edit_total_experience_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import {ExperienceYearList} from '../../functional_lib/common_lib';

const EditTotalExperience = ({totalexp,getprofile}) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    total_experience:{ value:totalexp, label: totalexp },
  });
  
  const [errors, seterrors] = useState({})

  const HandleSelectChange = (value,action) =>{
    setformdata({
      ...formdata,
      [action.name]:value
    })
  }

  const validateform = () =>{
    const newerrors = {}

    if (!formdata.total_experience) {
      newerrors.total_experience = "Please Select Year of Experience"
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const canceldialogue = () =>{
    seterrors({})
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () =>{
    if (validateform()) {
      edit_total_experience_service(formdata).then((data)=>{
        getprofile();
        canceldialogue();
        toast('✅ Total Experience Updated')
      }).catch((error)=>{

      })
    }
  }

  const totaloption = [
    { value: "less Than 1 year", label: "less Than 1 year" },
    { value: "1+ year", label: "1+ year" },
    { value: "2+ year", label: "2+ year" },
    { value: "3+ year", label: "3+ year" },
    { value: "4+ year", label: "4+ year" },
    { value: "5+ year", label: "5+ year" },
    { value: "6+ year", label: "6+ year" },
]

  return (
    <div class="modal fade" id="edittotalexperience" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Edit Total Experience
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <ReactSelect placeholder="Select Total Experience" value={formdata.total_experience} options={totaloption} onChange={HandleSelectChange} name='total_experience' />
                            {errors.total_experience && <span className='text-danger'>{errors.total_experience}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default EditTotalExperience