import React, { useEffect, useState } from 'react'
import { Link,useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AddWorkExperience from '../dialogue/AddWorkExperience';
import EditWorkExperience from '../dialogue/EditWorkExperience';
import AddEducation from '../dialogue/AddEducation';
import EditEducation from '../dialogue/EditEducation';
import AddSkills from '../dialogue/AddSkills';
import LanguageKnown from '../dialogue/LanguageKnown';
import BasicDetails from '../dialogue/BasicDetails';
import IntroVideo from '../dialogue/IntroVideo';
import Locations from '../dialogue/Locations';
import JobPreferences from '../dialogue/JobPreferences';
import PreferenceJob from '../dialogue/PreferenceJob';
import EditCurrentSalary from '../dialogue/EditCurrentSalary';
import EditTotalExperience from '../dialogue/EditTotalExperience';
import Resume from '../dialogue/Resume';
import { toast } from 'react-toastify';
import { candidateprofile_service, upload_profilepic_service,getCondidatesView } from '../../ApiServices';
import { public_url,ash_bublic_url } from '../../ApiUrl';
import EditEducationLevel from '../dialogue/EditEducationLevel';
import EditEnglishLevel from '../dialogue/EditEnglishLevel';
import {CandidateOption} from './CandidateOption';
import Comp_Dashboard_Widgets from '../component/comp_dashboard_widgets';

const CandidateDashboard = () => {

    const location = useLocation();
   

    const options = { month: 'short', year: 'numeric' };
    const formattedDate = (date) => {
        const newdate = new Date(date)
        const formatdate = newdate.toLocaleString('en-US', options);
        return formatdate
    }

    const [candidateprofile, setcandidateprofile] = useState({});
    const [getCandidateDashboard,setCandidateDashboard]=useState([]);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('candidate_id')) {
            let map={};
            map["candidate_id"]=btoa(localStorage.getItem('candidate_id'));
            getCondidatesView(map,"getCandidateOutStandingView").then((res)=>{
                //alert(JSON.stringify(res));
                setCandidateDashboard(JSON.parse(res.data));
            }).catch((error)=>{
    
            })

            getprofile();
        }
    }, [])

    const getprofile = () => {
        candidateprofile_service().then((data) => {
            //alert(JSON.stringify(data));
            setcandidateprofile(data.data)
        }).catch((error) => { }).finally(() => {
            setloading(false)
        })
    }


    const changeFileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (max size: 5MB)
            if (file.size > 5 * 1024 * 1024) {
                toast('❌ File size exceeds the maximum limit (5MB)');
            } else {
                upload_profilepic_service(file).then((data) => {
                    toast('✅ profile pic Updated');
                    getprofile()
                }).catch((error) => { })
            }
        }
    }

    const [editexpid, seteditexpid] = useState(null);
    const [editeduid, setediteduid] = useState(null)

    return (
        <>
            <div className="container">
               <CandidateOption pathname={location.pathname} />
               <div className="section-tittle row justify-content-between">
                        <div style={{ width: 'fit-content' }}>
                            <h2>Welcome {typeof getCandidateDashboard.candidates!=="undefined"?getCandidateDashboard.candidates[0]["firstname"]+" "+getCandidateDashboard.candidates[0]["lastname"]:''} </h2>
                        </div>
                       
                    </div>
               <Comp_Dashboard_Widgets candidate_dashboard={getCandidateDashboard}/>
            </div>
            
           
              
               
            
        </>
    )
}

export default CandidateDashboard