import React, { useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { addjobrole_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddjobrole = ({industries,getjobroles}) => {

    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        name:'',
        industry:''
    })

    const [errors, seterrors] = useState({});

    const HandleChange = (e) =>{
        const {name,value} = e.target;
        setformdata({
            ...formdata,
            [name]:value
        })

        seterrors((preverror)=>({
            ...preverror,
            [name]:''
        }))
    }

    const HandleSelectChange = (value,action) =>{
        setformdata({
            ...formdata,
            [action.name]:value
        })

        seterrors((preverror)=>({
            ...preverror,
            [action.name]:''
        }))
    }

    const canceldialogue = () =>{
        setformdata({
            industry: '',
            name: '',
        })
        seterrors({});
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const validatefom = () =>{
        const newerror = {};

        if (!formdata.name) {
            newerror.name = "Job role is Required";
        }

        if (!formdata.industry) {
            newerror.industry = "Please Select Industry"
        }

        seterrors(newerror);
        return Object.keys(newerror).length === 0;
    }

    const submit = () =>{
        if (validatefom()) {
            addjobrole_service(formdata).then((data)=>{
                toast('✅ Job Roles Added Successfully');
                canceldialogue()
                getjobroles();
            }).catch((error)=>{})
        }
    }

  return (
    <div class="modal fade" id="addJobRole" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Add Job Role
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <ReactSelect placeholder="Select Indutry" value={formdata.industry} options={industries} onChange={HandleSelectChange} name='industry' />
                            {errors.industry && <span className='text-danger'>{errors.industry}</span>}

                            <input type="text" className="form-control mt-2" placeholder='job Role' onChange={HandleChange} value={formdata.name} name='name' />
                            {errors.name && <span className='text-danger'>{errors.name}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AdminAddjobrole