import React, { useEffect, useState } from 'react'
import AdminAddLanguages from '../dialogue/AdminAddLanguages'
import AdminEditLanguages from '../dialogue/AdminEditLanguages'
import { alllanguageservice } from '../../ApiServices';

const AdminLanguages = () => {

  const [languages, setlanguages] = useState([]);
  const [languageid, setlanguageid] = useState(null);

  useEffect(() => {
    getalllanguages();
  }, [])


  const getalllanguages = () => {
    alllanguageservice().then((data) => {
      setlanguages(data.data)
    }).catch((error) => {

    })
  };


  return (
    <div className="container-fluid">
      {/* languages */}
      <AdminAddLanguages getlangauge={getalllanguages} />
      <AdminEditLanguages langid={languageid} getlanguage={getalllanguages} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">Language</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addlanguages" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">language Name</th>
                <th scope="col">language code</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                languages.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.name}
                      </td>
                      <td>
                        {v.code}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" onClick={()=>setlanguageid(v.id)} data-bs-target="#editlanguages" class="btn btn-sm btn-neutral text-danger-hover">
                          Edit
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminLanguages