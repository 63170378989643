import React, { useRef, useState } from 'react'
import { edit_current_salary_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const EditCurrentSalary = ({currentsalary,getprofile}) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    currentsalary:currentsalary
  })

  const [errors, seterrors] = useState({});

  const HandleChange = (e) =>{
    const {name,value} = e.target;
    setformdata({
      ...formdata,
      [name]:value
    })
    seterrors({})
  }

  const canceldialogue = () =>{
    seterrors({})
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const validateform = () =>{
    const newerrors = {}

    if (!formdata.currentsalary) {
      newerrors.currentsalary = "Please Enter Current Salary"
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const submitform = () =>{
    if (validateform()) {
      edit_current_salary_service(formdata).then((data)=>{
        getprofile();
        canceldialogue();
        toast('✅ Current Salary Updated')
      }).catch((error)=>{

      })
    }
  }

  return (
    <div class="modal fade" id="editcurrentsalary" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius:'0px'}}>
                    <div class="modal-header">
                        Edit Current monthly Salary
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <input className='form-control' placeholder="Current Monthly Salary" value={formdata.currentsalary} onChange={HandleChange} name='currentsalary' />
                            {errors.currentsalary && <span className='text-danger'>{errors.currentsalary}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submitform} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default EditCurrentSalary