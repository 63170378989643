import React, { useEffect, useState } from 'react'
import { Link,useLocation } from 'react-router-dom'
import { applicationlist_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import JobCard from '../component/JobCard';
import Comp_JobCard from '../component/Comp_JobCard';
import {getCondidatesView} from '../../ApiServices';
import CandidateOption from './CandidateOption';

const ApplyJobs = () => {
    const location = useLocation();

    const [loading, setloading] = useState(true);
    const [appliedjobdata, setappliedjobdata] = useState([]);


    useEffect(() => {
        getappliedjobs();
    }, [])

    const getappliedjobs = () => {
        let map={ser_candidate_id:btoa(localStorage.getItem('candidate_id'))};
        getCondidatesView(map,'getCandidateAppliedJobs').then((res) => {
            const newCandidates = JSON.parse(res.data).applied_jobs;
            setappliedjobdata(newCandidates)
        }).catch((error) => {
            toast('❌' + error.message);
        }).finally(() => {
            setloading(false);
        })
    }


    return (
        <>
            <div className="container">
            <CandidateOption pathname={location.pathname} />
            </div>

            {
                loading ? <section>
                    <div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div class="loading-loader"></div>
                    </div>
                </section> : <section className='main-can-profile' style={{ padding: '10px 0px 50px 0px' }}>
                    <div className="container">
                        {
                            appliedjobdata.length != 0 ? <div className='row' style={{ rowGap: '10px' }}>
                                {
                                    appliedjobdata.map((v) => {
                                        return (
                                            <Comp_JobCard savedJobs={v} />
                                            // <JobCard jobtitle={v.job.job_role.name} companyname={v.job.company.companyname} location={v.job.office_address} minsalary={v.job.min_salary} maxsalary={v.job.max_salary} worktype={v.job.work_type.name} employementtype={v.job.job_type.name} slug={v.job.slug} applicationstatus={v.application_status} />
                                        )
                                    })
                                }
                            </div> : <div className="row mt-3">
                                <div className="col-lg-6 d-flex justify-content-center">
                                    <img style={{ width: '200px' }} src="/assets/images/appliedjobs.png" />
                                </div>
                                <div className="col-lg-6 d-flex align-items-center text-center">
                                    <h3 style={{ color: 'var(--primary-color)' }}>You haven't applied to any jobs yet</h3>
                                </div>
                            </div>
                        }
                    </div>
                </section >
            }
        </>
    )
}

export default ApplyJobs