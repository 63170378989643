import React, { useEffect, useState } from 'react'
import AdminAddIndustry from '../dialogue/AdminAddIndustry'
import { allIndustries_service } from '../../ApiServices';

const AdminIndustry = () => {

  const [Industries, setIndustries] = useState([]);

  useEffect(() => {
    getindustries();
  }, []);


  const getindustries = () =>{
    allIndustries_service().then((data)=>{
      setIndustries(data.data.data);
    }).catch((error)=>{

    })
  }

  return (
    <div className="container-fluid">
      <AdminAddIndustry getindustries={getindustries}/>
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">Industry</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addIndustry" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Industry</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                Industries.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#addstate" class="btn btn-sm btn-neutral text-danger-hover">
                          Edit
                        </button>
                        {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button> */}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {/* <div class="card-footer border-0 py-5">
          <span class="text-muted text-sm">Showing 10 items out of 250 results found</span>
        </div> */}
      </div>
    </div>
  )
}

export default AdminIndustry