import React from 'react'
import { Link } from 'react-router-dom'

const Notification = () => {
    return (
        <>
            <div className="container">
                <div className="row tab-bar scroll-hide" style={{ flexWrap: 'nowrap', overflowX: 'scroll',padding:'30px 0px'}}>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/profile'} className="card p-tab">
                            profile
                        </Link>
                    </div>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/notification'} className="card p-tab active">
                            Notification
                        </Link>
                    </div>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/saved-jobs'} className="card p-tab">
                            Saved Jobs
                        </Link>
                    </div>
                    <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
                        <Link to={'/applied-jobs'} className="card p-tab">
                            Applied Jobs
                        </Link>
                    </div>
                </div>
            </div>
            <section className='main-can-profile' style={{ padding: '50px 0px' }}>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-lg-6 d-flex align-items-center justify-content-center">
                            <img style={{width:'200px'}} src="/assets/images/notification_img.png"/>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center text-center">
                            <h3 style={{ color: 'var(--primary-color)' }}>You Don't Have Any Notification Yet.</h3>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default Notification