import React from 'react';
import './comp_popup_contact.css';
let _isOpenBox=false;
export function OpenChatPop(){
    //alert("i am in click open");
    if(_isOpenBox===false){
        // document.getElementById("myFormChatPop").style.display = "block";
        // document.getElementById("close-icon").style.display="block";
        // document.getElementById("open-icon").style.display="none";
        _isOpenBox=true;
    }else{
        // document.getElementById("myFormChatPop").style.display = "none";
        // document.getElementById("close-icon").style.display="none";
        // document.getElementById("open-icon").style.display="block";
        _isOpenBox=false;
    }
    
}
export function CloseChatPop(){
    
}
export const Comp_Popup_Contact=(props)=>{
    return(<div class="chatbox1">
        {/* <h2>Popup Chat Window</h2> */}
        <button class="open-button" onClick={()=>OpenChatPop()}>
        <a href="https://wa.me/919669313432" target={"blank"} style={{"color":"#fff"}} class="chat-btn flex-item green-color11 mb-2 ">
        <i id="open-icon22" class="fab fa-whatsapp fa-3x"></i>
        </a>
            <i id="close-ico22n" class="fa fa-times fa-2x close-icon" aria-hidden="true"></i>
            </button>
        
        <div class="chat-popup" id="myFormChatPop" name="myFormChatPop">
          <form class="chat-container">
            {/* <h2 class="center">Get in Touch</h2> */}
            <a href="https://wa.me/918169575610" class="chat-btn flex-item mb-2 "><i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i></a>
            <a target="blank" href="tel:+918169575610" class="chat-btn flex-item mb-2 "><i class="fa fa-phone fa-2x" aria-hidden="true"></i></a>
           
          </form>
        </div></div>);
}
export default Comp_Popup_Contact;