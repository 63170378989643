import React, { useEffect, useState } from 'react'
import AdminAddState from '../dialogue/AdminAddState'
import AdminEditState from '../dialogue/AdminEditState'
import { getallstateservice, getcountryservice } from '../../ApiServices';

const AdminState = () => {

  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([])
  const [stateid, setstateid] = useState(null)

  useEffect(() => {
    getcontries();
    getstates();
  }, [])


  const getcontries = () => {
    getcountryservice().then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setcountries(mappedoptions)
    }).catch((error) => {

    })
  }

  const getstates = () => {
    getallstateservice().then((data) => {
      setstates(data.data);
    }).catch((error) => {

    })
  }




  return (
    <div className="container-fluid">
      {/* state */}
      <AdminAddState countries={countries} getstates={getstates} />
      <AdminEditState countries={countries} getstates={getstates} stateid={stateid} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">State</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addstate" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Coutnry</th>
                <th scope="col">state</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                states.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.country.name}
                      </td>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#editstate" class="btn btn-sm btn-neutral text-danger-hover" onClick={()=>setstateid(v.id)}>
                          Edit
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminState