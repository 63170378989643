import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { uploadintrovideo_service,getMultiPartData } from '../../ApiServices';

const IntroVideo = ({getprofile}) => {

  const closedialogueRef = useRef();


  const [video, setvideo] = useState(null)
  const [error, seterror] = useState('')

  const canceldialogue = () => {
    setvideo(null)
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (max size: 5MB)
      if (file.size > 100 * 1024 * 1024) {
        seterror('File size exceeds the maximum limit (100MB).');
      } else {
        seterror('');
        
        var reader = new FileReader();
        reader.onload = function (ee) {
          alert(ee.target.result);
        }

        setvideo(file);
        
        //setvideo(reader.readAsDataURL(e.target.files[0]))
      }
    } else {
      seterror('Please select a file.');
    }
  };

  const submit = () => {
    //alert("i m in upload click");
    if (video) {
      //alert(video);
     
      let map={ser_candidate_id:btoa(localStorage.getItem('candidate_id'))
      };
      getMultiPartData(map,video,"uploadIntroVideo").then((res)=>{
        //alert(JSON.stringify(res));
        if(res.data==="001"){
          alert("Video updated Successfully");
          canceldialogue();
        getprofile();

        }else{
          alert("Unable to update , kindly try after sometime");
        }
      })
      // uploadintrovideo_service(video).then((data)=>{

      // }).catch((error)=>{

      // }).finally(()=>{

      // })
    
    }
  }

  return (
    <div class="modal fade" id="introVideo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Upload Intro Video 👑
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <label htmlFor="video" style={{ width: '100%' }}>
              <div className='res-btn'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
                    <path fill="#1F8268" d="M10.57 6.762H6.762v3.81a.764.764 0 0 1-.762.761.764.764 0 0 1-.762-.762v-3.81h-3.81A.764.764 0 0 1 .667 6c0-.42.343-.762.762-.762h3.81v-3.81c0-.419.342-.761.761-.761.42 0 .762.342.762.762v3.809h3.81c.419 0 .762.343.762.762s-.343.762-.762.762Z">
                    </path>
                  </svg>
                </div> {video ? video.name : 'Upload Intro Video'}</div>
            </label>
            <input onChange={handleFileChange} type="file" hidden name='video' id='video' accept="video/*" capture/>
            {error && <span className='text-danger'>{error}</span>}
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Upload</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntroVideo