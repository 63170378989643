import React, { useEffect, useRef, useState } from 'react'
import { getcountrybyid_service, updatecountrybyid_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminEditCountry = ({ countryid, getcountry }) => {

  const closedialogueRef = useRef();

  const navigate = useNavigate();

  const [formData, setformData] = useState({
    code: '',
    name: ''
  })

  const [errors, seterrors] = useState({})

  useEffect(() => {
    if (countryid != null) {
      getcountrybyid_service(countryid).then((res) => {
        setformData({
          code: res.data.country.code,
          name: res.data.country.name
        })
      }).catch((err) => {

      })
    }
  }, [countryid])

  const validateform = () => {
    const newerrors = {}

    if (!formData.code) {
      newerrors.code = "Code is Required";
    }

    if (!formData.name) {
      newerrors.name = "Name is Required";
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;

  }

  const HandleChange = (e) => {
    const { name, value } = e.target
    setformData({
      ...formData,
      [name]: value
    })

    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const canceldialogue = () => {
    setformData({
      code: '',
      name: ''
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      updatecountrybyid_service(formData, countryid).then((data) => {
        toast('✅ Country Updated Successfully');
        canceldialogue()
        getcountry();
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }


  return (
    <div class="modal fade" id="editcountry" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit Country
            <button ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <input type="text" name='code' value={formData.code} className="form-control" placeholder='country Code' onChange={HandleChange} />
              <input type="text" className="form-control mt-2" name='name' value={formData.name} onChange={HandleChange} placeholder='country Name' />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEditCountry