import React, { useState } from 'react'
import { faqdata } from '../../JsonData';
import { Helmet } from 'react-helmet';

const Faq = () => {

  const [faqactiveIndex, setfaqActiveIndex] = useState(null);

  const faqhandleButtonClick = (index) => {
    setfaqActiveIndex(index === faqactiveIndex ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>
          Frequently Asked Questions - ejobee Support Center
        </title>
        <meta name="description" content="Find answers to common queries and get assistance with ejobee's comprehensive FAQ section. Navigate through frequently asked questions about job searching, application processes, and platform features. Simplify your experience with ejobee's Support Center." />
      </Helmet>
      < section style={{ paddingBottom: '0px' }
      }>
        <div className="container">
          <div className="section-title mb-3">
            <h4>FAQ's</h4>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div class='faq-container'>
                {
                  faqdata.map((v, i) => {
                    return (
                      <div className={`faq ${i === faqactiveIndex ? 'active' : ''}`}>
                        <h3 class="faq-title">{v.question}</h3>
                        <p class="faq-text">{v.answer}</p>
                        <button onClick={() => faqhandleButtonClick(i)} class='faq-toggle'>
                          <svg class="chevron w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                          </svg>
                          <svg class="close w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                          </svg>
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-lg-6">
              <img className='w-100' src="/assets/images/faq-section.png" alt="EJOBEE" />
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default Faq