import React, { useRef, useState } from 'react'
import { postlanguagesservice } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddLanguages = ({ getlangauge }) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    name: '',
    code: ''
  })
  const [errors, seterrors] = useState({});

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    })

    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const validateform = () => {
    const newerror = {}

    if (!formdata.name) {
      newerror.name = "Language Name is Required";
    }

    if (!formdata.code) {
      newerror.code = "Language Code is Required";
    }

    seterrors(newerror);
    return Object.keys(newerror).length === 0;
  }

  const canceldialogue = () => {
    setformdata({
      name: '',
      code: '',
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    console.log(formdata);
    if (validateform()) {
      postlanguagesservice(formdata).then((data) => {
          toast('✅ Language Added Successfully');
          canceldialogue();
          getlangauge();
      }).catch((errors) => {

      })
    }
  }

  return (
    <div class="modal fade" id="addlanguages" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Language
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">

              <input type="text" className="form-control mt-2" placeholder='Language Name' value={formdata.name} onChange={HandleChange} name='name' />
              {errors.name && <span className='text-danger'>{errors.name}</span>}

              <input type="text" className="form-control mt-2" placeholder='Language code' value={formdata.code} onChange={HandleChange} name='code' />
              {errors.code && <span className='text-danger'>{errors.code}</span>}

            </div>
          </div>
          <div class="modal-footer">
            <button onClick={submit} type="button" class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAddLanguages