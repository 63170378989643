import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { buy_premium_service, candidateprofile_service } from '../../ApiServices';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { toast } from 'react-toastify';

import Comp_Payment_Option from '../ash_dialogue/comp_payment_option'

const Pricing = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const [candidateprofile, setcandidateprofile] = useState({});

    const { paymentstatus } = useParams();

    // if (paymentstatus=="success") {
    //     toast('✅ Payment Successfully');
    // }else{
    //     toast('❌ Payment Failed');
    // }

    useEffect(() => {
        if (localStorage.getItem('candidate_id')) {
            getprofile();
        }
    }, [])

    const getprofile = () => {
        //alert("i m in profile");
        candidateprofile_service().then((data) => {
            //alert(JSON.stringify(data));
            setcandidateprofile(data.data)
        }).catch((error) => { }).finally(() => {
        })
    }

    const buypremium = () => {
        //return;
        if (localStorage.getItem('candidate_id')) {
            let map={  candidate_id: localStorage.getItem('candidate_id'),
                user_type:1}
            buy_premium_service(map).then((response) => {
                //alert(JSON.stringify(response));
                if (response.data.payment_url) {
                    //return;
                    // console.log(response.data.payment_url);
                    window.location.href = response.data.payment_url;
                }
            }).catch((error) => {
                console.log(error);
            })
        } else {
            navigate('/login')
        }
    }

    const checkLoginOnPay=()=>{
        if (localStorage.getItem('candidate_id')) {
           // $('#divCompPaymentOption').show();
           const myModal = new window.bootstrap.Modal(document.getElementById('divCompPaymentOption'));
           myModal.show();
        }else{
            navigate('/login')
        }
    }


    useEffect(() => {
    //return;
        const fetchPaymentResponse = async () => {
            try {
                const params = new URLSearchParams(location.search);
                const response = await axios.post('https://yourdomain.com/api/payment-response', { encResp: params.get('encResp') });

                if (response.data.status === 'success') {
                    alert('Payment successful!');
                } else if (response.data.status === 'failed') {
                    alert('Payment failed!');
                } else {
                    alert('Payment status unknown!');
                }
            } catch (error) {
                console.error('Error handling payment response:', error);
            }
        };

        //fetchPaymentResponse();
    }, [location])


    return (
        <>
            <Helmet>
                <title>
                    Upgrade to Premium | EJOBEE
                </title>
                <meta name="description" content="Upgrade to eJobee Premium to access premium features and unlock exclusive job opportunities. Take your career to the next level with our premium membership." />
            </Helmet>

            <section>
                <div className="container">
                    <div className="row mt-4 reverse">
                        <div className="col-lg-6">
                            <h1>Unlock Premium Benefits with <span style={{ color: 'var(--primary-color)' }}>EJOBEE</span></h1>
                            <p>Upgrade Your Job Seeker Experience for Only <b>₹49/month</b></p>
                            <ul style={{ marginTop: '10px', paddingLeft: '0' }}>
                                <li style={{ listStyle: 'inside' }}>
                                    Instant Notifications
                                </li>
                                <li style={{ listStyle: 'inside' }}>
                                    Intro Video Showcase
                                </li>
                                <li style={{ listStyle: 'inside' }}>
                                    Premium Resume Templates
                                </li>
                                <li style={{ listStyle: 'inside' }}>
                                    Enhanced Profile Visibility
                                </li>
                                <li style={{ listStyle: 'inside' }}>
                                    Exclusive Career Insights
                                </li>
                                <li style={{ listStyle: 'inside' }}>
                                    Dedicated Customer Support
                                </li>
                            </ul>
                            {
                                candidateprofile?.candidate?.is_premium == 'true' ? <div className="btn btn-primary">
                                    Already Premium
                                </div> : <div onClick={()=>checkLoginOnPay()}   className="btn btn-primary">
                                    Upgrade Premium
                                </div>
                            }
                        </div>
                        <div className="col-lg-6">
                            <h1 style={{ fontSize: '60px' }}>👑</h1>
                            <img src="assets/logo/logo.png" alt="Ejobee" />
                        </div>
                    </div>
                </div>
            </section>

            <Comp_Payment_Option makeCc={buypremium} />
        </>
    )
}

export default Pricing