import React, { useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { addskill_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddSkills = ({ jobroles, getskills }) => {

    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        jobrole: '',
        name: ''
    })

    const [errors, seterrors] = useState({});

    const HandleChange = (e) => {
        const { name, value } = e.target
        setformdata({
            ...formdata,
            [name]: value
        })

        seterrors((preverror) => ({
            ...preverror,
            [name]: ""
        }))
    }

    const HandleSelectChange = (value, action) => {
        setformdata({
            ...formdata,
            [action.name]: value
        })

        seterrors((preverror) => ({
            ...preverror,
            [action.name]: ""
        }))
    }

    const canceldialogue = () => {
        setformdata({
            jobrole: '',
            name: ''
        })
        seterrors({})
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }


    const formvalidate = () => {
        const newerror = {};

        if (!formdata.jobrole) {
            newerror.jobrole = "Please Select Job Role";
        }

        if (!formdata.name) {
            newerror.name = "Skill Name is Required"
        }

        seterrors(newerror);
        return Object.keys(newerror).length === 0;
    }

    const submit = () => {
        if (formvalidate()) {
            addskill_service(formdata).then((data) => {
                toast('✅ Skills Added Successfully');
                canceldialogue()
                getskills()
            }).catch((error) => { })
        }
    }

    return (
        <div class="modal fade" id="addSkills" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Add Skills
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <ReactSelect placeholder="Select Jobroles" value={formdata.jobrole} options={jobroles} onChange={HandleSelectChange} name='jobrole' />
                            {errors.jobrole && <span className='text-danger'>{errors.jobrole}</span>}

                            <input type="text" className="form-control mt-2" placeholder='Skill Name' onChange={HandleChange} value={formdata.name} name='name' />
                            {errors.name && <span className='text-danger'>{errors.name}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAddSkills