import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { blog_post_service } from '../../ApiServices'
import InfiniteScroll from 'react-infinite-scroll-component'
import { public_url } from '../../ApiUrl'

const Blogs = () => {

    const [blogpost, setblogpost] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        getblogs();
    }, [])

    const getblogs = () => {
        blog_post_service(page).then((res) => {
            const newblogpost = res.data.data;
            setblogpost(prevblogpost => [...prevblogpost, ...newblogpost]);
            setHasMore(res.data.current_page < res.data.last_page);
            setPage(prevPage => prevPage + 1);
        }).catch((error) => {

        })
    }


    return (
        <>
            <Helmet>
                <title>Blog - Insights, Tips, and Career Advice | EJOBEE</title>
                <meta name="description" content="Explore the eJobee blog for valuable insights, tips, and career advice. Stay updated with the latest trends in the job market and enhance your career growth." />
            </Helmet>
            <section>
                <div className="container">
                    <div className="section-title mb-5">
                        <h4>Blogs</h4>
                    </div>
                    <InfiniteScroll
                    style={{overflow:'hidden'}}
                        dataLength={blogpost.length}
                        next={getblogs}
                        hasMore={hasMore}
                        loader={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0px' }}>
                            <div style={{ width: '20px', height: '20px' }} class="loading-loader"></div>
                        </div>}
                    // endMessage={<p>No more candidates to show.</p>}
                    >
                        <div className="row" style={{ rowGap: '50px' }}>
                            {
                                blogpost.map((b) => {
                                    return (
                                        <Link to={`/blogs/${b.id}/${b.slug}`} className="col-lg-4">
                                            <div class="blog">
                                                <div class="blog-img">
                                                    <img style={{width:'100%',height:'250px',objectFit:'contain'}} loading='lazy' src={public_url + b.image} alt="" />
                                                </div>
                                                <div class="blog-content">
                                                    <h2 class="blog-title">{b.title.substring(0,20)}</h2>
                                                    <p class="blog-desc" dangerouslySetInnerHTML={{__html:b.content.substring(0,250)}} ></p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </InfiniteScroll>
                </div>
            </section>
        </>
    )
}

export default Blogs