import React from 'react'
import { Navigate } from 'react-router-dom';

const LoginBypass = ({children,path}) => {
  const isAuthenticated = localStorage.getItem('admin-token');

  if (!isAuthenticated) {
    return children;
  }
  return (
    <Navigate to={path} />
  );
}

export default LoginBypass