import React, { useEffect, useState } from 'react'
import AdminAddCountry from '../dialogue/AdminAddCountry'
import AdminEditCountry from '../dialogue/AdminEditCountry'
import { getcountryservice } from '../../ApiServices';

const AdminCountry = () => {

  const [countries, setcountries] = useState([]);
  const [countryid, setcountryid] = useState(null);

  useEffect(() => {
    getcountires();
  }, [])

  const getcountires = () => {
    getcountryservice().then((data) => {
      setcountries(data.data)
    }).catch((error) => {})
  }


  return (
    <div className="container-fluid">
      {/* country */}
      <AdminAddCountry  getcountry={getcountires} />
      <AdminEditCountry getcountry={getcountires} countryid={countryid} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">Countries</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addcountry" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">country code</th>
                <th scope="col">Coutnry</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                countries.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.code}
                      </td>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#editcountry" class="btn btn-sm btn-neutral text-danger-hover" onClick={()=>setcountryid(v.id)}>
                          Edit
                        </button>
                        {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button> */}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminCountry