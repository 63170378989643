import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { company_profile_service, company_update_profile_service, getcityservice, getcountryservice, getstateservice } from '../../ApiServices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const CompanyProfile = () => {

  const navigate = useNavigate();

  const [formdata, setformdata] = useState({
    companyname: "",
    firstname: "",
    lastname: "",
    numberemployees: "",
    email: "",
    gender: "",
    mobile: "",
    country: "",
    state: "",
    city_name: ""
  })

  const [errors, seterrors] = useState({});

  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);

  useEffect(() => {
    getcountry();
    getprofile();
  }, [])


  const getcountry = () => {
    getcountryservice().then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setcountries(mappedoptions)
    }).catch((error) => {
      getcountry()
    })
  }


  const getstate = (countryid) => {
    getstateservice(countryid).then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setstates(mappedoptions)
    }).catch((error) => {
      getstate();
    })
  }


  const getcities = (stateid) => {
    // getcityservice(stateid).then((data) => {
    //   const mappedoptions = data.data.map((v) => ({
    //     value: v.id,
    //     label: v.name
    //   }));
    //   setcities(mappedoptions)
    // }).catch((error) => {
    //   getcities();
    // })
  }

  const getprofile = () => {
    const id = localStorage.getItem('company_id');
    if (id) {
      company_profile_service(id).then((data) => {
        setformdata({
          ...formdata,
          firstname: data.data.company.firstname,
          lastname: data.data.company.lastname,
          companyname: data.data.company.companyname,
          gender: data.data.company.gender,
          email: data.data.company.email,
          mobile: `+${data.data.company.mobile}`,
          numberemployees: { value: data.data.company.no_of_employees, label: data.data.company.no_of_employees },
          country: { value: data.data.company.country.id, label: data.data.company.country.name },
          state: { value: data.data.company.state.id, label: data.data.company.state.name },
          city_name:data.data.company.city_name
          //city: { value: data.data.company.city.id, label: data.data.company.city.name }
        })
        getstate(formdata.country.value);
        getcities(formdata.state.value)
      }).catch((error) => {
        console.log(error);
      })
    } else {
      navigate('/login')
    }
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setformdata({
      ...formdata,
      [name]: value
    })
  }

  const handleSelectChange = (data, action) => {
    seterrors((prevErrors) => ({
      ...prevErrors,
      [action.name]: '',
    }));
    setformdata({
      ...formdata,
      [action.name]: data
    })
    if (action.name == "country") {
      getstate(data.value);
    }

    if (action.name == "state") {
      //getcities(data.value);
    }
  }




  const formvalidate = () => {
    const newErrors = {};

    if (!formdata.companyname) {
      newErrors.companyname = "Company Name is Required";
    }

    if (!formdata.numberemployees) {
      newErrors.numberemployee = "Number of Employee is Required";
    }

    if (!formdata.firstname) {
      newErrors.firstname = "Firstname is Required";
    }

    if (!formdata.lastname) {
      newErrors.lastname = "Lastname is Required";
    }

    if (!formdata.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formdata.email)) {
      newErrors.email = 'Email is Not Valid';
    }

    if (!formdata.country) {
      newErrors.country = 'country is Required';
    }

    if (!formdata.state) {
      newErrors.state = 'State is Required';
    }

    if (!formdata.city_name) {
      newErrors.city_name = 'City is Required';
    }

    seterrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  const updatebtnclick = () => {
    if (formvalidate()) {
      company_update_profile_service(formdata).then((data) => {
        toast('✅ profile Updated Successfully', { autoClose: 3000 });
        getprofile();
      }).catch((error) => {

      })
    }
  }

  const employeework = [
    { value: "0-10", label: "0-10" },
    { value: "10-20", label: "10-20" },
    { value: "20-50", label: "20-50" },
    { value: "50-100", label: "50-100" },
    { value: "100-150", label: "100-150" },
    { value: "150-200", label: "150-200" },
    { value: "200-250", label: "200-250" },
    { value: "250+", label: "250+" },
  ]

  return (<>
   <Helmet>
                <title>Welcome to Ejobee</title>
                <meta name="description" content="All Jobs That Company Have Posted For Candidate." />
            </Helmet>
    <section style={{ padding: '10px 10px' }}>
      <div className="container mt-5">

        <div className="row">
          <div className="col-lg-6">
            <div className="card">

            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6">
            <input type="text" readonly={"true"} className="form-control" name='companyname' value={formdata.companyname} placeholder='Company Name' onChange={handleChange} />
            {errors.companyname && <span className="text-danger">{errors.companyname}</span>}
          </div>
          <div className="col-lg-6">
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="numberemployees"
              onChange={handleSelectChange}
              value={formdata.numberemployees}
              placeholder="no. Of Employee"
              options={employeework}
            />
            {errors.numberemployee && <span className="text-danger">{errors.numberemployee}</span>}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6">
            <input type="text" value={formdata.firstname} placeholder='First Name' name='firstname' onChange={handleChange} className="form-control" />
            {errors.firstname && <span className="text-danger">{errors.firstname}</span>}
          </div>
          <div className="col-lg-6">
            <input type="text" value={formdata.lastname} name='lastname' placeholder='Last Name' className="form-control" onChange={handleChange} />
            {errors.lastname && <span className="text-danger">{errors.lastname}</span>}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6">
            <input type="text" readonly={"true"} value={formdata.email} placeholder='Email' className="form-control" name='email' onChange={handleChange} />
            {errors.email && <span className="text-danger">{errors.email}</span>}
          </div>
          <div className="col-lg-6">
            <input type="text" value={formdata.mobile} disabled placeholder='mobile Number' name='mobile' className="form-control" onChange={handleChange} />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12 d-flex gap-4 align-items-center">
            <label htmlFor="">Gender</label>
            <div className='d-flex gap-3 align-items-center justify-content-center'>
              <label className='d-flex gap-1 align-items-center justify-content-center'>
                <input
                  type="radio"
                  value="male"
                  name="gender"
                  checked={formdata.gender === 'male'}
                  onChange={handleChange}
                />
                Male
              </label>
              <label className='d-flex gap-1 align-items-center justify-content-center'>
                <input
                  type="radio"
                  value="female"
                  name="gender"
                  checked={formdata.gender === 'female'}
                  onChange={handleChange}
                />
                Female
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-6">
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select Country"
              isSearchable={true}
              isDisabled={true} 
              value={formdata.country}
              name="country"
              onChange={handleSelectChange}
              options={countries}
            />
            {errors.country && <span className="text-danger">{errors.country}</span>}
          </div>
          <div className="col-lg-6">
            <Select
            readOnly={"true"}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select State"
              isSearchable={true}
              name="state"
              onChange={handleSelectChange}
              value={formdata.state}
              options={states}
            />
            {errors.state && <span className="text-danger">{errors.state}</span>}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-6">
            {/* <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder='select City'
              isSearchable={true}
              value={formdata.city}
              name="city"
              onChange={handleSelectChange}
              options={cities}
            /> */}
            <input type="text" className="form-control" name='city_name' value={formdata.city_name} placeholder='City Name' onChange={handleChange} />
            {errors.city_name && <span className="text-danger">{errors.city_name}</span>}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 mt-3">
            <div onClick={updatebtnclick} className="btn btn-primary">Update Company Profile</div>
          </div>
        </div>

      </div>
    </section>
  </>)
}

export default CompanyProfile