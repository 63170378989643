import React from 'react'
import { Helmet } from 'react-helmet'

const Refund = () => {
    return (
        <>
            <Helmet>
                <title>
                    Refund And Return Policy - Your Trust Matters at ejobee
                </title>
            </Helmet>
            <section>
                <div className="container">
                    <div className="section-title mb-3">
                        <h4>Return and Refund Policy</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column gap-5">
                            <p><b>No Refund Policy -</b> At Ejobee, we do not offer any return or refund policy for our services. This applies to all plans for candidates and companies.</p>

                            <p>
                            <b>Immediate Service Activation -</b> Once a plan is purchased by a candidate or a company, the associated services are made available to you immediately. Due to the immediate nature of our services, any fees paid are non-refundable.
                            </p>

                            <p>
                            <b> User Responsibility -</b> We encourage all users to carefully consider their choices before making a purchase or activating a plan on our platform. Please ensure that you fully understand the services you are selecting and that they meet your needs.
                            </p>

                            <p>
                            <b>Support and Resolution -</b> In the event of any technical issues or dissatisfaction with our services, we are committed to resolving the matter promptly and providing assistance to the best of our abilities.
                            </p>

                            <p>
                            <b>Contact Information -</b> For any questions or concerns regarding our services or this policy, please contact us at:
                            </p>
                            <p>
                            <b>Email:</b> yourejobee@gmail.com<br/>
Thank you for choosing Ejobee for your job-seeking or recruiting needs.

                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Refund