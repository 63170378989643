import React, { useEffect, useRef, useState } from 'react'
import { getcitybyid_service, getstateservice, updatecitybyid_service } from '../../ApiServices';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminEditCity = ({ countries, getcities, cityid }) => {


  const navigate = useNavigate();

  const [formdata, setformdata] = useState({
    country: '',
    state: '',
    name: ''
  })
  const [errors, seterrors] = useState({})
  const [state, setstate] = useState({});

  const closedialogueRef = useRef();

  const Handlechange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    })
    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const HandleSelectChange = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    })
    seterrors((preverror) => ({
      ...preverror,
      [action.name]: ''
    }))

    if (action.name == "country") {
      getstatebycountry();
    }
  }

  useEffect(() => {
    getcitybyid_service(cityid).then((res) => {
      setformdata({
        country: { value: res.data.city.state.country.id, label: res.data.city.state.country.name },
        state: { value: res.data.city.state.id, label: res.data.city.state.name },
        name: res.data.city.name
      })
      getstatebycountry();
    }).catch((error) => {

    })

  }, [cityid])



  const getstatebycountry = () => {
    getstateservice(formdata.country.value).then((data) => {
      const mappedoptions = data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setstate(mappedoptions)
    })
  }

  const validateform = () => {
    const newerror = {};

    if (!formdata.country) {
      newerror.country = "Please Select Country";
    }

    if (!formdata.state) {
      newerror.state = "Please Select State"
    }

    if (!formdata.name) {
      newerror.name = "City Name is Required"
    }

    seterrors(newerror);
    return Object.keys(newerror).length === 0;
  }

  const canceldialogue = () => {
    setformdata({
      country: '',
      state: '',
      name: ''
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      updatecitybyid_service(formdata, cityid).then((res) => {
        toast('✅ City Updated Successfully');
        canceldialogue()
        getcities();
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }

  return (
    <div class="modal fade" id="editcity" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit City
            <button type="button" ref={closedialogueRef} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <ReactSelect placeholder="Select Country" name='country' onChange={HandleSelectChange} value={formdata.country} options={countries} />
              {errors.country && <span className='text-danger'>{errors.country}</span>}

              <ReactSelect placeholder="Select State" name='state' onChange={HandleSelectChange} value={formdata.state} options={state} className='mt-2' />
              {errors.state && <span className='text-danger'>{errors.state}</span>}

              <input type="text" value={formdata.name} onChange={Handlechange} className="form-control mt-2" placeholder='City name' name='name' />
              {errors.name && <span className='text-danger'>{errors.name}</span>}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEditCity