import React from 'react'

const AdminJobsDetail = () => {
  return (
    <div class="modal fade" id="jobdetail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Job Detail
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            lorem1000
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">cancel</button>
            {/* <button type="button" class="btn btn-danger" style={{ borderRadius: '0' }}>Logout</button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminJobsDetail