import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams,useSearchParams } from 'react-router-dom'
import { buy_premium_service, candidateprofile_service } from '../../ApiServices';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { toast } from 'react-toastify';

import Comp_Payment_Option from '../ash_dialogue/comp_payment_option';
import {getCondidatesView} from '../../ApiServices';

const Page_Payment_Receipt = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const [loading, setloading] = useState(true);
    const [getPaymentTransactions, setPaymentTransactions] = useState({});

    //alert(JSON.stringify(paymentstatus));
    const [searchParams] = useSearchParams();
    const paramValue = searchParams.get('rf');
    //alert(paramValue);


   
    useEffect(() => {
        getPaymentReceipt();
    }, [paramValue])

   

    const getPaymentReceipt = () => {
        let map={};
        if(localStorage.getItem("company_id")){
            map["ser_company_id"]=btoa(localStorage.getItem('company_id'));
        }else if(localStorage.getItem("candidate_id")){
            map["ser_candidate_id"]=btoa(localStorage.getItem('candidate_id'));
        }
        map["rf"]=paramValue;
        
        getCondidatesView(map,'getCandidatePayments').then((res) => {
            //alert(res.data);
            const newCandidates = JSON.parse(res.data).transactions;
            if(newCandidates.length>0){
                setPaymentTransactions(newCandidates[0]);
            }
            
        }).catch((error) => {
            toast('❌' + error.message);
        }).finally(() => {
            setloading(false);
        })
    }


   


    return (
        <>
            <Helmet>
                <title>
                    Upgrade to Premium | EJOBEE
                </title>
                <meta name="description" content="Upgrade to eJobee Premium to access premium features and unlock exclusive job opportunities. Take your career to the next level with our premium membership." />
            </Helmet>

            <section>
                <div className="container-vsm border-box">
                {getPaymentTransactions.user_type==="2"?
          <div class="row">
            <div class="col-12 checkmark">
                {getPaymentTransactions.status==="Success"?<i class="fa fa-check-circle-o fa-2x" ></i>:<i class="fa fa-times fa-2x" aria-hidden="true"></i>}
                
                </div>
            <div class="col-12 amountdiv">Receipt Amount <b>{getPaymentTransactions.amount}</b></div>
            <div class="col-6 left">Refrence No.</div>
            <div class="col-6 right"><b>{getPaymentTransactions.order_id}</b></div>
            <div class="col-6 left">Payment Time</div>
            <div class="col-6 right"><b>{getPaymentTransactions.created_at}</b></div>
            <div class="col-6 left">Payment Method</div>
            <div class="col-6 right">-</div>
            <div class="col-6 left">Company Name</div>
            <div class="col-6 right"><b>{getPaymentTransactions.companyname}</b></div>
            <div class="col-6 left">Job Post Title</div>
            <div class="col-6 right"><b>{getPaymentTransactions.jobrole_name}</b></div>
            <div class="col-6 left">AMOUNT</div>
            <div class="col-6 right"><b>{getPaymentTransactions.amount}</b></div>
            <div class="col-6 left">Transaction Status</div>
            <div class="col-6 right"><b>{getPaymentTransactions.status}</b></div>
          </div>:
          ''
          }

          {/* for candidate view */}
          {getPaymentTransactions.user_type==="1"?
          <div class="row">
            <div class="col-12 checkmark">
            {getPaymentTransactions.status==="Success"?<i class="fa fa-check-circle-o fa-2x" ></i>:<i class="fa fa-times fa-2x" aria-hidden="true"></i>}
                </div>
            <div class="col-12 amountdiv">Receipt Amount <b>{getPaymentTransactions.amount}</b></div>
            <div class="col-6 left">Refrence No.</div>
            <div class="col-6 right"><b>{getPaymentTransactions.order_id}</b></div>
            <div class="col-6 left">Payment Time</div>
            <div class="col-6 right"><b>{getPaymentTransactions.created_at}</b></div>
            <div class="col-6 left">Payment Method</div>
            <div class="col-6 right">-</div>
            <div class="col-6 left">Candidate Name</div>
            <div class="col-6 right"><b>{getPaymentTransactions.firstname} {getPaymentTransactions.lastname}</b></div>
            {/* <div class="col-6 left">Job Post Title</div>
            <div class="col-6 right">{props.getReceipt.jobrole_name}</div> */}
            <div class="col-6 left">AMOUNT</div>
            <div class="col-6 right"><b>{getPaymentTransactions.amount}</b></div>
            <div class="col-6 left">Transaction Status</div>
            <div class="col-6 right"><b>{getPaymentTransactions.status}</b></div>
          </div>:
          ''
          }
                </div>
            </section>
 </>
    )
}

export default Page_Payment_Receipt