import React from 'react'
import { Navigate } from 'react-router-dom';

const CompanyProtection = ({ children }) => {

  const isAuthenticated = localStorage.getItem('company_id');
  const isprofilecompleted = localStorage.getItem('company_is_profile_completed');

  if (isAuthenticated) {
    if (isprofilecompleted=='true') {
      return children;
    } else {
      return <Navigate to={`/company/complete-profile?id=${localStorage.getItem('company_id')}`}/>
    }
  } else {
    return (
      <Navigate to="/login" />
    );
  }
}

export default CompanyProtection