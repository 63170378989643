import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import {editeducationlevelbyid_service, educationlevelbyid_service, } from '../../ApiServices';
import { useNavigate } from 'react-router-dom';

const AdminEditEducationLevel = ({ geteducationlevels, educationlevelid }) => {
  const closedialogueRef = useRef();

  const navigate = useNavigate();

  const canceldialogue = () => {
    setformdata({
      name: '',
    })
    seterrors({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }


  const [formdata, setformdata] = useState({
    name: ''
  });

  const [errors, seterrors] = useState({})


  useEffect(() => {
    if (educationlevelid != null) {
      educationlevelbyid_service(educationlevelid).then((res) => {
        setformdata({
          name: res.data[0].name
        })
      }).catch((error) => {
      })
    }
  }, [educationlevelid])


  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    })
    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }


  const formvalidate = () => {
    const newerrors = {};

    if (!formdata.name) {
      newerrors.name = "Level Name is Required";
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0
  }

  const submit = () => {
    if (formvalidate()) {
      editeducationlevelbyid_service(educationlevelid,formdata).then((res) => {
        toast('✅ education level edited Successfully');
        canceldialogue();
        geteducationlevels();
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }


  return (
    <div class="modal fade" id="editeducationlevel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Education Level
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <input type="text" className="form-control mt-2" placeholder='Level' onChange={HandleChange} value={formdata.name} name='name' />
              {errors.name && <span className='text-danger'>{errors.name}</span>}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEditEducationLevel