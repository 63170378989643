import React, { useEffect, useState } from 'react'
import { alljobplan_service, company_profile_service, freejobpost_service } from '../../ApiServices';
import { useNavigate } from 'react-router-dom';
import Comp_Ejobee_Pricing from '../../web/component/comp_ejobee_pricing';
import { toast } from 'react-toastify';

const SelectPlan = ({ nxtbtnclick, backbtnclick, jobid }) => {

  const [jobplans, setjobplans] = useState([]);
  const [selectedplan, setselectedplan] = useState({})
  const [freepost, setfreepost] = useState('');


  const navigate = useNavigate();

  useEffect(() => {
    getaljobplan();
    getprofile();
  }, [])

  const getaljobplan = () => {
    alljobplan_service().then((res) => {
      setjobplans(res.data.plans)
    }).catch((err) => {

    })
  }


  const getprofile = () => {
    const id = localStorage.getItem('company_id');
    if (id) {
      company_profile_service(id).then((data) => {
        setfreepost(data.data.company.freejobpost);
      }).catch((error) => {
        console.log(error);
      })
    } else {
      navigate('/login')
    }
  }


  const [formdata, setformdata] = useState({
    planid: "",
    planAmount:1,
  });

  const [errors, seterrors] = useState({});

  const HandleChange = (e) => {
    const { name, value } = e.target
   

    const plan = jobplans.find(plan => plan.id === parseInt(value));
    setselectedplan(plan)

    seterrors((preverror) => ({
      ...preverror,
      [name]: ''
    }))
    setformdata({
      ...formdata,
      [name]: value,
      ["planAmount"]:parseFloat(plan.price + (plan.price * 0.18)).toFixed(2)

    })
  }

  const validateform = () => {
    const newerrors = {}
    // step5
    if (!formdata.planid) {
      newerrors.planid = "Please Choose Plans according to Your Job Post"
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }


  const continuebtnclick = () => {
    if (validateform()) {
      // alert("i m in click");
      // return;
      nxtbtnclick(jobid,formdata)
    }
  }

  const jobpostbtnclick = () =>{
    if (validateform()) {
      freejobpost_service(jobid,formdata).then((res)=>{
        if (res.data.message=="No Free Job post") {
          toast('❌' + res.data.message)
        }else{
          toast('✅ Job Published Successfully');
          navigate('/company')
          window.location.reload();
        }
      }).catch((error)=>{
        toast('❌' + error.message);
      })
    } 
  }




  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="card text-center p-4 pricing-section">
            <p className='fs-4 text-black mb-2'>Choose a preferred plan below to post a Job</p>
            {/* plans */}
            <Comp_Ejobee_Pricing buyNow={false} onChangeSelectPlan={HandleChange} lsBusinessPlans={jobplans} />
            <div className="row gy-4 mt-2 border-bottom pb-4 pricing ">

              

              {errors.planid && <span className='mt-2 text-danger'>{errors.planid}</span>}
            </div>

            {/* payment */}

            <div className="row mt-3">
              <h5 className='text-start'>Purchase Details</h5>
              <div className='border m-2 p-4 text-start'>
                <div className="border-bottom pb-4 row text-black">
                  <p style={{ fontWeight: 'bolder', fontSize: '16px', color: 'black' }}>Payment Summary</p>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      Amount
                    </div>
                    <div>
                    ₹ {
                        formdata.planid ?
                          selectedplan.price : "00"
                      }
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      GST (18%)
                    </div>
                    <div>
                    ₹ {
                        formdata.planid ?
                          (selectedplan.price * 0.18).toFixed(2) : "00"
                      }
                    </div>
                  </div>
                  {/* <div className='d-flex align-items-center justify-content-between mt-2'>
                    <div className='border-bottom cursor-pointer'>
                      I have a GST
                    </div>
                  </div> */}
                </div>
                <div className='d-flex align-items-center justify-content-between mt-2 text-black'>
                  <div className='fw-bold fs-4'>
                    Total
                  </div>
                  <div className='fs-4 fw-bolder'>
                    ₹ {formdata.planid ? parseFloat(selectedplan.price + (selectedplan.price * 0.18)).toFixed(2) : "00"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12">
                <div onClick={backbtnclick} className="btn btn-primary me-2">Back</div>
                {freepost>0 ? <div onClick={jobpostbtnclick} className="btn btn-primary me-2">Use Free Post</div> : "" }
                <div onClick={continuebtnclick} className="btn btn-primary">Proceed Payment</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectPlan