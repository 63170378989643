import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { getstatebyid_service, updatestatebyid_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminEditState = ({ countries, getstates, stateid }) => {

  const closedialogueRef = useRef();

  const [formdata, setformdata] = useState({
    country: '',
    name: ''
  })
  const [error, seterror] = useState({});

  useEffect(() => {
    if (stateid != null) {
      getstatebyid();
    }
  }, [stateid])

  const getstatebyid = () => {
    getstatebyid_service(stateid).then((res) => {
      setformdata({
        country: { value: res.data.state.country.id, label: res.data.state.country.name },
        name: res.data.state.name
      });
    }).catch((err) => {

    })
  }


  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    });
    seterror((preverror) => ({
      ...preverror,
      [name]: ''
    }))
  }

  const HandleSelectChange = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    });

    seterror((preverror) => ({
      ...preverror,
      [action.name]: ''
    }))
  }


  const validateform = () => {
    const newerror = {}

    if (!formdata.country) {
      newerror.country = "Please Select County"
    }

    if (!formdata.name) {
      newerror.name = "Name is Required";
    }

    seterror(newerror);
    return Object.keys(newerror).length === 0;
  }

  const canceldialogue = () => {
    setformdata({
      country: '',
      name: ''
    })
    seterror({});
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      updatestatebyid_service(formdata, stateid).then((data) => {
        toast('✅ State Update Successfully');
        canceldialogue()
        getstates()
      }).catch((error) => {

      })
    }
  }

  return (
    <div class="modal fade" id="editstate" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Edit State
            <button type="button" ref={closedialogueRef} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <ReactSelect placeholder="Select Country" options={countries} value={formdata.country} onChange={HandleSelectChange} name='country' />
              {error.country && <span className="text-danger">{error.country}</span>}
              <input type="text" className="form-control mt-2" placeholder='State Name' name='name' value={formdata.name} onChange={HandleChange} />
              {error.name && <span className="text-danger">{error.name}</span>}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEditState