import React from 'react';
//import {appGetUrlPath} from '../function_lib/common_cms';
//blog-slider-single
 import './comp_testimonials.css';
export function Comp_Testimonials(props){
   // alert(JSON.stringify(props.lsData));
/*Header Values */
let injIndex=-1,needRatings=true;

var title="";
let lsData=props.lsData;
if(props.lsData.length!=0){
title=lsData[0]["g_title"];
}
var appPath="/assets/images/";
//var listData=JSON.parse(lsData);
var strResp="";

let divId="";
//alert("testimonials=="+injIndex)
    if(injIndex!==-1){
      divId="divind"+injIndex;
   }

   let isBackGroundImg="";
   let sliderCss="blog-slider";
   let thumbImgPlace="testi_img";
   let bgTransparent="";
   let txtColor=""
   if(lsData.length>0){
    if(lsData[0]["col5"]!==""){
        //isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[0]["col5"]+")\"";
    }
    //alert(lsData[0]["col13"]);
    if(lsData[0]["col13"]!==""){
        sliderCss=lsData[0]["col13"];
        thumbImgPlace="testi_img_center";
        bgTransparent="bg_transparent ";
        txtColor="txt-white";
    }  
    
   }

   return(<div id={divId} class={"orange11-bg main-blog-area pt-50 pb-50"+isBackGroundImg}>
            <div class="container">
        <div class="row">
        <div class="col-md-12">
            <div class="heading page__title-bar_testi">
                <h3 class={"title-black title "+txtColor}>{title}</h3>
            </div>
        </div>
    </div>
    <div class={sliderCss+" swiper-container slider-nav-style-1"}>
    <div class="new-product-wrapper1 pt-10 swiper-wrapper">
        {/* content */}
        {props.lsData.map((item,index)=>{

var urlTitle= item["col1"];
urlTitle=urlTitle.replace("?","_");
urlTitle=urlTitle.replace(" ","-");
var urlCol7="#";
if(item["col7"]!==""){
    urlCol7=item["col7"];
}
let urlTxtCol8="Check Product";
if(item["col8"]){
    urlTxtCol8=item["col8"];
}
var imgPath="default-product.png";
if(item["col4"]!=""){
    imgPath=item["col4"];
     }
let titleC;
let positionC="";
let arrTitle=item["col1"].split(',');
titleC=arrTitle[0];
if(arrTitle.length>1){
    positionC=arrTitle[1];
}

return (<>
{needRatings!==true?
<div class={"card1 mycard "+bgTransparent+" "+txtColor+" single-blog swiper-slide col-md-5"}>
        
        <div class="card12 mycard12">
          <div class="card-body12">
           <img class={thumbImgPlace} src={appPath+imgPath} alt="img_test"  />
            {/* <i class="fa fa-quote-left\" aria-hidden=\"true\"></i> */}
            {/* <a href=\"#\" class=\"title-link\"><h5 class=\"card-title\">"+lsData[i]["col1"]+"</h5></a>"+ */}
            <p class="card-text" style={{"text-align":"center"}}>{item["col2"]}</p>
            <p class="font-w">{item["col3"]}</p>
            {/* //"<span style=\"text-align:center;\" class=\"price1 "+bgTransparent+" "+txtColor+" heading article_thumb\">"+ */}
            <span style={{"text-align":"center"}} class={"title1 title_testimonical title-black "+txtColor}><span>{titleC}</span><span>{positionC}</span></span>
          </div>
        </div>

    </div>:<div class={"card1 mycard "+bgTransparent+" "+txtColor+" single-blog swiper-slide col-md-5"}>
        <div class={"card12 mycard12"}>
          <div class="card-body12">
           <img class={thumbImgPlace} src={appPath+imgPath} alt="img_test" />
            {/* "<i class=\"fa fa-quote-left\" aria-hidden=\"true\"></i>" */}
            {/* <a href=\"#\" class=\"title-link\"><h5 class=\"card-title\">"+lsData[i]["col1"]+"</h5></a>"+ */}
            <p class="card-text" style={{"text-align":"center"}}>{item["col2"]}</p>
            <p class="font-w">{item["col3"]}</p>
            <hr/>
            <span class={"price1 "+bgTransparent+" "+txtColor+" border-top heading article_thumb"}>
            <span style={{"float":"left"}} class={"title1 title_testimonical title-black "+txtColor}><b>{titleC}</b><span>{positionC}</span></span>
            <span style={{"float":"right"}} class="title1">
            <div class="rating-product">
                <i class="ion-android-star"></i>
                <i class="ion-android-star"></i>
                <i class="ion-android-star"></i>
                <i class="ion-android-star"></i>
                <i class="ion-android-star-outline"></i>
            </div>
        </span>
        </span>
           
          </div>
        </div>
    </div>
    
    }
    </>)

        })}
        {/* end of content */}

        </div>
<div class="swiper-buttons">
<div class="swiper-button-next"></div>
<div class="swiper-button-prev"></div>
</div>
</div></div></div>
        )
 

 
}
export default Comp_Testimonials;