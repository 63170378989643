import React, { useEffect, useState } from 'react'
import AdminAddEducationLevel from '../dialogue/AdminAddEducationLevel'
import AdminEditEducationLevel from '../dialogue/AdminEditEducationLevel'
import { education_level_service } from '../../ApiServices';

const AdminEducationLevel = () => {

  const [educationlevels, seteducationlevels] = useState([]);

  const [educationlevelid, seteducationlevelid] = useState(null)

  useEffect(() => {
    getalleducationlevel();
  }, [])

  const getalleducationlevel = () =>{
    education_level_service().then((data)=>{
      console.log(data);
      seteducationlevels(data.data.data);
    }).catch((error)=>{})
  }

  return (
    <div className="container-fluid">
      {/* education level */}
      <AdminAddEducationLevel geteducationlevels={getalleducationlevel}/>
      <AdminEditEducationLevel geteducationlevels={getalleducationlevel} educationlevelid={educationlevelid} />
      <div class="card shadow border-0 mb-7">
        <div class="card-header d-flex justify-content-between">
          <h5 class="mb-0">Education Level</h5>
          <button type="button" data-bs-toggle="modal" data-bs-target="#addeducationlevel" class="btn btn-sm btn-neutral text-danger-hover">
            <i class="bi bi-plus"></i>
            Add
          </button>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-nowrap">
            <thead class="thead-light">
              <tr>
                <th scope="col">Level</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                educationlevels.map((v) => {
                  return (
                    <tr>
                      <td>
                        {v.name}
                      </td>
                      <td class="text-end">
                        <button onClick={()=>seteducationlevelid(v.id)} type="button" data-bs-toggle="modal" data-bs-target="#editeducationlevel" class="btn btn-sm btn-neutral text-danger-hover">
                          Edit
                        </button>
                        {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i class="bi bi-trash"></i>
                        </button> */}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminEducationLevel