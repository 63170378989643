import React, { useEffect, useState } from 'react'
import Navbar from '../web/component/Navbar'
import { Outlet, useNavigate } from 'react-router-dom'
import Footer from '../web/component/Footer'
import Sidebar from '../web/component/Sidebar'
import Comp_Ejobee_Footer from '../web/component/comp_ejobee_footer';
import { candidateprofile_service } from '../ApiServices'

const WebLayout = ({ issidebaractive, menubtnclick }) => {

    const navigate = useNavigate();
    useEffect(() => {
        const iscompanyAuthenticated = localStorage.getItem('company_googleuid');
        if (iscompanyAuthenticated) {
            navigate('/company');
        }
    }, [])

    const [candidatefirstname, setcandidatefirstname] = useState('');
    const [candidatelastname, setcandidatelastname] = useState('');

    useEffect(() => {
        if (localStorage.getItem('candidate_id')) {
            getcandidateprofile();
        }
    }, [])

    const getcandidateprofile = () => {
        candidateprofile_service().then((data) => {
            setcandidatefirstname(data.data.candidate.firstname)
            setcandidatelastname(data.data.candidate.lastname)
        }).catch((error) => { })
    }

    return (
        <div className={issidebaractive ? 'sidebaractive' : ''} >
            <Sidebar isactive={issidebaractive} menuclick={menubtnclick} />
            <Navbar menuclick={menubtnclick} firstname={candidatefirstname} lastname={candidatelastname} />
            <Outlet />
            
            <Footer />
            <Comp_Ejobee_Footer/>
        </div>
    )
}

export default WebLayout