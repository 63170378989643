import React, { forwardRef, useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import { addworkexperience_service, alljobrole_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import MyMonthYearPicker from './MyMonthYearPicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddWorkExperience = ({getprofile}) => {
  const closedialogueRef = useRef();


  const [formdata, setformdata] = useState({
    jobrole: "",
    companyname: "",
    startDate: "",
    endDate: "",
    is_working: false
  });
  const [errors, seterrors] = useState({})
  const [jobroles, setjobroles] = useState([]);

  useEffect(() => {
    getjobroles();
  }, [])

  const getjobroles = () => {
    alljobrole_service().then((data) => {
      const mappedoptions = data.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }));
      setjobroles(mappedoptions)
    }).catch((error) => { })
  }


  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    })
    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  }

  const HandleSelectChange = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    })
    seterrors((prevErrors) => ({
      ...prevErrors,
      [action.name]: '',
    }));
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const onisworkingclick = () => {
    setformdata({
      ...formdata,
      is_working: !formdata.is_working
    })
  }

  const validateform = () => {
    const newerrors = {}

    if (!formdata.companyname) {
      newerrors.companyname = "Company Name is Required"
    }

    if (!formdata.jobrole) {
      newerrors.jobrole = "please Select Jobrole"
    }

    if (!formdata.is_working) {
      if (!formdata.startDate || !formdata.endDate) {
        newerrors.date = "please Select Start And End Date"
      }
    } else {
      if (!formdata.startDate) {
        newerrors.date = "please Select Start Date"
      }
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const canceldialogue = () => {
    setformdata({
      jobrole: "",
      companyname: "",
      startDate: "",
      endDate: "",
      is_working: false
    })
    seterrors({})
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    if (validateform()) {
      addworkexperience_service(formdata).then((data) => {
        toast('✅ Experience Added Successfully');
        getprofile();
        canceldialogue()
      }).catch((error) => {

      })
    }
  }

  return (
    <div class="modal fade" id="addworkexperience" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Work Experience
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <input type="text" className="form-control mt-2" placeholder='Jobrole' value={formdata.jobrole} onChange={HandleChange} name="jobrole"/>
              {/* <ReactSelect placeholder="Select Jobroles" value={formdata.jobrole} options={jobroles} onChange={HandleSelectChange} name='jobrole' /> */}
              {errors.jobrole && <span className='text-danger'>{errors.jobrole}</span>}

              <input type="text" className="form-control mt-2" placeholder='Company Name' onChange={HandleChange} value={formdata.companyname} name='companyname' />
              {errors.companyname && <span className='text-danger'>{errors.companyname}</span>}

              <div className="row">
                <div className="col-lg-6 mt-3 d-flex flex-column">
                  <label className='me-2'>Start Date</label>
                  {/* <MyMonthYearPicker/> */}
                  <DatePicker selected={
                    !formdata.startDate ? new Date() : formdata.startDate
                  }
                  showMonthYearPicker                  // Enables month and year picker
                  showYearDropdown             // Enables the year dropdown
                  scrollableYearDropdown       // Makes the year dropdown scrollable
                  yearDropdownItemNumber={15}  // Number of years to show in the dropdown
                    //showMonthYearPicker
                    //dateFormatCalendar="MMMM"
                    dropdownMode="select"
                    className='form-control' dateFormat="MMMM yyyy"
                    name='startDate'
                    placeholderText='Start Year' onChange={(date) => {
                      setformdata({
                        ...formdata,
                        startDate: date
                      })
                    }}
                    maxDate={new Date()} customInput={<ExampleCustomInput />} />
                </div>
                {
                  formdata.is_working ? '' : <div className="col-lg-6 mt-3 d-flex flex-column">
                    <label className='me-2'>end Date</label>
                    <ReactDatePicker selected={
                      !formdata.endDate ? new Date() : formdata.endDate
                    }
                      showMonthYearPicker
                      className='form-control' dateFormat="MMMM yyyy"
                      name='endDate'
                      placeholderText='Start Year' onChange={(date) => {
                        setformdata({
                          ...formdata,
                          endDate: date
                        })
                      }}
                      maxDate={new Date()} customInput={<ExampleCustomInput />} />
                  </div>
                }
                {
                  errors.date && <span className='text-danger' >{errors.date}</span>
                }
              </div>


              <input className='mt-5' checked={formdata.is_working ? true : false} name='is_working' type="checkbox" onChange={onisworkingclick} /> Currently Working in {formdata.companyname}

            </div>

          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddWorkExperience