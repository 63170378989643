import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { admincompanies_service } from '../../ApiServices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Comp_Company_Profile_View} from '../../web/ash_dialogue/comp_company_profile_view';
import {Comp_Create_Company_Admin} from '../../web/ash_dialogue/comp_create_company_admin';
import {getCondidatesView} from '../../ApiServices';

const AdminCompanies = () => {

  const [Companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  
  const [stCompanyItem,setStCompanyItem]=useState({});

  const setCompanyItem=(item)=>{
    setStCompanyItem(item);
  }
  const setLoginAsCompany=(item)=>{
    // alert(JSON.stringify(item));
    // return;
    localStorage.setItem('company_is_profile_completed',"true");
    localStorage.setItem('company_id',item.id);
    navigate('/company');
  }


  const navigate = useNavigate();

  const fetchcompany = () => {
    if (hasMore) {
      getCondidatesView([],"getCompanyOutstanding").then((res) => {
        const newCompanies = JSON.parse(res.data).companies;

        //const newCompanies = res.data.data;
        //alert(JSON.stringify(newCompanies));
        setCompanies(prevCompanies => [...prevCompanies, ...newCompanies]);
        setPage(prevPage => prevPage + 1);
        setHasMore(res.data.current_page < res.data.last_page);
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }

  useEffect(() => {
    fetchcompany();
  }, [])



  return (<>
    <div className="container-fluid">
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0 card-title">Companies</h5>
          <button data-bs-toggle="modal" data-bs-target="#divCompanyCreateAdmin" className="btn btn-danger float-right">Add New Company</button>
        </div>
        <div class="table-responsive">
          <InfiniteScroll
            dataLength={Companies.length}
            next={fetchcompany}
            hasMore={hasMore}
            loader={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0px' }}>
              <div style={{ width: '20px', height: '20px' }} class="loading-loader"></div>
            </div>}
          // endMessage={<p>No more candidates to show.</p>}
          >
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">mobile</th>
                  <th scope="col">Email</th>
                  <th scope="col">City</th>
                  <th scope="col">Jobs Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  Companies.map((v) => {
                    return (
                      <tr>
                        <td>
                          {v.companyname}
                        </td>
                        <td>
                          {v.mobile}
                        </td>
                        <td>
                          {v.email}
                        </td>
                        <td>
                          {v.city?.name}
                        </td>
                        <td>
                          {v.count_jobs}
                        </td>
                        <td class="text-end">
                        <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    <a class="dropdown-item"  onClick={()=>setCompanyItem(v)} data-bs-toggle="modal" data-bs-target="#divCompanyProfileView" href="javascript:void(0)">View Profile</a>
                    <a class="dropdown-item" onClick={()=>setLoginAsCompany(v)}  href="javascript:void(0)">Login As Company</a>

                    </div>
                  </div>
                          {/* <button onClick={()=>setCompanyItem(v)} data-bs-toggle="modal" data-bs-target="#divCompanyProfileView" class="btn btn-sm btn-neutral">View</button> */}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
    <Comp_Company_Profile_View companyItem={stCompanyItem}/>
    <Comp_Create_Company_Admin/>
  </>)
}

export default AdminCompanies