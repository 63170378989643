import React, { useRef, useState } from 'react'
import { uploadresume_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const Resume = ({getprofile}) => {

  const closedialogueRef = useRef();


  const [resume, setresume] = useState(null)
  const [error, seterror] = useState('')

  const canceldialogue = () => {
    setresume(null)
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (max size: 5MB)
      if (file.size > 5 * 1024 * 1024) {
        seterror('File size exceeds the maximum limit (5MB).');
      } else {
        seterror('');
        setresume(file)
      }
    } else {
      seterror('Please select a file.');
    }
  };

  const submit = () => {
    if (resume) {
      uploadresume_service(resume).then((data) => {
        toast('✅ Resume Updated Successfully');
        canceldialogue();
        getprofile();
      })
    }
  }

  return (
    <div class="modal fade" id="addResume" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Upload Resume
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <label htmlFor="resume" style={{ width: '100%' }}>
              <div className='res-btn'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
                    <path fill="#1F8268" d="M10.57 6.762H6.762v3.81a.764.764 0 0 1-.762.761.764.764 0 0 1-.762-.762v-3.81h-3.81A.764.764 0 0 1 .667 6c0-.42.343-.762.762-.762h3.81v-3.81c0-.419.342-.761.761-.761.42 0 .762.342.762.762v3.809h3.81c.419 0 .762.343.762.762s-.343.762-.762.762Z">
                    </path>
                  </svg>
                </div> {resume ? resume.name : 'Upload Resume'}</div>
            </label>
            <input onChange={handleFileChange} type="file" hidden name='resume' id='resume' accept=".pdf" />
            {error && <span className='text-danger'>{error}</span>}
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Upload</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume