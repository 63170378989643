import React from 'react'
import { Link } from 'react-router-dom'
import { public_url } from '../../ApiUrl';


export const Comp_CandidateSavedJobCard=(props)=>{
    return (
        <Link onClick={()=>props.onView(typeof props.candidate_id!=="undefined"?props.candidate_id:props.candidate.id)} to={"javascript:void(0)"} className="col-lg-12 col-sm-12" data-bs-toggle="modal" data-bs-target="#divStudentProfileView">
            <div className="card p-3 d-flex flex-column gap-1">
                {props.candidate.is_premium==="true"?<div class="ribbon-wrapper ribbon-lg"><div class="ribbon bg-success text-lg111">
                        premium
                      </div>
                      </div>:''}
                <div className="info d-flex align-items-center justify-content-between">
                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                        <div className="com-profile" style={{ width: '45px',height:"45px" }}>
                            
                       {props.candidate.profile_pic?<img src={public_url+props.candidate.profile_pic} style={{ width: '45px',height:"45px", borderRadius: '50%' }} alt="Ejobee" />:<img src="/assets/images/user_icon.png" style={{ width: '50px', borderRadius: '50%' }} alt="Ejobee" />}
                            
                        </div>
                        <div>
                            <p className='fw-bold mb-0'>{props.candidate.firstname} {props.candidate.lastname}</p>
                            {/* <p className='mb-0'>{props.candidate.gender}</p> */}
                        </div>
                    </div>
                    {/* <div className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000"><path d="M8.355 6.316a.985.985 0 0 0 0 1.515l4.714 4.17-4.714 4.17a.985.985 0 0 0 0 1.515c.474.419 1.24.419 1.713 0l5.577-4.933a.985.985 0 0 0 0-1.515l-5.577-4.933c-.461-.408-1.239-.408-1.713.01Z"></path></svg>
                    </div> */}
                </div>
                <div className='d-flex align-items-center gap-1'>
                {/* <p className='mb-0 col1'><i class="fa fa-book" aria-hidden="true"></i> {props.candidate.level_name}</p> */}
                <p className='mb-0 col1'><i class="fas fa-suitcase-rolling"></i> Saved for :<b>{props.candidate.jobrole_name}</b></p>
                </div>
                <div className='d-flex flex-column flex-md-row justify-content-left row gap-1'>
                
                {JSON.parse(props.candidate.skills).map((item,index)=>{
                    return<div className='card col-1 fitcontent' style={{ fontSize: '13px', padding: '0px 5px' }}>{item.label}</div>
                })}
                {/* {props.candidate.min_salary} - {props.candidate.max_salary} */}
                
                </div>
              
                <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99984 1.33337C5.05127 1.33337 2.6665 3.47684 2.6665 6.12707C2.6665 8.98275 6.03412 12.9204 7.42079 14.4202C7.72555 14.7489 8.28174 14.7489 8.5865 14.4202C9.96555 12.9204 13.3332 8.98275 13.3332 6.12707C13.3332 3.47684 10.9484 1.33337 7.99984 1.33337ZM7.99984 7.83911C6.94841 7.83911 6.09508 7.07212 6.09508 6.12707C6.09508 5.18203 6.94841 4.41504 7.99984 4.41504C9.05127 4.41504 9.9046 5.18203 9.9046 6.12707C9.9046 7.07212 9.05127 7.83911 7.99984 7.83911Z" fill="#000"></path></svg> */}
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p className='mb-0'>{props.candidate.city_name},{props.candidate.state_name},{props.candidate.country_name}</p>
                    <a  href={"javascript:void(0)"} onClick={()=>props.onView(props.candidate.id)} data-bs-toggle="modal" data-bs-target="#divStudentProfileView" class="ml-auto float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">View Profile</a>
                </div>
             
            </div>

        </Link>
    )
}

export const Comp_CandidateAppliedJobCard=(props)=>{
    return (
        <Link onClick={()=>props.onView(typeof props.candidate_id!=="undefined"?props.candidate_id:props.candidate.id)} to={"javascript:void(0)"} className="col-lg-12 col-sm-12" data-bs-toggle="modal" data-bs-target="#divStudentProfileView">
            <div className="card p-3 d-flex flex-column gap-1">
                {props.candidate.is_premium==="true"?<div class="ribbon-wrapper ribbon-lg"><div class="ribbon bg-success text-lg111">
                        premium
                      </div>
                      </div>:''}
                <div className="info d-flex align-items-center justify-content-between">
                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                        <div className="com-profile" style={{ width: '45px',height:"45px" }}>
                            
                       {props.candidate.profile_pic?<img src={public_url+props.candidate.profile_pic} style={{ width: '50px', height:"45px",borderRadius: '50%' }} alt="Ejobee" />:<img src="/assets/images/user_icon.png" style={{ width: '50px', borderRadius: '50%' }} alt="Ejobee" />}
                            
                        </div>
                        <div>
                            <p className='fw-bold mb-0'>{props.candidate.firstname} {props.candidate.lastname}</p>
                            {/* <p className='mb-0'>{props.candidate.gender}</p> */}
                        </div>
                    </div>
                    {/* <div className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000"><path d="M8.355 6.316a.985.985 0 0 0 0 1.515l4.714 4.17-4.714 4.17a.985.985 0 0 0 0 1.515c.474.419 1.24.419 1.713 0l5.577-4.933a.985.985 0 0 0 0-1.515l-5.577-4.933c-.461-.408-1.239-.408-1.713.01Z"></path></svg>
                    </div> */}
                </div>
                <div className='d-flex align-items-center gap-1'>
                {/* <p className='mb-0 col1'><i class="fa fa-book" aria-hidden="true"></i> {props.candidate.level_name}</p> */}
                <p className='mb-0 col1'><i class="fas fa-suitcase-rolling"></i> Applied for :<b>{props.candidate.jobrole_name}</b></p>
                </div>
                <div className='d-flex flex-column flex-md-row justify-content-left row gap-1'>
                
                {JSON.parse(props.candidate.skills).map((item,index)=>{
                    return<div className='card col-1 fitcontent' style={{ fontSize: '13px', padding: '0px 5px' }}>{item.label}</div>
                })}
                {/* {props.candidate.min_salary} - {props.candidate.max_salary} */}
                
                </div>
              
                <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99984 1.33337C5.05127 1.33337 2.6665 3.47684 2.6665 6.12707C2.6665 8.98275 6.03412 12.9204 7.42079 14.4202C7.72555 14.7489 8.28174 14.7489 8.5865 14.4202C9.96555 12.9204 13.3332 8.98275 13.3332 6.12707C13.3332 3.47684 10.9484 1.33337 7.99984 1.33337ZM7.99984 7.83911C6.94841 7.83911 6.09508 7.07212 6.09508 6.12707C6.09508 5.18203 6.94841 4.41504 7.99984 4.41504C9.05127 4.41504 9.9046 5.18203 9.9046 6.12707C9.9046 7.07212 9.05127 7.83911 7.99984 7.83911Z" fill="#000"></path></svg> */}
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p className='mb-0'>{props.candidate.city_name},{props.candidate.state_name},{props.candidate.country_name}</p>
                    <a  href={"javascript:void(0)"} onClick={()=>props.onView(props.candidate.id)} data-bs-toggle="modal" data-bs-target="#divStudentProfileView" class="ml-auto float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">View Profile</a>
                </div>
             
            </div>

        </Link>
    )
}

export const Comp_CandidateCard = (props) => {
    //alert(JSON.stringify(props.candidate.skills));
    
    return (
        <Link onClick={()=>props.onView(typeof props.candidate_id!=="undefined"?props.candidate_id:props.candidate.id)} to={"javascript:void(0)"} className="col-lg-12 col-sm-12" data-bs-toggle="modal" data-bs-target="#divStudentProfileView">
            <div className="card p-3 d-flex flex-column gap-1">
                {props.candidate.is_premium==="true"?<div class="ribbon-wrapper ribbon-lg"><div class="ribbon bg-success text-lg111">
                        premium
                      </div>
                      </div>:''}
                <div className="info d-flex align-items-center justify-content-between">
                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                        <div className="com-profile" style={{ width: '45px',height:"45px" }}>
                            
                       {props.candidate.profile_pic?<img src={public_url+props.candidate.profile_pic} style={{ width: '50px', height:"45px",borderRadius: '50%' }} alt="Ejobee" />:<img src="/assets/images/user_icon.png" style={{ width: '50px', borderRadius: '50%' }} alt="Ejobee" />}
                            
                        </div>
                        <div>
                            <p className='fw-bold mb-0'>{props.candidate.firstname} {props.candidate.lastname}</p>
                            <p className='mb-0'>{props.candidate.gender}</p>
                        </div>
                    </div>
                    {/* <div className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000"><path d="M8.355 6.316a.985.985 0 0 0 0 1.515l4.714 4.17-4.714 4.17a.985.985 0 0 0 0 1.515c.474.419 1.24.419 1.713 0l5.577-4.933a.985.985 0 0 0 0-1.515l-5.577-4.933c-.461-.408-1.239-.408-1.713.01Z"></path></svg>
                    </div> */}
                </div>
                <div className='d-flex align-items-center gap-1'>
                <p className='mb-0 col1'><i class="fa fa-book" aria-hidden="true"></i> {props.candidate.level_name}</p>
                <p className='mb-0 col1'>| <i class="fa fa-suitcase" aria-hidden="true"></i> {props.candidate.total_experience?"Experience:"+props.candidate.total_experience:''}</p>
                </div>
                <div className='d-flex flex-column row flex-md-row justify-content-left gap-1'>
                
                {JSON.parse(props.candidate.skills).map((item,index)=>{
                    return<div className='card col-1 fitcontent' style={{ fontSize: '13px', padding: '0px 5px' }}>{item.label}</div>
                })}
                {/* {props.candidate.min_salary} - {props.candidate.max_salary} */}
                
                </div>
              
                <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99984 1.33337C5.05127 1.33337 2.6665 3.47684 2.6665 6.12707C2.6665 8.98275 6.03412 12.9204 7.42079 14.4202C7.72555 14.7489 8.28174 14.7489 8.5865 14.4202C9.96555 12.9204 13.3332 8.98275 13.3332 6.12707C13.3332 3.47684 10.9484 1.33337 7.99984 1.33337ZM7.99984 7.83911C6.94841 7.83911 6.09508 7.07212 6.09508 6.12707C6.09508 5.18203 6.94841 4.41504 7.99984 4.41504C9.05127 4.41504 9.9046 5.18203 9.9046 6.12707C9.9046 7.07212 9.05127 7.83911 7.99984 7.83911Z" fill="#000"></path></svg> */}
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p className='mb-0'>{props.candidate.city_name},{props.candidate.state_name},{props.candidate.country_name}</p>
                    <a  href={"javascript:void(0)"} onClick={()=>props.onView(props.candidate.id)} data-bs-toggle="modal" data-bs-target="#divStudentProfileView" class="ml-auto float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">View Profile</a>
                </div>
               
              
               
                {/* <div className="d-flex gap-2">
                    {
                        props.candidate.work_types ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {props.candidate.work_types}
                        </div> : ''
                    }
                    {
                        props.candidate.jobtype_name ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {props.candidate.jobtype_name}
                        </div> : ''
                    }
                </div> */}
            </div>

        </Link>
    )
}

export default Comp_CandidateCard