import React, { useEffect, useState } from 'react'
import { companyjobsbyid_service, postinterviewerinformation_service } from '../../ApiServices'
import { toast } from 'react-toastify'

const InterviewInformation = ({ bkbtnclick, nxbtnclick, jobid }) => {

  const [formdata, setformdata] = useState({
    // step3
    recruitername: "",
    recruiterwhatsapp: "",
    recruiteremail: "",
    interviewtype: "",
    interviewaddress: "",

  })

  const [errors, seterrors] = useState({})

  useEffect(() => {
    if (jobid != null) {
      companyjobsbyid_service(jobid).then((res) => {
        if (res.data.jobs[0].interview_question === 1) {
          const j = res.data.jobs[0];
          setformdata({
            recruitername: j.recruiter_name,
            recruiterwhatsapp: j.recruiter_whatsapp,
            recruiteremail: j.recruiter_email,
            interviewtype: j.interview_type,
            interviewaddress: j.interview_address,
          })
        }
      }).catch((error) => {

      })
    }
  }, [jobid])

  const Handlechange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    });
    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }))

  }

  const validateform = () => {
    const newerrors = {}

    if (!formdata.recruitername) {
      newerrors.recruitername = "Recruiter Name is Required"
    }

    if (!formdata.recruiterwhatsapp) {
      newerrors.recruiterwhatsapp = "Recruiter Whatsapp is Required"
    }

    if (!formdata.recruiteremail) {
      newerrors.recruiteremail = "Recruiter Email Is Required"
    }

    if (!formdata.interviewtype) {
      newerrors.interviewtype = "Please Choose Interview Options"
    }

    if (formdata.interviewtype == "1") {
      if (!formdata.interviewaddress) {
        newerrors.interviewaddress = "Interview Address is Required"
      }
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const backbtnclick = () => {
    bkbtnclick();
  }

  const continuebtnclick = () => {
    if (validateform()) {
      postinterviewerinformation_service(jobid, formdata).then((res) => {
        nxbtnclick();
      }).catch((error) => {
        toast('❌' + error.message);
      })
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="card">
          <h5 className='mt-3 mb-0'>Recruiter Details </h5>

          <div className="row mt-3 mb-3">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                  Name <span className='text-danger'>*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ram Sharma"
                  id="recruitername"
                  value={formdata.recruitername}
                  name="recruitername"
                  onChange={Handlechange}
                />
                {errors.recruitername && <span className='text-danger'>{errors.recruitername}</span>}
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                   Whatsapp No. <span className='text-danger'>*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="9898777700"
                  id="recruiterwhatsapp"
                  value={formdata.recruiterwhatsapp}
                  name="recruiterwhatsapp"
                  onChange={Handlechange}
                />
                {errors.recruiterwhatsapp && <span className='text-danger'>{errors.recruiterwhatsapp}</span>}
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                   Email ID <span className='text-danger'>*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ramsharma@gmail.com"
                  id="recruiteremail"
                  value={formdata.recruiteremail}
                  name="recruiteremail"
                  onChange={Handlechange}
                />
                {errors.recruiteremail && <span className='text-danger'>{errors.recruiteremail}</span>}

              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="row mt-3">
        <div className="card">
          <h5 className='mt-3 mb-0'>Interview Type</h5>
          {/* <p>Fill The Interview Information</p> */}
          <div className="row mt-3 mb-2">
            <div className="col-lg-12">
              <div class="form-group">
                <label for="first_name" class="label">
                  Type Of Interview
                </label>
                <div className='d-flex gap-2'>

                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="1"
                      checked={formdata.interviewtype==1}
                      name="interviewtype"
                      onChange={Handlechange}
                    />
                    Personal Interview
                  </label>

                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="2"
                      checked={formdata.interviewtype==2}
                      name="interviewtype"
                      onChange={Handlechange}
                    />
                    Teleponic Online Interview
                  </label>
                </div>
                {errors.interviewtype && <span className='text-danger'>{errors.interviewtype}</span>}
              </div>
            </div>
          </div>

          {
            formdata.interviewtype == "1" ?
              <div className="row mt-3 mb-3">
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="first_name" class="label">
                      Interview Address <span className='text-danger'>*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Interview Address"
                      id="interviewaddress"
                      value={formdata.interviewaddress}
                      name="interviewaddress"
                      onChange={Handlechange}
                    />
                    {errors.interviewaddress && <span className='text-danger'>{errors.interviewaddress}</span>}
                  </div>
                </div>
              </div> : ""
          }

        </div>

        <div className="row mt-5 mb-5">
          <div className="col-lg-12 text-center">
            <div onClick={backbtnclick} className="btn btn-primary me-2">Back</div>
            <div onClick={continuebtnclick} className="btn btn-primary">Next</div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default InterviewInformation