import React from 'react'
import { Navigate } from 'react-router-dom';

const AdminProtection = ({children}) => {
  const isAuthenticated = localStorage.getItem('admin-token');
  if (isAuthenticated) {
    return children;
  }
  return (
    <Navigate to="/admin/login" />
  );
}

export default AdminProtection