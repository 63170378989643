import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { companygetalljob_service } from '../../ApiServices';
import { Helmet } from 'react-helmet';

const CompanyAllJobs = () => {

    //alert("i m in all jobs");
    const navigate = useNavigate();
    const [jobs, setjobs] = useState([]);

    const postjobclick = () => {
        navigate('/company/post-job')
    }

    useEffect(() => {
        companygetalljob_service(localStorage.getItem('company_id')).then((res) => {
            setjobs(res.data.jobs)
        }).catch((error) => {

        })
    }, []);

    const formatDate = (timestamp, locale = 'en-GB') => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(locale, options);
    };

    return (
        <>
            <Helmet>
                <title>All Posted Jobs | EJOBEE</title>
                <meta name="description" content="All Jobs That Company Have Posted For Candidate." />
            </Helmet>
            <section style={{ padding: '10px 10px' }}>
                <div className="container">
                    <div className="section-tittle row justify-content-between">
                        <div style={{ width: 'fit-content' }}>
                            <h2>All Jobs ({jobs.length}) </h2>
                        </div>
                        <div style={{ width: 'fit-content' }}>
                            <div onClick={postjobclick} className="btn btn-primary">Post Job</div>
                        </div>
                    </div>
                    <div className="row mt-5 mb-5">
                        {
                            jobs.length == 0 ?
                                <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h2>Don't Have Any Posted Job</h2>
                                </div> :
                                <div className="col-lg-12 mt-2 d-flex flex-column" style={{ rowGap: '10px' }}>
                                    {
                                        jobs.map((v) => {
                                            return (
                                                <div className="card job-card">
                                                    <div className="container pt-2 pb-2">
                                                        <div className="row g-3">
                                                            <div className="col-lg-6">
                                                                <h4>{v.jobrole_name}</h4>
                                                                <p> {v.office_address}, {v.city_name} | posted on: {formatDate(v.created_at)} | {v.company.firstname + " " + v.company.lastname}</p>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <h4>Status</h4>
                                                                <p>{v.job_status}</p>
                                                            </div>
                                                            {
                                                                v.job_detail && v.candidate_requirement && v.interview_question && v.job_status==="published" ?
                                                                    <>
                                                                        {
                                                                            !v.job_preview ? <div className="col-lg-2">
                                                                                <div onClick={()=>navigate('/company/post-job/'+v.id)} className="btn btn-primary">preview Job</div>
                                                                            </div> :
                                                                                <>
                                                                                    {
                                                                                        !v.select_plan ? <div className="col-lg-2">
                                                                                            <div onClick={()=>navigate('/company/post-job/'+v.id)} className="btn btn-primary">Select Plan</div>
                                                                                        </div> : <div className="col-lg-2">
                                                                                            <div onClick={()=>navigate('/company/applied-candidate')} className="btn btn-primary">view Applicant</div>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                    : <div className="col-lg-2">
                                                                        <div onClick={()=>navigate('/company/post-job/'+v.id)} className="btn btn-primary">Complete Post</div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompanyAllJobs