import React, { useRef, useState } from 'react'
import { addjobplan_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddJobPlan = ({getobplan}) => {

    const closedialogueRef = useRef();

    const [formData, setformData] = useState({
        title: '',
        price: '',
        validity: '',
        description: ''
    })

    const [errors, seterrors] = useState({})


    const HandleChange = (e) => {
        const { name, value } = e.target
        setformData({
            ...formData,
            [name]: value
        })

        seterrors((preverror) => ({
            ...preverror,
            [name]: ''
        }))
    }


    const canceldialogue = () => {
        setformData({
            title: '',
            price: '',
            validity: '',
            description: ''
        })
        seterrors({});
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }


    const validateform = () => {
        const newerrors = {}

        if (!formData.title) {
            newerrors.title = "Title is Required";
        }

        if (!formData.price) {
            newerrors.price = "Price is Required";
        }

        if (!formData.validity) {
            newerrors.validity = "Validity is Required";
        }

        if (!formData.description) {
            newerrors.description = "description is Required";
        }

        seterrors(newerrors);
        return Object.keys(newerrors).length === 0;
    }

    const submit = () => {
        if (validateform()) {
            addjobplan_service(formData).then((res) => {
                toast('✅ Job Plan Added Successfully');
                getobplan();
                canceldialogue();
            }).catch((err) => {

            })
        }
    }

    return (
        <div class="modal fade" id="addjobplan" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Add Job Plans
                        <button ref={closedialogueRef} onClick={canceldialogue} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <input type="text" className="form-control" name='title' placeholder='title' onChange={HandleChange} />
                            {errors.title && <span className="text-danger">{errors.title}</span>}
                            <input type="number" className="form-control mt-2" placeholder='price' name='price' onChange={HandleChange} />
                            {errors.price && <span className="text-danger">{errors.price}</span>}

                            <input type="number" className="form-control mt-2" placeholder='validity' name='validity' onChange={HandleChange} />
                            {errors.validity && <span className="text-danger">{errors.validity}</span>}

                            <textarea className='form-control mt-2 p-2' rows={10} name='description' onChange={HandleChange} placeholder='Description' />
                            {errors.description && <span className="text-danger">{errors.description}</span>}

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button onClick={submit} type="button" class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAddJobPlan