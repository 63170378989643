import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import { blogpostbyid_service, edit_blog_post_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { public_url } from '../../ApiUrl';

const AdminEditBlog = ({ getblogpost, blogid }) => {

    const closedialogueRef = useRef();

    const navigate = useNavigate();

    const [formdata, setformdata] = useState({
        title: '',
        content: '',
        image: ''
    })

    const [errors, seterrors] = useState({});
    const [file, setfile] = useState(null);


    const validateform = () => {
        const newerror = {}

        if (!formdata.title) {
            newerror.title = "Title is Required"
        }

        if (!formdata.content) {
            newerror.content = "Content is Required"
        }

        // if (!file) {
        //     newerror.image = "Image is Required"
        // }

        seterrors(newerror);
        return Object.keys(newerror).length === 0;
    }


    useEffect(() => {
        if (blogid != null) {
            blogpostbyid_service(blogid).then((res) => {
                console.log(res.data);
                setformdata({
                    title:res.data[0].title,
                    content:res.data[0].content,
                    image:res.data[0].image
                })
            }).catch((err) => {

            })
        }
    }, [blogid])


    const clearstate = () => {
        setformdata({
            title: '',
            content: '',
            image:''
        })
        seterrors({});
    }

    const changehandle = (e) => {
        const { name, value } = e.target;
        setformdata({
            ...formdata,
            [name]: value
        })
    }

    const onchangeeditor = (editor) => {
        setformdata({
            ...formdata,
            content: editor
        })
    }

    const filehandler = (e) => {
        const file = e.target.files[0];
        setfile(file)
        // if (file) {
        //   if (file.type === 'image/jpeg' || file.type === 'image/png') {
        //     setformdata({
        //       ...formdata,
        //       image: file
        //     })
        //   } else {
        //     seterrors({
        //       ...errors,
        //       image: "Please select a valid PNG or JPG file."
        //     })
        //     e.target.value = null;
        //   }
        // }
    }

    const canceldialogue = () => {
        setformdata({
            title: '',
            content: '',
            image:''
        })
        seterrors({});
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const updateblogpost = () => {
        if (validateform()) {
            const formdata2 = new FormData();
            formdata2.append('title', formdata.title);
            formdata2.append('content', formdata.content);
            formdata2.append('image', file)
            edit_blog_post_service(blogid,formdata).then((res) => {
                toast('✅ Blog Update Successfully');
                canceldialogue();
                getblogpost();
            }).catch((error) => {
                toast('❌' + error.message);
                if (error.message === 'Unauthorized: Please log in.') {
                    localStorage.clear();
                    navigate('/')
                    window.location.reload();
                }
            })
        }
    }

    return (
        <div class="modal fade" id="editblog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Edit Blog
                        <button type="button" ref={closedialogueRef} onClick={clearstate} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style={{ overflowY: 'scroll' }}>

                        <div className="form-group">
                            <input type="text" className="form-control mt-3" placeholder='Blog Title' name='title' value={formdata.title} onChange={changehandle} />
                            {errors.title && <span className='text-danger'>{errors.title}</span>}
                        </div>

                        <div className="form-group d-flex flex-column">
                            <input type="file" className='mt-3' accept=".png, .jpg, .jpeg" name='image' onChange={filehandler} />
                            {errors.image && <span className='text-danger'>{errors.image}</span>}
                        </div>

                        {
                            formdata.image ?
                            <img src={public_url+formdata.image} alt="" /> : ""
                        }

                        <div className='mt-3' style={{ height: '200px' }}>
                            <ReactQuill value={formdata.content} onChange={onchangeeditor} placeholder='Blog Content' style={{ height: '250px' }} />
                        </div>
                        {errors.content && <span className='text-danger'>{errors.content}</span>}

                    </div>
                    <div class="modal-footer mt-2">
                        {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button> */}
                        <button onClick={updateblogpost} type="button" class="btn btn-primary" style={{ borderRadius: '0' }}>update Blog</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminEditBlog