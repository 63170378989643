import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { education_level_service, update_education_level } from '../../ApiServices';
import { toast } from 'react-toastify';
import { public_url,ash_bublic_url } from '../../ApiUrl';
import {getCondidatesView} from '../../ApiServices';
import {setResumeTemplateData} from '../../functional_lib/common_lib';
import Comp_Video_Player from './comp_video_player';

import './comp_student_profile_view.css';


export const Comp_Student_Profile_View=(props)=>{
  const navigate = useNavigate();

  const [getCandidateProfile,setCandidateProfile]=useState({});
     
  //alert(props.candidateId);
  const fetchProfileRecords = () => {
      
      let map={cand_id:btoa(props.candidateId)};
     getCondidatesView(map,"getCandidateProfView").then((res) => {
          //alert(JSON.stringify(res));
          const newCandidates = JSON.parse(res.data);

         setCandidateProfile(setResumeTemplateData(newCandidates));
        }).catch((error) => {
          toast('❌' + error.message);
          if (error.message === 'Unauthorized: Please log in.') {
            localStorage.clear();
            navigate('/')
            window.location.reload();
          }
        })
      
    }
  
      useEffect(() => {
          if(props.candidateId!==null){
              fetchProfileRecords()
          }
      }, [props.candidateId])
  
  return(<div>
       <div class="modal fade" id="divStudentProfileView" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-scrollable">
           <div class="modal-content">
             <div class="modal-header">
               <h1 class="modal-title fs-5 " id="exampleModalLabel">Candidate Profile</h1>
     
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             </div>

             <div class="modal-body">
             {typeof getCandidateProfile.basic!=="undefined"?<>
               <div class="row top-row ">
               <div class="col-md-4">
              
                <div class="card card-primary card-outline">
                
                     
                  <div class="card-body box-profile position-relative">
                    {getCandidateProfile.basic.is_premium==="true"?<div class="ribbon-wrapper ribbon-lg"><div class="ribbon bg-success text-lg111">
                      premium
                      </div>
                      </div>:''}
                   
                  <div class="text-center">
                  {getCandidateProfile.basic.profile_pic?<img src={public_url+getCandidateProfile.basic.profile_pic} class="profile-user-img img-fluid img-circle" alt="..."/>:''}
                  </div>
                  <div class="profile-username text-center">
                 <h3 class="profile-username text-center"> {getCandidateProfile.basic.first_name} {getCandidateProfile.basic.last_name}</h3>
                 <p class="text-muted text-center">
                 {getCandidateProfile.contact.email}
                 </p>
                 <p class="text-muted text-center">
                 {getCandidateProfile.contact.mobile}
                 </p>
                  </div>
                  <hr/>
                    <strong>Intro Video of {getCandidateProfile.basic.first_name+" "+getCandidateProfile.basic.last_name}</strong>
                    {getCandidateProfile.basic.intro_video?<Comp_Video_Player video_url={ash_bublic_url+"upd_intro_video/"+getCandidateProfile.basic.intro_video} />:<><br/>'No Video Uploaded'</>}

              <hr/>
              {/* <strong>Remsume</strong> */}
              <p class="text-muted">
                {getCandidateProfile.basic.resume?<a class="btn btn-primary" target={"blank"} href={public_url+getCandidateProfile.basic.resume}>View Resume</a>:'No Resume Updated'}
              </p>
                  </div>

                  <div class="card-body">
                    <strong>Address</strong>
                    <p class="text-muted">
                    {getCandidateProfile.contact.address}
                    </p>
                    <hr/>
                    <strong>Skills</strong>
                    <p class="text-muted">
                    {getCandidateProfile.skills.map((item,index)=>{
                        return <li>{item}</li>
                    })} 
                    </p>
                    <hr/>
                    <strong>Language Known</strong>
                    <p class="text-muted">
                    {getCandidateProfile.language.map((item,index)=>{
            
            return <span class="phone-val">
              {" "+item}{(index<getCandidateProfile.language.length-1)?",":''}
            </span>
          })}
                    </p>
                   
                  </div>

                </div>

{/* second columns */}



                </div>
                 <div class="col-12 col-md-8 leftsection">
                  
                     <div class="card card-primary card-outline" >
                        <div class="card-body box-profile">
                        <h3 class="profile-username text-center">Education</h3>
                          <p class="text-muted">
                          {getCandidateProfile.education.map((item,index)=>{
                      return <>
                      <p><b>{item["degree"]}</b> <br/>{item["university"]}</p>
                      <p>Duration: {item["duration"]}</p>
                      <hr/>
                      </>
                    })}
                          </p>
                        </div>  
                      </div>



                      <div class="card card-primary card-outline" >
                        <div class="card-body box-profile">
                        <h3 class="profile-username text-center">Experience</h3>
                        <p class="text-muted text-center"><b>{getCandidateProfile.years_exp} Years</b></p>
                          <p class="text-muted">
                          
                    {getCandidateProfile.experience.map((item,index)=>{
                      return <>
                      <strong>{item["company_name"]}</strong>
                      <p> {item["jobrole_name"]}</p>
                      <p>Duration: {item["duration"]}</p>
                      <hr/>
                      </>
                    })}
                          </p>
                        </div>  
                      </div>
                     
                 </div>
                
                 
                 
                </div>
     
               </> :''}
             </div>
             
             <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
             </div>
             
           </div>
           
         </div>
         
       </div>
    </div>)
}

export const Comp_Student_Profile_View_Old = (props) => {

    const navigate = useNavigate();

const [getCandidateProfile,setCandidateProfile]=useState({});
   
//alert(props.candidateId);
const fetchProfileRecords = () => {
    
    let map={cand_id:btoa(props.candidateId)};
   getCondidatesView(map,"getCandidateProfView").then((res) => {
        //alert(JSON.stringify(res));
        const newCandidates = JSON.parse(res.data);
       setCandidateProfile(newCandidates);
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    
  }

    useEffect(() => {
        if(props.candidateId!==null){
            fetchProfileRecords()
        }
    }, [props.candidateId])



    return (
        <div class="modal fade" id="divStudentProfileView_old" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">

              <h1 class="modal-title fs-5 white" id="exampleModalLabel">Candidate Profile</h1>
    
              <button type="button" class="btn-close white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                {/* {JSON.stringify(getCandidateProfile)} */}
            {typeof getCandidateProfile.candidate_master!=="undefined"?<>
              <div class="row">
                <div class="col-12 col-md-6 leftsection">
                  
                    <div class="profilediv">
                       <div class="card mb-3" style={{"max-width":"540px"}}>
                           <div class="row g-0">
                             <div class="col-md-4">
                               <img src="profile.jpg" class="img-fluid rounded-start" alt="..." />
                             </div>
                             <div class="col-md-8">
                               <div class="card-body">
                                 <h5 class="card-title11">{getCandidateProfile.candidate_master[0]["firstname"]} {getCandidateProfile.candidate_master[0]["lastname"]}</h5>
                                 <p class="card-text">
                                   <i class="fa fa-briefcase"></i> {getCandidateProfile.candidate_master[0]["total_experience"]} <br/>
                                   <i class="fa fa-user"></i>{getCandidateProfile.candidate_master[0]["is_premium"]} <br/>
                                   <i class="fa fa-envelope"></i> {getCandidateProfile.candidate_master[0]["email"]} <br/>
                                   <i class="fa fa-phone"></i> {getCandidateProfile.candidate_master[0]["mobile"]} <br/>
                                   <i class="fa fa-location-arrow"></i> {getCandidateProfile.candidate_master[0]["city_name"]} <br/>
                                   <i class="fa fa-calendar" aria-hidden="true"></i> {getCandidateProfile.candidate_master[0]["created_at"]} <br/>
                                   <i class="fa fa-male" aria-hidden="true"></i> {getCandidateProfile.candidate_master[0]["gender"]}
                                 </p>
                                 
                               </div>
                             </div>
                           </div>
                         </div>
                    </div>
                    
                </div>
                <div class="col-12 col-md-6 rightsection">
                    <div class="introvid text-center mt-2">
                       <video width="350" controls>
                           <source src="vid.mp4" type="video/mp4" />
                         </video>
                    </div>
                </div>
                
               </div> 

               <div class="candidateeducation">
                   <h4>Education</h4>
                   <ul>
                    {getCandidateProfile.candidate_edu.map((item,index)=>{
                        return(
                            <li>{"Degree: "+item["degree_name"]},{"University: "+item["university_name"]}
                            {"Duration: "+item["start"]+" -to- "+item["end"]}
                            </li>
                        // <li>Bachelor of Technology (B.Tech) in Computer Science Indian Institute of Technology (IIT) Delhi | August 2009 – May 2013</li>
                        )
                    })}
                   </ul>
               </div>
               
               <div class="candidateexperience">
                   <h4>Experience</h4>
                   <ul>
                    {getCandidateProfile.candidate_expe.map((item,index)=>{
                        return (
                            <li>{"Company: "+item["company_name"]} ,
                            {"Job Role: "+item["jobrole_name"]},
                            {"Start: "+item["start"]},
                            {"End :"}{item["end"]!==null?item["end"]:'Continue'},
                            
                            </li>
                        )
                    })}
                       {/* <li>Software Engineer - Innovative Tech, Jaipur, Rajasthan | July 2013 – February 2018</li>
                       <li>Intern - Innovative Tech, Jaipur, Rajasthan | July 2012 – February 2013 </li> */}
                   </ul>
               </div>
   
               <div class="candidateskill">
                   <h4>Skills</h4>
                   {/* {getCandidateProfile.candidate_master[0]["skills"]} */}
                   <ul>
                    
                     {JSON.parse(getCandidateProfile.candidate_master[0]["skills"]).map((item,index)=>{
                        return <li>{item["label"]}</li>
                    })} 
                       {/* <li>HTML</li>
                       <li>C++</li>
                       <li>Java</li> */}
                   </ul>
               </div>
   
              
               <div class="candidatelanguage">
                   <h4>Language</h4>
                   <ul>
                       <li>English (Fluent)</li>
                       <li>Hindi (Fluent)</li>
                       <li>Marathi (Basic)</li>
                   </ul>
               </div></>:''}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Comp_Student_Profile_View