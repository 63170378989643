import './comp_ejobee_categories.css';
import { Link, useNavigate } from 'react-router-dom';

export const Comp_Ejobee_Categories=(props)=>{
      return(
        <div data-aos111="fade-up" class="container">
        <div class="services pb-5">
            <div class="container">
              <div class="pt-5">
                <h2 class="vc_custom_heading ico_header">Popular Job Categories</h2>
                <hr/>
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box it" style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>IT</h2>
                      <p><i class="fa fa-laptop-code pt-3"></i></p>
                      <div class="flip-view">
                        <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">IT </span><i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box banking" style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>Banking</h2>
                      <p><i class="fa fa-university pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">Banking</span> <i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box accounting " style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>Accounting</h2>
                      <p><i class="fa fa-calculator pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">Accounting </span><i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box graphic" style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>Graphic Design</h2>
                      <p><i class="fa fa-palette pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">Graphic Design </span><i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box customer" style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>Customer Support</h2>
                      <p><i class="fa fa-headset pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">Customer Support </span><i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box digital" style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>Video Editor</h2>
                      <p><i class="fa fa-video pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">Video Editor </span> <i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box hr" style11={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>Work from Home</h2>
                      <p><i class="fa fa-home pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">Work from Home </span><i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="investor-box alljob"  style111={{ backgroundImage: `url(/assets/images/74.png)`,opacity: 1 }}>
                      <h2>View All Jobs</h2>
                      <p><i class="fa fa-search pt-3"></i></p>
                      <div class="flip-view">
                      <Link class={"d-flex align-items-center"} to={"/jobs"}><span class="search-job-text pe-3">View All Jobs </span><i class="fa fa-chevron-circle-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
    
    );
}
export default Comp_Ejobee_Categories;