import React from 'react'

const PageError = () => {
    return (
        <section>
            <div className="container">
                <div className="col-lg-12 d-flex flex-column align-items-center justify-content-between">
                    <div style={{ width: '300px' }}>
                        <img className='w-100' src="/assets/images/imghero.svg" alt="" />
                    </div>
                    <h2>Page Not Found</h2>
                </div>
            </div>
        </section>
    )
}

export default PageError