import React, { useEffect, useState } from 'react'
import AdminAddSkills from '../dialogue/AdminAddSkills'
import { alljobrole_service, allskills_service } from '../../ApiServices';

const AdminSkills = () => {

    const [skills, setskills] = useState([]);
    const [jobroles, setjobroles] = useState([]);

    useEffect(() => {
        getjobroles();
        getskills();
    }, [])

    const getskills = () => {
        allskills_service().then((data)=>{
            setskills(data.data.data)
            console.log(data.data.data);
        }).catch((error)=>{})
    }

    const getjobroles = () => {
        alljobrole_service().then((data) => {
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }))
            setjobroles(mappedoptions)
        }).catch((error) => {

        })
    }


    return (
        <div className="container-fluid">
            <AdminAddSkills jobroles={jobroles} getskills={getskills} />
            <div class="card shadow border-0 mb-7">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h5 class="mb-0">Skills</h5>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#addSkills" class="btn btn-sm btn-neutral text-danger-hover">
                        <i class="bi bi-plus"></i>
                        Add
                    </button>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Job Role</th>
                                <th scope="col">Skills</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                skills.map((v) => {
                                    return (
                                        <tr>
                                            <td>
                                                {v.jobrole.name}
                                            </td>
                                            <td>
                                                {v.name}
                                            </td>
                                            <td class="text-end">
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#addstate" class="btn btn-sm btn-neutral text-danger-hover">
                                                    Edit
                                                </button>
                                                {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="bi bi-trash"></i>
                                                </button> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* <div class="card-footer border-0 py-5">
                    <span class="text-muted text-sm">Showing 10 items out of 250 results found</span>
                </div> */}
            </div>
        </div>
    )
}

export default AdminSkills