import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { update_english_level } from '../../ApiServices';
import {LanguageList} from '../../functional_lib/common_lib';

const EditEnglishLevel = ({ edienglishlevel, getprofile }) => {

    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        englishlevel: { value: edienglishlevel, label: edienglishlevel }
    });

    const [englishlevel, setenglishlevel] = useState([]);

    const [errors, seterrors] = useState({})

    useEffect(() => {
        setenglishlevel(LanguageList())
    }, [])


    const HandleSelectChange = (value, action) => {
        setformdata({
            ...formdata,
            [action.name]: value
        })
    }

    const validateform = () => {
        const newerrors = {}

        if (!formdata.englishlevel) {
            newerrors.englishlevel = "Please Select English Level"
        }

        seterrors(newerrors);
        return Object.keys(newerrors).length === 0;
    }

    const canceldialogue = () => {
        seterrors({})
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const submit = () => {
        if (validateform()) {
            update_english_level(formdata).then((res) => {
                toast('✅ English Level Successfully');
                getprofile()
                canceldialogue()
            }).catch((err) => {

            })
        }
    }

    return (
        <div class="modal fade" id="editenglishlevel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Edit English Level
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <ReactSelect options={englishlevel} placeholder="Select English Level" value={formdata.englishlevel} onChange={HandleSelectChange} name='englishlevel' />
                            {errors.englishlevel && <span className='text-danger'>{errors.educationlevel}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditEnglishLevel