import React from 'react'
import { Link } from 'react-router-dom'

const JobCard = ({ jobtitle, companyname, location, minsalary, maxsalary, companyprofilepic, slug, worktype, employementtype, applicationstatus }) => {
    return (
        <Link to={`/jobs/${slug}`} className="col-lg-4 col-sm-10">
            <div className="card p-3 d-flex flex-column gap-1">
                <div className="info d-flex align-items-center justify-content-between">
                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                        <div className="com-profile" style={{ width: '45px' }}>
                            {
                                companyprofilepic ? <img className='w-100 rounded-circle' src={companyprofilepic} alt="" /> : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
                                    <rect width="32" height="32" fill="#E8E7EA" rx="4"></rect>
                                    <path fill="#8C8594" d="M4 5.37C4 4.612 4.613 4 5.37 4h13.26c.757 0 1.37.613 1.37 1.37v22.493a.137.137 0 0 1-.137.137H4.137A.137.137 0 0 1 4 27.863V5.37Z"></path>
                                    <path fill="#E8E7EA" d="M13.5 21h-3a.5.5 0 0 0-.5.5V28h4v-6.5a.5.5 0 0 0-.5-.5Z"></path>
                                    <rect width="3" height="3" x="14" y="16" fill="#E8E7EA" rx="0.4"></rect>
                                    <rect width="3" height="3" x="7" y="16" fill="#E8E7EA" rx="0.4"></rect>
                                    <rect width="3" height="3" x="14" y="11" fill="#E8E7EA" rx="0.4"></rect>
                                    <rect width="3" height="3" x="7" y="11" fill="#E8E7EA" rx="0.4"></rect>
                                    <rect width="3" height="3" x="14" y="6" fill="#E8E7EA" rx="0.4"></rect>
                                    <rect width="3" height="3" x="7" y="6" fill="#E8E7EA" rx="0.4"></rect>
                                    <path fill="#D1CED4" d="M20 14.886c0-.49.396-.886.886-.886h6.228c.49 0 .886.396.886.886V27.91c0 .05-.04.089-.089.089H20.09a.089.089 0 0 1-.089-.089V14.886Z"></path>
                                    <path fill="#fff" d="M22.5 17.4c0-.22.18-.4.4-.4h2.2c.22 0 .4.18.4.4v2.2a.4.4 0 0 1-.4.4h-2.2a.4.4 0 0 1-.4-.4v-2.2ZM22.5 22.4c0-.22.18-.4.4-.4h2.2c.22 0 .4.18.4.4v2.2a.4.4 0 0 1-.4.4h-2.2a.4.4 0 0 1-.4-.4v-2.2Z"></path>
                                </svg>
                            }
                        </div>
                        <div>
                            <p className='fw-bold mb-0'>{jobtitle}</p>
                            <p className='mb-0'>{companyname}</p>
                        </div>
                    </div>
                    <div className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000"><path d="M8.355 6.316a.985.985 0 0 0 0 1.515l4.714 4.17-4.714 4.17a.985.985 0 0 0 0 1.515c.474.419 1.24.419 1.713 0l5.577-4.933a.985.985 0 0 0 0-1.515l-5.577-4.933c-.461-.408-1.239-.408-1.713.01Z"></path></svg>
                    </div>
                </div>
                {
                    applicationstatus ? <div style={{ fontSize: '13px', padding: '0px 5px',fontWeight:'bold',margin:'10px 0px'}}>
                        Application Status : {applicationstatus}
                    </div> : ''
                }
                <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99984 1.33337C5.05127 1.33337 2.6665 3.47684 2.6665 6.12707C2.6665 8.98275 6.03412 12.9204 7.42079 14.4202C7.72555 14.7489 8.28174 14.7489 8.5865 14.4202C9.96555 12.9204 13.3332 8.98275 13.3332 6.12707C13.3332 3.47684 10.9484 1.33337 7.99984 1.33337ZM7.99984 7.83911C6.94841 7.83911 6.09508 7.07212 6.09508 6.12707C6.09508 5.18203 6.94841 4.41504 7.99984 4.41504C9.05127 4.41504 9.9046 5.18203 9.9046 6.12707C9.9046 7.07212 9.05127 7.83911 7.99984 7.83911Z" fill="#000"></path></svg>
                    </div>
                    <p className='mb-0'>{location}</p>
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><g clip-path="url(#a)"><path fill="#000" d="M12.8 5V3.8c0-.662-.538-1.2-1.2-1.2H3.8c-.992 0-1.8.808-1.8 1.8v7.2c0 1.32 1.076 1.8 1.8 1.8h9c.662 0 1.2-.538 1.2-1.2v-6c0-.662-.538-1.2-1.2-1.2Zm-1.2 5.4h-1.2V8h1.2v2.4ZM3.8 5a.6.6 0 0 1 0-1.2h7.8V5H3.8Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M2 2h12v12H2z"></path></clipPath></defs></svg>
                    </div>
                    <p className='mb-0'>{minsalary} - {maxsalary}</p>
                </div>
                <div className="d-flex gap-2">
                    {
                        worktype ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {worktype}
                        </div> : ''
                    }
                    {
                        employementtype ? <div className='card' style={{ fontSize: '13px', padding: '0px 5px' }}>
                            {employementtype}
                        </div> : ''
                    }
                </div>
            </div>

        </Link>
    )
}

export default JobCard