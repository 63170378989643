import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ourVisionImg from '../../c_assets/about_us/our_vision_about_us.jpg';
import our_mission_about_us from '../../c_assets/about_us/our_mission_about_us.jpg';
import our_commitment_abt_us from '../../c_assets/about_us/our_commitment_abt_us.jpg';
import aboutUsImg from '../../c_assets/home/about_us_.png';


const About = () => {
    return (
        <>
            <Helmet>
                <title>
                    About eJobee | EJOBEE
                </title>
                <meta name="description" content="Discover the story behind eJobee - the leading job platform dedicated to helping professionals find their ideal career paths. Learn how eJobee connects job seekers with top employers and fosters career growth." />
            </Helmet>
            <section style={{ background: '#f5f5f5' }} className='pb-0'>
                <div className="container">
                    <div  className="row reverse">
                        <div className="col-lg-6 mt-auto">
                            <img className='w-100 img-thumb' src={aboutUsImg} alt="EJOBEE" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h11-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>About Us</h4>
                                </div>
                                <p>
                                Welcome to Ejobee, a comprehensive job portal designed to serve the needs of <b>Both Job Seekers & Employers</b>. Whether you're looking to advance your career or seeking the perfect candidate, Ejobee offers a range of tools and features to make the process easier and more efficient. Ejobee, helps to empower job seekers and professionals at every stage of their career journey.  We believe that everyone deserves the opportunity to find their dream job and grow in their chosen field. That’s why we offer a range of services tailored to meet your needs, whether you’re just starting out or looking to take the next big step.
                                </p>
                                <p>
                                At Ejobee we bridge the gap between employers and job seekers by offering an innovative, cost-effective platform that streamlines the hiring process. Our job portal is designed to help companies find the right talent quickly and efficiently, all while keeping costs low.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div  className="row reverse">
                      
                        <div className="col-lg-12">
                            <div className="about-text d-flex flex-column justify-content-center h11-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>What We Offer For Candidate -</h4>
                                </div>
                                <p>
                                • <b>Free Resume Creation -</b> Craft a professional resume with ease using our user-friendly tools. We provide templates and guidance to help you showcase your skills and experience in the best possible light.
                                </p>
                                <p>
                                • <b>Intro Video Showcase for Premium Members -</b> Stand out from the crowd with an engaging introduction video. Our premium members have the exclusive opportunity to create and display a personal video profile, making a lasting impression on potential employers.
                                </p>
                                <p>
                                • <b>Diverse Job Opportunities -</b> Whether you’re unemployed, underemployed, or seeking a new challenge, Ejobee has a wide array of job listings across various industries. We are committed to connecting you with the right opportunities to help you build a successful career.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-lg-12 mt-10 mb-10">
                            <div className="about-text d-flex flex-column justify-content-center h11-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>What We Offer For Company -</h4>
                                </div>
                                <p>
                                • <b>Affordable Job Posting -</b> We provide companies with the opportunity to place job listings on our portal at a very low cost.
                                </p>
                                <p>
                                • <b>Advanced Candidate Search -</b> Save time and resources by searching for candidates through our unique intro video showcases and comprehensive resumes. This allows you to get a better sense of a candidate’s personality and suitability before scheduling interviews.
                                </p>
                                <p>
                                • <b>Efficient Interview Scheduling -</b> Reduce the time spent on initial screenings by leveraging our intro video feature. View candidate videos before making interview decisions, ensuring that you only meet with those who truly fit your requirements.
                                </p>
                                <p>
                                • <b>Diverse Talent Pool -</b> Ejobee hosts a vast array of candidates across all categories, making it easier than ever to find the perfect match for your company's needs.
                                </p>
                                <p class="mt-5">
                                Join Ejobee today and take the next step towards achieving your career goals. Whether you're looking for your first job or your next big career move, we're here to help you every step of the way and Company take advantage of our great facilities to connect with top talent and make smarter hiring decisions.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-0'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mt-auto">
                            <div className="about-text d-flex flex-column justify-content-center h11-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>Our Vision</h4>
                                </div>
                                <p>
                                Our vision for Ejobee is to become the leading platform that seamlessly connects job seekers and employers by providing innovative tools and resources. We aim to empower individuals with personalized career solutions and help businesses find top talent effortlessly. Through a user-centric approach and cutting-edge technology, we strive to enhance the job search experience and foster meaningful career opportunities for everyone.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-auto">
                            <img className='w-100 img-thumb' src={ourVisionImg} alt="EJOBEE" />
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ background: '#f5f5f5' }}>
                <div className="container">
                    <div className="row reverse">
                        <div className="col-lg-6 mt-auto">
                            <img className='w-100 img-thumb' src={our_mission_about_us} alt="EJOBEE" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>Our Mission</h4>
                                </div>
                                <p>
                                At Ejobee, our mission is to bridge the gap between candidates and companies by providing a versatile and supportive job portal. We are committed to offering exceptional benefits for both newcomers, fresher’s, and experienced professionals, as well as employers. By delivering personalized career resources, advanced matching technology, and comprehensive support, we aim to simplify the job search and recruitment processes. Our goal is to help candidates of all experience levels find their ideal opportunities while enabling companies to connect with diverse, top-quality talent, fostering growth and success for all involved.
                                </p>
                                <p>
                                At Ejobee, we are committed to delivering an exceptional job portal experience by prioritizing transparency, innovation, and user satisfaction. We pledge to continuously enhance our platform with cutting-edge technology and valuable resources to support both job seekers and employers. Our dedication extends to providing personalized assistance, fostering a diverse talent pool, and ensuring a seamless and efficient job matching process. We are devoted to building lasting relationships, empowering users to achieve their career and hiring goals, and contributing to a thriving job market.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>Our Commitment</h4>
                                </div>
                                <p>
                                At Ejobee, we are committed to delivering an exceptional job portal experience by prioritizing transparency, innovation, and user satisfaction. We pledge to continuously enhance our platform with cutting-edge technology and valuable resources to support both job seekers and employers. Our dedication extends to providing personalized assistance, fostering a diverse talent pool, and ensuring a seamless and efficient job matching process. We are devoted to building lasting relationships, empowering users to achieve their career and hiring goals, and contributing to a thriving job market.
                                </p>
                                <p>
                                    <b>
                                Join Ejobee today and experience our commitment to revolutionizing your job search and recruitment journey. With our innovative platform, personalized support, and a wide range of benefits for job seekers and employers alike, Ejobee is here to help you connect, grow, and succeed. Sign up now to start discovering opportunities and finding top talent!
                                </b>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-auto">
                            <img loading='lazy' className='w-100 img-thumb' src={our_commitment_abt_us} alt="EJOBEE" />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default About