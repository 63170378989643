import React,{useState,useEffect}  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './comp_dashboard_widgets.css';
export const Comp_Dashboard_Widgets=(props)=>{

  let _workExpRank=0; // 20
  let _educationRank=0; // 15
  let _skillsRank=0; // 15
  let _languageRank=0; // 15
  let _resumeRank=0; //15
  let _videoRank=0; //20

  const [getMarks,setMarks]=useState(0);
  const [percentMarks,setPercMarks]=useState(0);

  const [stprofileList,setProfileList]=useState([]);


  let totalRank=100;
  let outofMarks=0;

  useEffect(()=>{
    let profileList=[];
    if(typeof props.candidate_dashboard!=="undefined" && typeof props.candidate_dashboard.candidates!=="undefined" && props.candidate_dashboard.candidates.length>0){
      if(props.candidate_dashboard.candidates[0]["is_experience_detail"]==="true"){
        _workExpRank=20;
      }else{
        profileList.push("No work expierence details found");
      }
      if(props.candidate_dashboard.candidates[0]["is_education_detail"]==="true"){
        _educationRank=15;
      }else{
        profileList.push("No education details found");
      }

      if(props.candidate_dashboard.candidates[0]["skills"]!==""){
        //alert(props.candidate_dashboard.candidates[0]["skills"]);
        if(JSON.parse(props.candidate_dashboard.candidates[0]["skills"]).length>0){
          _skillsRank=15;
        }else{
          profileList.push("Kindly add skills for your better ranking");
        }
      }
      if(props.candidate_dashboard.candidates[0]["languages"]!==""){
        //alert(props.candidate_dashboard.candidates[0]["skills"]);
        if(JSON.parse(props.candidate_dashboard.candidates[0]["languages"]).length>0){
          _languageRank=15;
        }else{
          profileList.push("Kindly add languages for your better ranking");
        }
      }

      if(props.candidate_dashboard.candidates[0]["resume"]!==null && props.candidate_dashboard.candidates[0]["resume"]!==""){
        _resumeRank=15;
      }else{
        profileList.push("Resume not prepared yet !")
      }

      if(props.candidate_dashboard.candidates[0]["intro_video"]!==null && props.candidate_dashboard.candidates[0]["intro_video"]!==""){
        _videoRank=20;
      }else{
        profileList.push("Make your intro video and upload on ejobee");
      }

      outofMarks+=_workExpRank+_educationRank+_skillsRank+_languageRank+_resumeRank+_videoRank;
      setMarks(outofMarks);
      setProfileList(profileList);
      
    }

  },[props.company_dashboard,props.candidate_dashboard])

  


//alert(JSON.stringify(props));
    return(
        <section class="content">
      <div class="container-fluid">
        {typeof props.company_dashboard!=="undefined" && typeof props.company_dashboard.companies!=="undefined" && props.company_dashboard.companies.length>0?
    <div class="row">
              <div class="col-12 col-sm-12 col-md-3">
                <Link to={'/company/jobs'}> 
                <div class="info-box">
                  <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog white"></i></span>
    
                  <div class="info-box-content">
                    <span class="info-box-text">Jobs Posted</span>
                    <span class="info-box-number">
                      {props.company_dashboard.companies[0]["count_jobs"]}
                      {/* <small>%</small> */}
                    </span>
                  </div>
                  
                </div>
                </Link>
              </div>
              
              <div class="col-12 col-sm-12 col-md-3">
              <Link to={'/company/saved-candidate'}>
                <div class="info-box mb-3">
                  <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-up white"></i></span>
    
                  <div class="info-box-content">
                    <span class="info-box-text">Saved Candidates</span>
                    <span class="info-box-number">{props.company_dashboard.companies[0]["saved_candidates"]}</span>
                  </div>
                  
                </div>
               </Link>
              </div>
             
              {/* <div class="clearfix hidden-md-up"></div> */}
    
              <div class="col-12 col-sm-12 col-md-3">
              <Link to={'/company/applied-candidate'}>
                <div class="info-box mb-3">
                  <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart white"></i></span>
    
                  <div class="info-box-content">
                    <span class="info-box-text">Applied Candidates</span>
                    <span class="info-box-number">{props.company_dashboard.companies[0]["applied_candidates"]}</span>
                  </div>
                
                </div>
                </Link>
              </div>
              
              <div class="col-12 col-sm-12 col-md-3">
              <Link to={'/company/applied-candidate'}>
                <div class="info-box mb-3">
                  <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users white"></i></span>
    
                  <div class="info-box-content">
                    <span class="info-box-text">Short Listed</span>
                    <span class="info-box-number">{props.company_dashboard.companies[0]["shorted_candidates"]}</span>
                  </div>
                 
                </div>
              </Link>
              </div>
              
            </div>:''}


{/* candidate component */}

{typeof props.candidate_dashboard!=="undefined" && typeof props.candidate_dashboard.candidates!=="undefined" && props.candidate_dashboard.candidates.length>0?
    <div class="row">
              <div class="col-12 col-sm-12 col-md-3">
                <Link to={'/saved-jobs'}> 
                <div class="info-box">
                  <span class="info-box-icon bg-info elevation-1">
                    {/* <i class="fas fa-cog white"></i> */}
                    <i class="fas fa-save white"></i>
                    </span>
    
                  <div class="info-box-content">
                    <span class="info-box-text">Saved Jobs</span>
                    <span class="info-box-number">
                      {props.candidate_dashboard.candidates[0]["count_saved_jobs"]}
                      {/* <small>%</small> */}
                    </span>
                  </div>
                  
                </div>
                </Link>
              </div>
              
              <div class="col-12 col-sm-12 col-md-3">
              <Link to={'/applied-jobs'}>
                <div class="info-box mb-3">
                  <span class="info-box-icon bg-danger elevation-1">
                    <i class="fas fa-thumbs-up white"></i>
                    </span>
    
                  <div class="info-box-content">
                    <span class="info-box-text">Applied Jobs</span>
                    <span class="info-box-number">{props.candidate_dashboard.candidates[0]["count_applied_jobs"]}</span>
                  </div>
                  
                </div>
               </Link>
              </div>

              <div class="col-12 col-sm-12 col-md-3">
           <Link to={"/profile"}>
            <div class="info-box mb-3 bg-warning">
              <span class="info-box-icon"><i class="fas fa-tag white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text white">Resume</span>
                <span class="info-box-number white">{props.candidate_dashboard.candidates[0]["resume"]!==null && props.candidate_dashboard.candidates[0]["resume"]!==""?"updated successfully":"not updated"}</span>
              </div>
              
            </div>
            </Link>
            </div>

            {/* Intro Video */}
            <div class="col-12 col-sm-12 col-md-3">
              <Link to={"/profile"}>
            <div class="info-box mb-3 bg-danger">
              <span class="info-box-icon"><i class="fas fa-cloud-download-alt white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text white">Intro Video</span>
                <span class="info-box-number white">{props.candidate_dashboard.candidates[0]["intro_video"]!==null && props.candidate_dashboard.candidates[0]["intro_video"]!==""?"updated successfully":'not updated'  }</span>
              </div>
              
            </div>
            </Link>
            </div>
             
              {/* <div class="clearfix hidden-md-up"></div> */}
              {/* goal completion */}
              <div class="col-12 col-md-12 mt-10 ">
              <div class="progress-group">
                      Profile Completion for best visibility and ratings
                      <span class="float-right"><b>{getMarks}</b>/{totalRank}</span>
                      <div class="progress progress-sm">
                        <div class="progress-bar bg-danger" style={{"width":getMarks+"%"}}></div>
                      </div>
                    </div>
                    
<ul class="nav nav-pills nav-sidebar flex-column">
  {stprofileList.map((item,index)=>{
    return  <li class="nav-item">
    <Link to={'/profile'} class="nav-link">
      <i class="nav-icon far fa-circle text-warning"></i>
      <p>{item}</p>
    </Link>
  </li>
  })}
                   

          </ul>
          

            </div>        
              
            </div>:''}

            </div>
            </section>
            )

}

export default Comp_Dashboard_Widgets;