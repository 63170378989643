import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

const CandidateProtection = ({ children }) => {

    const location = useLocation();
    const normalizedPathname = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;

    const isAuthenticated = localStorage.getItem('candidate_id');
    const iscompanyAuthenticated = localStorage.getItem('company_id');

    if (iscompanyAuthenticated) {
        return <Navigate to={'/company'} />
    } else {
        if (!isAuthenticated) {
            if (normalizedPathname === '' || normalizedPathname == "/" || normalizedPathname == "/about-us" || normalizedPathname == '/contact-us' || normalizedPathname == "/jobs" || normalizedPathname == '/blogs' || normalizedPathname == '/premium' || normalizedPathname == '/faq' || normalizedPathname.startsWith('/blogs/') || normalizedPathname.startsWith('/resume_sample/') || normalizedPathname.startsWith('/jobs/') || normalizedPathname == '/privacy-policy' || normalizedPathname == 'privacy-policy' || normalizedPathname == '/terms-and-conditions' || normalizedPathname == 'terms-and-conditions' || normalizedPathname == '/refund-and-return' || normalizedPathname == '/refund-and-return' || normalizedPathname==='/resume_sample' ) {
                return children
            }
            return <Navigate to="/login" />;
        } else {
            if (normalizedPathname == '/login' || normalizedPathname == '/otp' || normalizedPathname.startsWith('/otp/')) {
                return <Navigate to={'/'} />
            } else {
                if (localStorage.getItem('candidate_is_profile_completed') == 'false') {
                    if (localStorage.getItem('candidate_is_personal_details') == 'false') {
                        return <Navigate to={`complete-profile?id=${isAuthenticated}`} />
                    } else {
                        if (localStorage.getItem('candidate_is_education_details') == 'false') {
                            return <Navigate to={`/complete-profile/education-details?id=${isAuthenticated}`} />
                        } else {
                            if (localStorage.getItem('candidate_is_experience_details') == 'false') {
                                return <Navigate to={`/complete-profile/experience-details?id=${isAuthenticated}`} />
                            } else {
                                if (localStorage.getItem('candidate_is_preference_details') == 'false') {
                                    return <Navigate to={`/complete-profile/preferences?id=${isAuthenticated}`} />
                                } else {
                                    return children
                                }
                            }
                        }
                    }
                } else {
                    return children
                }
            }
        }
    }
}

export default CandidateProtection