import React, { useEffect, useState } from 'react'
import AdminAddPerks from '../dialogue/AdminAddPerks';
import { alljobrole_service, allperk_service } from '../../ApiServices';

const AdminPerks = () => {

    const [perks, setperks] = useState([]);

    useEffect(() => {
        getprks();
    }, [])

    const getprks = () =>{
        allperk_service().then((data)=>{
            setperks(data.data.data);
        }).catch((error)=>{})
    }
    

  return (
    <div className="container-fluid">
        <AdminAddPerks getperks={getprks} />
            <div class="card shadow border-0 mb-7">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h5 class="mb-0">Perks</h5>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#addPerks" class="btn btn-sm btn-neutral text-danger-hover">
                        <i class="bi bi-plus"></i>
                        Add
                    </button>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Perks</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                perks.map((v) => {
                                    return (
                                        <tr>
                                            <td>
                                                {v.name}
                                            </td>
                                            <td class="text-end">
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#addstate" class="btn btn-sm btn-neutral text-danger-hover">
                                                    Edit
                                                </button>
                                                {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="bi bi-trash"></i>
                                                </button> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* <div class="card-footer border-0 py-5">
                </div> */}
            </div>
        </div>
  )
}

export default AdminPerks