import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { allskills_service, candidateaddskill_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const AddSkills = ({defaultSelected,getprofile}) => {

  const closedialogueRef = useRef();

  const [skilloption, setskilloption] = useState([]);

  const [formdata, setformdata] = useState({
    skills: ""
  })


  useEffect(() => {
    //alert(JSON.stringify(getprofile));
    allskills_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      //alert(JSON.stringify(mappedoptions));
      setskilloption(mappedoptions)
    }).catch((err) => { })
  }, [])


  const changeSelecthandle = (value, action) => {
    setformdata({
      ...formdata,
      [action.name]: value
    })
  }


  const canceldialogue = () => {
    setformdata({
      skills: ""
    })
    if (closedialogueRef.current) {
      closedialogueRef.current.click();
    }
  }

  const submit = () => {
    candidateaddskill_service(formdata).then((res) => {
      toast('✅ Skills added Successfully');
      canceldialogue()
      getprofile();
    }).catch((err) => { })
  }

  return (
    <div class="modal fade" id="addskills" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Add Skills
            <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div className="form-group">
              <ReactSelect
                onChange={changeSelecthandle}
                isMulti
                name="skills"
                options={skilloption}
                defaultValue={defaultSelected}
                className="basic-multi-select"
                classNamePrefix="select" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddSkills