import $ from 'jquery';
import './comp_ejobee_loginmodal.css';
import { Link,useNavigate } from 'react-router-dom';

const Comp_Ejobee_Loginmodal=(props)=>{
 const closeCall=()=>{
$("#loginModal").modal("hide");
  }
    return (<div class="modal fade ejobeeloginmodal" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg ">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="loginModalLabel"><img src="/assets/logo/logo.png"/></h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
            
          <div class="col-md-6 mb-3">
            <Link to={"/login?rf=candidate"} onClick={()=>closeCall()}>
            <div class="card h11-100">
              <div class="card-body">
                <h5 class="card-title">Login as Candidate</h5>
                <p class="card-text"><img src="assets/images/candidate.png" alt=""/></p>
                <p>मुझे जॉब चाहिए</p>
              </div>
            </div>
            </Link>
          </div>
          
          <div class="col-md-6 mb-3">
          <Link to={"/login?rf=company"} onClick={()=>closeCall()} >
            <div class="card h11-100">
              <div class="card-body">
                <h5 class="card-title">Login as Company</h5>
                <p class="card-text"><img src="assets/images/recruiter.jpg" alt=""/></p>
                <p>मुझे हायरिंग करना है</p>
              </div>
            </div>
            </Link>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

    );
}
export default Comp_Ejobee_Loginmodal;