import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const AdminNavbar = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const logoutclick = () => {
    localStorage.clear();
    navigate('/admin/login')
  }



  return (
    <header class="bg-surface-primary border-bottom pt-6 pb-6">
      <div class="container-fluid">
        <div class="mb-npx">
       
          <div class="row align-items-center justify-content-between">
          <a style={{ width: 'fit-content' }}  class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVertical" aria-controls="navbarVertical" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </a>
            <h1 class="h2 mb-0 ls-tight" style={{ width: 'fit-content' }}>
            
              {
                location.pathname === '/admin/dashboard' || location.pathname === '/admin/' || location.pathname === '/admin' ? 'Dashboard' : ''
              }
              {
                location.pathname === '/admin/candidate' || location.pathname === '/admin/candidate/' ? 'Candidates' : ''
              }
              {
                location.pathname === '/admin/companies' || location.pathname === '/admin/companies/' ? 'Companies' : ''
              }
              {
                location.pathname === '/admin/coupons' || location.pathname === '/admin/coupons/' ? 'Coupons' : ''
              }
              {
                location.pathname === '/admin/jobs' || location.pathname === '/admin/companies/' ? 'Jobs' : ''
              }
              {location.pathname === '/admin/contact-forms' || location.pathname === '/admin/contact-forms/' ? 'Contact Form' : ''
              }
              {location.pathname === '/admin/country' || location.pathname === '/admin/country/' ? 'Country' : ''
              }
              {location.pathname === '/admin/state' || location.pathname === '/admin/state/' ? 'State' : ''
              }
              {location.pathname === '/admin/city' || location.pathname === '/admin/city/' ? 'City' : ''
              }
              {location.pathname === '/admin/language' || location.pathname === '/admin/language/' ? 'Language' : ''
              }
              {location.pathname === '/admin/currency' || location.pathname === '/admin/curency/' ? 'Currency' : ''
              }

              {/* options */}
              {
                location.pathname === '/admin/education-level' || location.pathname === '/admin/education-level/' ? 'Education Level' : ''
              }
              {
                location.pathname === '/admin/university' || location.pathname === '/admin/university/' ? 'University' : ''
              }
              {
                location.pathname === '/admin/degree' || location.pathname === '/admin/degree/' ? 'Degree' : ''
              }
              {
                location.pathname === '/admin/job-role' || location.pathname === '/admin/job-role/' ? 'Job Role' : ''
              }
              {
                location.pathname === '/admin/industry' || location.pathname === '/admin/industry/' ? 'Industry' : ''
              }
              {
                location.pathname === '/admin/skills' || location.pathname === '/admin/skills/' ? 'Skills' : ''
              }

              {
                location.pathname === '/admin/perks' || location.pathname === '/admin/perks/' ? 'Perks' : ''
              }

              {
                location.pathname === '/admin/job-type' || location.pathname === '/admin/job-type/' ? 'Job Types' : ''
              }

              {
                location.pathname === '/admin/work-type' || location.pathname === '/admin/work-type/' ? 'Work Types' : ''
              }

              {
                location.pathname === '/admin/job-shift' || location.pathname === '/admin/job-shift/' ? 'Job Shifts' : ''
              }

              {
                location.pathname === '/admin/job-plan' || location.pathname === '/admin/job-plan/' ? 'Job plans' : ''
              }

              {/* content */}
              {/* {
                location.pathname === '/admin/about' || location.pathname === '/admin/about/' ? 'About Us' : ''
              }
              {
                location.pathname === '/admin/faq' || location.pathname === '/admin/faq/' ? 'FAQ' : ''
              }
              {
                location.pathname === '/admin/testimonials' || location.pathname === '/admin/testimonials/' ? 'Testimonial' : ''
              }
              {
                location.pathname === '/admin/privacy' || location.pathname === '/admin/privacy/' ? 'Privacy Policy' : ''
              }
              {
                location.pathname === '/admin/terms' || location.pathname === '/admin/terms/' ? 'Terms And Conditions' : ''
              } */}

              {/* blogs */}
              {
                location.pathname === '/admin/blogs' || location.pathname === '/admin/blogs/' ? 'Blogs' : ''
              }

              {
                location.pathname === '/admin/change-password' || location.pathname === '/admin/change-password/' ? 'Change-password' : ''
              }

            </h1>
            {/* <!-- Actions --> */}
            <div class="mx-n1" style={{ width: 'fit-content' }}>
              {/* <a href="#" class="btn d-inline-flex btn-sm btn-neutral border-base mx-1">
                <span class=" pe-2">
                  <i class="bi bi-pencil"></i>
                </span>
                <span>Edit</span>
              </a> */}
              <a onClick={logoutclick} class="btn d-inline-flex btn-sm btn-primary mx-1">
                <span>Logout</span>
              </a>
            </div>
          </div>

          {/* commons */}
          {
            location.pathname === '/admin/country' || location.pathname === '/admin/state' || location.pathname === '/admin/city' || location.pathname === '/admin/language' || location.pathname === '/admin/currency' || location.pathname === '/admin/country/' || location.pathname === '/admin/state/' || location.pathname === '/admin/city/' || location.pathname === '/admin/language/' || location.pathname === '/admin/currency/'
              ? <ul class="nav nav-tabs mt-4 overflow-x border-0">
                <li class="nav-item ">
                  <Link to={'country'} href="#" class={`nav-link ${location.pathname === '/admin/country' || location.pathname === '/admin/country/' ? 'active' : ''}`}>Country</Link>
                </li>
                <li class="nav-item">
                  <Link to={'state'} class={`nav-link ${location.pathname === '/admin/state' || location.pathname === '/admin/state/' ? 'active' : ''}`}>State</Link>
                </li>
                <li class="nav-item">
                  <Link to={'city'} class={`nav-link ${location.pathname === '/admin/city' || location.pathname === '/admin/city/' ? 'active' : ''}`}>City</Link>
                </li>
                <li class="nav-item">
                  <Link to={'language'} class={`nav-link ${location.pathname === '/admin/language' || location.pathname === '/admin/language/' ? 'active' : ''}`}>Language</Link>
                </li>
                <li class="nav-item">
                  <Link to={'currency'} class={`nav-link ${location.pathname === '/admin/currency' || location.pathname === '/admin/currency/' ? 'active' : ''}`}>Currency</Link>
                </li>
              </ul> : ''
          }

          {/* options */}
          {
            location.pathname === '/admin/education-level' || location.pathname === '/admin/education-level/' || location.pathname === '/admin/university' || location.pathname === '/admin/university/' || location.pathname === '/admin/degree' || location.pathname === '/admin/degree/' || location.pathname === '/admin/job-role' || location.pathname === '/admin/job-role/' || location.pathname === '/admin/industry' || location.pathname === '/admin/industry/' || location.pathname === '/admin/skills' || location.pathname === '/admin/skills/' || location.pathname === '/admin/perks/' || location.pathname === '/admin/perks' || location.pathname === '/admin/work-type' || location.pathname === '/admin/work-type/' || location.pathname === '/admin/job-type' || location.pathname === '/admin/job-type/' || location.pathname === '/admin/job-shift' || location.pathname === '/admin/job-shift/' ?
              <ul class="nav nav-tabs mt-4 overflow-x border-0">
                <li class="nav-item ">
                  <Link to={'education-level'} href="#" class={`nav-link ${location.pathname === '/admin/education-level' || location.pathname === '/admin/education-level/' ? 'active' : ''}`}>Education Level</Link>
                </li>
                <li class="nav-item">
                  <Link to={'university'} class={`nav-link ${location.pathname === '/admin/university' || location.pathname === '/admin/university/' ? 'active' : ''}`}>University</Link>
                </li>
                <li class="nav-item">
                  <Link to={'degree'} class={`nav-link ${location.pathname === '/admin/degree' || location.pathname === '/admin/degree/' ? 'active' : ''}`}>Degree</Link>
                </li>
                <li class="nav-item">
                  <Link to={'industry'} class={`nav-link ${location.pathname === '/admin/industry' || location.pathname === '/admin/industry/' ? 'active' : ''}`}>Industry</Link>
                </li>
                <li class="nav-item">
                  <Link to={'job-role'} class={`nav-link ${location.pathname === '/admin/job-role' || location.pathname === '/admin/job-role/' ? 'active' : ''}`}>Job Role</Link>
                </li>
                <li class="nav-item">
                  <Link to={'skills'} class={`nav-link ${location.pathname === '/admin/skills' || location.pathname === '/admin/skills/' ? 'active' : ''}`}>skills</Link>
                </li>
                <li class="nav-item">
                  <Link to={'perks'} class={`nav-link ${location.pathname === '/admin/perks' || location.pathname === '/admin/perks/' ? 'active' : ''}`}>Perks</Link>
                </li>
                <li class="nav-item">
                  <Link to={'work-type'} class={`nav-link ${location.pathname === '/admin/work-type' || location.pathname === '/admin/work-type/' ? 'active' : ''}`}>Work Types</Link>
                </li>

                <li class="nav-item">
                  <Link to={'job-type'} class={`nav-link ${location.pathname === '/admin/job-type' || location.pathname === '/admin/job-type/' ? 'active' : ''}`}>Job Types</Link>
                </li>

                <li class="nav-item">
                  <Link to={'job-shift'} class={`nav-link ${location.pathname === '/admin/job-shift' || location.pathname === '/admin/job-shift/' ? 'active' : ''}`}>Job Shift</Link>
                </li>

              </ul>
              : ''
          }

          {/* content */}
          {
            location.pathname === '/admin/about' || location.pathname === '/admin/about/' || location.pathname === '/admin/faq' || location.pathname === '/admin/faq/' || location.pathname === '/admin/testimonials/' || location.pathname === '/admin/testimonials' || location.pathname === '/admin/privacy' || location.pathname === '/admin/privacy/' || location.pathname === '/admin/terms' || location.pathname === '/admin/terms/' ?
              <ul class="nav nav-tabs mt-4 overflow-x border-0">
                <li class="nav-item ">
                  <Link to={'about'} href="#" class={`nav-link ${location.pathname === '/admin/about' || location.pathname === '/admin/about/' ? 'active' : ''}`}>About Us</Link>
                </li>
                <li class="nav-item">
                  <Link to={'faq'} class={`nav-link ${location.pathname === '/admin/faq' || location.pathname === '/admin/faq/' ? 'active' : ''}`}>Faq</Link>
                </li>
                <li class="nav-item">
                  <Link to={'testimonials'} class={`nav-link ${location.pathname === '/admin/testimonials' || location.pathname === '/admin/testimonials/' ? 'active' : ''}`}>testimonial</Link>
                </li>
                <li class="nav-item">
                  <Link to={'privacy'} class={`nav-link ${location.pathname === '/admin/privacy' || location.pathname === '/admin/privacy/' ? 'active' : ''}`}>Privacy Policy</Link>
                </li>
                <li class="nav-item">
                  <Link to={'terms'} class={`nav-link ${location.pathname === '/admin/terms' || location.pathname === '/admin/terms/' ? 'active' : ''}`}>Terms And Conditions</Link>
                </li>
              </ul>
              : ''
          }

        </div>
      </div>
    </header>
  )
}

export default AdminNavbar