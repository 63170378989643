import React, { useState, useRef } from 'react';
import Plyr from 'react-plyr';
import 'plyr/dist/plyr.css';  // Import Plyr CSS

const Comp_Video_Player = (props) => {
    const [loading, setLoading] = useState(true);
    const videoRef = useRef(null);

    const handleOnReady = () => {
        setLoading(false);
    };

    const handleOnWaiting = () => {
        setLoading(true);
    };

    const handleOnPlaying = () => {
        setLoading(false);
    };

    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                <div className="loading-spinner" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 10,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '10px',
                    borderRadius: '50%'
                }}>
                    Loading...
                </div>
            )}
            <Plyr
                ref={videoRef}
                type="video"
                url={props.video_url}
                onReady={handleOnReady}
                onWaiting={handleOnWaiting}
                onPlaying={handleOnPlaying}
            />
        </div>
    );
};

export default Comp_Video_Player;