import React, { useEffect, useState } from 'react'
import { blogpostbyid_service } from '../../ApiServices';
import { public_url } from '../../ApiUrl';

const AdminBlogDetails = ({ blogid }) => {

  const [singleblog, setsingleblog] = useState(null);


  useEffect(() => {
    if (blogid != null) {
      blogpostbyid_service(blogid).then((res) => {
        setsingleblog(res.data)
      }).catch((err) => {

      })
    }
  }, [blogid])


  return (
    <div class="modal fade" id="blogdetail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: '0px' }}>
          <div class="modal-header">
            Blog Detail
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {
              singleblog ?
                <>
                  <p>blog title</p>
                  <h3>{singleblog[0].title}</h3>

                  <div style={{ borderBottom: '1px solid #ccc', marginTop: '20px', marginBottom: '20px' }}></div>

                  <img src={public_url + singleblog[0].image} />

                  <div style={{ borderBottom: '1px solid #ccc', marginTop: '20px', marginBottom: '20px' }}></div>

                  <p>blog content</p>
                  <div dangerouslySetInnerHTML={{__html:singleblog[0].content}}></div>
                </>
                :
                ""
            }
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">cancel</button>
            {/* <button type="button" class="btn btn-danger" style={{ borderRadius: '0' }}>Logout</button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminBlogDetails