import React, { useEffect, useState } from 'react'
import { alljobtype_service } from '../../ApiServices';

const AdminJobType = () => {

    const [jobtype, setjobtype] = useState([]);


    useEffect(() => {
        alljobtype_service().then((data)=>{
            setjobtype(data.data.data)
        })
    }, [])


    return (
        <div className="container-fluid">

            <div class="card shadow border-0 mb-7">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h5 class="mb-0">Job Types</h5>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#addPerks" class="btn btn-sm btn-neutral text-danger-hover">
                        <i class="bi bi-plus"></i>
                        Add
                    </button>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Job Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                jobtype.map((v) => {
                                    return (
                                        <tr>
                                            <td>
                                                {v.name}
                                            </td>
                                            <td class="text-end">
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#addstate" class="btn btn-sm btn-neutral text-danger-hover">
                                                    Edit
                                                </button>
                                                {/* <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="bi bi-trash"></i>
                                                </button> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AdminJobType