import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';
import { education_level_service, update_education_level } from '../../ApiServices';
import { toast } from 'react-toastify';

const EditEducationLevel = ({ edieducationlevel, getprofile }) => {

    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        educationlevel: { value: edieducationlevel.id, label: edieducationlevel.name }
    });

    const [educationlevel, seteducationlevel] = useState([]);

    const [errors, seterrors] = useState({})

    useEffect(() => {
        education_level_service().then((data) => {
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            seteducationlevel(mappedoptions)
            setformdata({
                educationlevel: educationlevel.find(obj => obj.value === edieducationlevel)
            })
        })
    }, [])


    const HandleSelectChange = (value, action) => {
        setformdata({
            ...formdata,
            [action.name]: value
        })
    }

    const validateform = () => {
        const newerrors = {}

        if (!formdata.educationlevel) {
            newerrors.educationlevel = "Please Select Highest Education Level"
        }

        seterrors(newerrors);
        return Object.keys(newerrors).length === 0;
    }

    const canceldialogue = () => {
        seterrors({})
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const submit = () => {
        if (validateform()) {
            update_education_level(formdata).then((res) => {
                toast('✅ Education Level Successfully');
                canceldialogue();
                getprofile();
            }).catch((err) => {

            })
        }
    }

    return (
        <div class="modal fade" id="editeducationlevel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Edit Highest Education
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <ReactSelect options={educationlevel} placeholder="Select Highest Education" value={formdata.educationlevel} onChange={HandleSelectChange} name='educationlevel' />
                            {errors.educationlevel && <span className='text-danger'>{errors.educationlevel}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditEducationLevel