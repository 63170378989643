import React, { useEffect, useRef, useState } from 'react'
import { addIndustry_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const AdminAddIndustry = ({ getindustries }) => {

    const closedialogueRef = useRef();

    const [formdata, setformdata] = useState({
        name: ''
    })
    const [errors, seterrors] = useState({})

    const HandleChange = (e) => {
        const { name, value } = e.target;
        setformdata({
            [name]: value
        })

        seterrors((preverror) => ({
            ...preverror,
            [name]: ""
        }))
    }

    const validateform = () => {
        const newerror = {}

        if (!formdata.name) {
            newerror.name = "Industry Name is Required";
        }

        seterrors(newerror);
        return Object.keys(newerror).length === 0;
    }

    const canceldialogue = () => {
        setformdata({
            name: '',
        })
        seterrors({});
        if (closedialogueRef.current) {
            closedialogueRef.current.click();
        }
    }

    const submit = () => {
        if (validateform()) {
            addIndustry_service(formdata).then((data) => {
                toast('✅ Industry Added Successfully');
                canceldialogue()
                getindustries();
            }).catch((error) => {

            })
        }
    }

    return (
        <div class="modal fade" id="addIndustry" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style={{ borderRadius: '0px' }}>
                    <div class="modal-header">
                        Add Industry
                        <button onClick={canceldialogue} ref={closedialogueRef} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="form-group">
                            <input type="text" className="form-control mt-2" placeholder='Industry Name' onChange={HandleChange} value={formdata.name} name='name' />
                            {errors.name && <span className='text-danger'>{errors.name}</span>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={submit} class="btn btn-primary" style={{ borderRadius: '0' }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAddIndustry