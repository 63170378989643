import React, { useEffect, useState } from 'react'
import { adminalljob_service } from '../../ApiServices';
import InfiniteScroll from 'react-infinite-scroll-component';

import {getCondidatesView} from '../../ApiServices';

const AdminJobs = () => {

  const [jobs, setjobs] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchjobs = () => {
    if (hasMore) {
      let map={};
      map["testing"]="0";
      getCondidatesView(map,"getAllJobsListOutstand").then((res) => {
        //alert(res.data);
        //const newjobs = res.data.data;
        const newjobs = JSON.parse(res.data).all_jobs;
        setjobs(prevjobs => [...prevjobs, ...newjobs]);
        setPage(prevPage => prevPage + 1);
        setHasMore(res.data.current_page < res.data.last_page);
      }).catch((err) => {

      })
    }
  }

  useEffect(() => {
    fetchjobs();
  }, [])


  return (
    <div className="container-fluid">
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0">Jobs</h5>
        </div>
        <div class="table-responsive">
          <InfiniteScroll
            dataLength={jobs.length}
            next={fetchjobs}
            hasMore={hasMore}
            loader={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0px' }}>
              <div style={{ width: '20px', height: '20px' }} class="loading-loader"></div>
            </div>}
          // endMessage={<p>No more candidates to show.</p>}
          >
            <table class="table table-hover table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Job Title</th>
                  <th scope="col">company name</th>
                  <th scope="col">Work Type</th>
                  <th scope="col">Employement Type</th>
                  <th scope="col">city</th>
                  <th scope="col">Job Applied</th>
                  <th scope="col">Job Saved </th>

                  <th scope="col">status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  jobs.map((v) => {
                    return (
                      <tr>
                        <td>
                          {v.jobrole_name}
                        </td>
                        <td>
                          {v.companyname}
                        </td>
                        <td>
                          {v.work_types}
                        </td>
                        <td>
                          {v.jobtype_name}
                        </td>
                        <td>
                          {v.city_name}
                        </td>
                        <td>
                          {v.count_applied_jobs}
                        </td>
                        <td>
                          {v.count_saved_jobs}
                        </td>
                        <td>
                          {
                            v.job_status == "pending" ? <span className="btn-danger btn-sm">pending</span> : ""
                          }
                          {
                            v.job_status == "published" ? <span className="btn-success btn-sm">published</span> : ""
                          }
                          {
                            v.job_status == "expire" ? <span className="btn-warning btn-sm">Expire</span> : ""
                          }
                        </td>
                        <td class="text-end">
                          <button data-bs-toggle="modal" data-bs-target="#jobdetail" class="btn btn-sm btn-neutral">View</button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default AdminJobs